import React from "react";
import {
    createContext
} from "react";
import { MeetingConfiguration, MeetingConfigurationDifference, Taxonomy } from "../../api/client";

interface MeetingConfigurationContextValues {
    actualMeetingConfiguration?: MeetingConfiguration;
    themeSpecificMeetingConfiguration?: MeetingConfiguration;
    meetingConfigurationDifference?: MeetingConfigurationDifference;
    customerCategories: Taxonomy[];
}

const MeetingConfigurationContext = createContext<MeetingConfigurationContextValues>({
    customerCategories: []
});

export const useMeetingConfigurationContext = () => {
    const context = React.useContext(MeetingConfigurationContext);
    if (context === null) throw new Error("useMeetingConfigurationContext must be used within a MeetingConfigurationProvider");
    return context;
};

export const MeetingConfigurationProvider = ({ children, ...rest }: React.PropsWithChildren<MeetingConfigurationContextValues>) => {
    return (
        <MeetingConfigurationContext.Provider value={rest}>
            {children}
        </MeetingConfigurationContext.Provider>
    );
};