import { Link as RouterLink, useLocation } from "react-router-dom"
import {
  Box,
  Flex,
  HStack,
  Link,
  VStack,
  Text,
  LinkProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Tooltip
} from "@chakra-ui/react";
import { useState, useEffect, useMemo } from "react";
import { SettingsIcon } from "../icons/SettingsIcon";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { appRoles } from "../authconfig";
import { useTokenContext } from "../TokenContext";
import { useTranslation } from 'react-i18next';
import jwtDecode from "jwt-decode";
import { LanguageSelector } from "./LanguageSelector";
import { useGetFeatureFlags } from "../api/useBankOptionsApi";

interface UpperNavLinkProps {
  title: string;
  onClick?: (title: string) => void;
}

const UpperNavLink = ({ title, onClick, ...props }: UpperNavLinkProps & Omit<LinkProps, "onClick">) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    fontSize="2xl"
    textColor="gray.100"
    _hover={{
      textDecoration: 'none',
      bg: "bookMeDarkBlue",
    }}
    onClick={() => onClick?.(title)}
    {...props}
  >
    {title}
  </Link>
);

interface LowerNavLinkProps {
  title: string;
  path: string;
  onClick?: () => void;
  disabledWithMessage?: string;
}

const LowerNavLink = ({ title, path, onClick, disabledWithMessage, ...props }: LowerNavLinkProps & Omit<LinkProps, "onClick">) => {
  const isDisabled = !!disabledWithMessage;

  return (
    <Tooltip label={disabledWithMessage} isDisabled={!isDisabled}>
      <Link
        px={2}
        py={1}
        rounded={'md'}
        fontSize="l"
        textColor={isDisabled ? 'gray.400' : 'gray.800'}
        _hover={{
          textDecoration: 'none',
          bg: isDisabled ? 'transparent' : 'bookMeLightBlue',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        to={isDisabled ? '#' : path}
        as={RouterLink}
        onClick={isDisabled ? undefined : onClick}
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        aria-disabled={isDisabled}
        {...props}
      >
        {title}
      </Link>
    </Tooltip>
  );
};

const Settings = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { t } = useTranslation();

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <Menu>
      <MenuButton as={Button} variant="navLink" color="white" fontSize="lg" _hover={{
        "& svg": {
          transform: "rotate(135deg)"
        }
      }} rightIcon={<SettingsIcon ml="2" height="24px" width="24px" transition="transform 800ms ease" />}>
        {t('navbar.settings')}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => instance.logout()}>{t('navbar.logout')}</MenuItem>
      </MenuList>
    </Menu>
  );
};

interface LowerLink {
  title: string;
  path: string;
  roles?: string[];
  disabled?: boolean;
  disabledWithMessage?: string;
}

interface UpperLink {
  title: string;
  roles?: string[];
  lowerLinks: LowerLink[];
}

const NavBar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const token = useTokenContext();
  const { data: featureFlags } = useGetFeatureFlags();
  const [userRoles, setUserRoles] = useState<string[]>([])
  const links: UpperLink[] = useMemo(() => {
    const navbarLinks = [
      {
        title: t('navbar.title1'), roles: [appRoles.AMB_managementAccess], lowerLinks:
          [
            {
              title: t('navbar.title1.subtitle1'),
              path: '/calendar',
              roles: [appRoles.AMB_managementAccess]
            },
            {
              title: t('navbar.title1.subtitle2'),
              path: '/availability',
              roles: [appRoles.AMB_managementAccess]
            },
            {
              title: t('navbar.title2.subtitle5'),
              path: '/serviceGroups',
              roles: [appRoles.AMB_managementAccess]
            },
            {
              title: t('navbar.title2.subtitle6'),
              path: '/competenceGroups',
              roles: [appRoles.AMB_managementAccess]
            },
            {
              title: t('navbar.title1.subtitle3'),
              path: '/insights-settings',
              roles: [appRoles.AMB_managementAccess],
              disabledWithMessage: featureFlags?.enableInsights ? undefined : t('navbar.title1.subtitle3.disabled-text')
            },
          ]
      },
      {
        title: t('navbar.title2'), roles: [appRoles.AMB_configurationAccess], lowerLinks:
          [{ title: t('navbar.title2.subtitle1'), path: '/standardvalues', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle2'), path: '/customercategories', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle3'), path: '/meetingtaxonomy', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle4'), path: '/meetingconfiguration', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle5'), path: '/serviceGroups', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle6'), path: '/competenceGroups', roles: [appRoles.AMB_configurationAccess] },
          { title: t('navbar.title2.subtitle7'), path: '/meetingrooms', roles: [appRoles.AMB_configurationAccess] },
          {
            title: t('navbar.title2.subtitle8'),
            path: '/portals',
            roles: [appRoles.AMB_configurationAccess],
            disabledWithMessage: featureFlags?.enablePortalConfiguration ? undefined : t('navbar.title2.subtitle8.disabled-text')
          },
          {
            title: t('navbar.title2.subtitle9'),
            path: '/portals/configurations',
            roles: [appRoles.AMB_configurationAccess],
            disabledWithMessage: featureFlags?.enablePortalConfiguration ? undefined : t('navbar.title2.subtitle9.disabled-text')
          }
          ]
      }];

    return navbarLinks;

  }, [t, featureFlags]);

  const userLinks = useMemo(() => {
    return links.filter(link => link.roles?.some(role => userRoles.includes(role))).map(link => {
      link.lowerLinks = link.lowerLinks.filter(lowerLink => lowerLink.roles?.some(role => userRoles.includes(role) && !lowerLink.disabled));
      return link;
    });

  }, [userRoles, links]);

  const [subPageChoices, setSubPageChoices] = useState<LowerLink[]>();
  const [currentUpperLink, setCurrentUpperlink] = useState<UpperLink>();
  const [currentLowerLink, setCurrentLowerLink] = useState<LowerLink>();

  useEffect(() => {
    let upperLink = userLinks.find(link => link.lowerLinks.map(x => x.path).includes(pathname));
    if (!upperLink && userLinks) {
      upperLink = userLinks[0];
    }
    
    if (currentLowerLink && (currentLowerLink.path === "/competenceGroups" || currentLowerLink.path === "/serviceGroups")) {
      upperLink = currentUpperLink || upperLink;
    }

    setCurrentUpperlink(upperLink);
    setCurrentLowerLink(upperLink ? upperLink.lowerLinks.find(x => x.path === pathname) : undefined);
    setSubPageChoices(upperLink ? upperLink.lowerLinks : undefined);
  }, [pathname, userLinks]);

  useEffect(() => {
    if (token) {
      const decodedToken: { [key: string]: any } = jwtDecode(token);
      if (decodedToken['roles']) {
        setUserRoles(decodedToken['roles']);
      }
    }
  }, [token])

  function handleNavClick(linkText: string) {
    setSubPageChoices(userLinks.find(x => x.title === linkText)?.lowerLinks);
    setCurrentUpperlink(userLinks.find(x => x.title === linkText));
  }

  return (
    <VStack alignItems="stretch" spacing={0} pos="sticky" top="0" zIndex="sticky" >
      <Box bg="bookMeBlue">
        <Flex h="80px" alignItems="center" justifyContent="space-between" shadow="lg" px={4}>
          <HStack spacing="40" alignItems="center" px={2} width="80%">
            <Link as={RouterLink} to="/">
              <HStack align="end" spacing="0">
                <img width="120" height="70px" alt="BookMe logo" src="/logo-n.svg" />
              </HStack>
            </Link>
            <HStack as="nav" spacing={8} display={{ base: 'none', md: 'flex' }}>
              {userLinks.map((link, index) => {
                const fontWeight = link?.title === currentUpperLink?.title ? "bold" : "normal";
                return <UpperNavLink fontWeight={fontWeight} title={link.title} key={index} onClick={handleNavClick}></UpperNavLink>
              })}
            </HStack>
          </HStack>
          <HStack>
            <LanguageSelector />
            <Settings />
          </HStack>
        </Flex>
      </Box>
      <Box bg="white">
        <Flex h="60px" alignItems="center" justifyContent="space-between" boxShadow="lg" px={4}>
          <HStack spacing="40" alignItems="center" px={2} overflowX="auto">
            <HStack as="nav" spacing={8} display={{ base: 'none', md: 'flex' }} >
              {subPageChoices ? subPageChoices.map((link, index) => {
                const fontWeight = link === currentLowerLink ? "bold" : "normal";
                return <LowerNavLink
                  fontWeight={fontWeight}
                  title={link.title}
                  key={`${index}${link.title}`}
                  path={link.path}
                  onClick={() => setCurrentLowerLink(link)}
                  disabledWithMessage={link.disabledWithMessage}
                />
              }) : null}
            </HStack>
          </HStack>
        </Flex>
      </Box>
    </VStack>
  );
}

export default NavBar;