import React from "react";
import {
    Input,
    Button,
    Box,
    FormControl,
    FormLabel,
    IconButton,
    HStack,
    Icon,
    VStack
} from "@chakra-ui/react";
import {
    Taxonomy
} from "../api/client";
import { CreateButton } from "./Buttons/CreateButton";
import {
    FaUndo
} from "react-icons/fa";
import {
    AiOutlineMinusCircle as MinusIcon,
    AiOutlineClose as DeleteIcon
} from "react-icons/ai";
import { useTranslation } from "react-i18next";

interface SubTaxonomyFormProps {
    value: string;
    label: string;
    allowDelete?: boolean;
    isRequired?: boolean;
    onDelete?: () => void;
    onChange?: (newValue: string) => void;
}

const SubTaxonomyForm = ({ value, label, allowDelete, isRequired, onDelete, onChange }: SubTaxonomyFormProps) => {
    const { t } = useTranslation();
    return (
        <FormControl isRequired={isRequired}>
            <FormLabel color="gray.300" fontSize="xs">{label}</FormLabel>
            <HStack spacing="0">
                <Input maxW="90%" placeholder={t('meetingthemepage.create-taxonomy-form.input-placeholder')} shadow="md" value={value} onChange={e => onChange?.(e.target.value)} size="lg" borderRadius="0" />
                {allowDelete && (
                    <>
                        <IconButton ml="1" variant="link" aria-label="Remove" fill="black" icon={<Icon as={MinusIcon} />} size="sm" onClick={onDelete} />
                    </>
                )}
            </HStack>
        </FormControl>
    );
};

interface Props {
    mainAreaLabel: string;
    childInputLabel: string;
    createSubTaxonomyLabel: string;
    onSave?: (taxonomy: Taxonomy) => void;
}

export const CreateTaxonomyForm = ({ mainAreaLabel, childInputLabel, createSubTaxonomyLabel, onSave }: Props) => {
    const [mainTaxonomyTitle, setMainTaxonomyTitle] = React.useState("");
    const [childrenTitles, setChildrenTitles] = React.useState<string[]>([""]);
    const isCreateButtonDisabled = React.useMemo(() => {
        if (mainTaxonomyTitle.trim() === "" || childrenTitles[0].trim() === "") return true;
        return false;
    }, [mainTaxonomyTitle, childrenTitles]);
    const { t } = useTranslation(); 

    function reset() {
        setMainTaxonomyTitle("");
        setChildrenTitles([""]);
    }

    function save() {
        const taxonomy: Taxonomy = {
            name: mainTaxonomyTitle,
            children: []
        };
        for (const childTitle of childrenTitles) {
            if (taxonomy.children === undefined || taxonomy.children === null) break;
            taxonomy.children.push({ name: childTitle });
        }
        onSave?.(taxonomy);
        reset();
    }

    function setChildTitle(index: number, title: string) {
        setChildrenTitles(p => [...p.slice(0, index), title, ...p.slice(index+1)]);
    }

    function addChildTitle() {
        setChildrenTitles(p => [...p, ""]);
    }

    function deleteChildTaxonomy(index: number) {
        setChildrenTitles(p => [...p.slice(0, index), ...p.slice(index+1)]);
    }

    return (
        <Box>
            <FormControl maxW={["100%", null, null, null, "50%"]} isRequired>
                <FormLabel color="gray.300" fontSize="xs">{mainAreaLabel}</FormLabel>
                <Input placeholder={t('meetingthemepage.create-taxonomy-form.input-placeholder')} shadow="md" value={mainTaxonomyTitle} onChange={e => setMainTaxonomyTitle(e.target.value)}
                    size="lg" borderRadius="0" />
            </FormControl>
            <Box maxW={["100%", null, null, null, "60%"]} pl="4" my="4" borderLeft="1px solid" borderColor="gray.200">
                <VStack spacing="4">
                    {childrenTitles.map((t, i) => (
                        <SubTaxonomyForm key={`childTitle-${i}`} value={t} allowDelete={i !== 0} label={childInputLabel}
                            onChange={newValue => setChildTitle(i, newValue)}
                            onDelete={() => deleteChildTaxonomy(i)}
                            isRequired={i === 0} />
                    ))}
                </VStack>
                <Button mt="4" variant="bookMe" onClick={addChildTitle}>+ {createSubTaxonomyLabel}</Button>
            </Box>
            <HStack spacing="4">
                <Button isFullWidth size="lg" leftIcon={<Icon as={FaUndo} />} onClick={reset}>{t('meetingthemepage.create-taxonomy-form.reset')}</Button>
                <CreateButton isFullWidth onClick={save} disabled={isCreateButtonDisabled} text={t('meetingthemepage.create-taxonomy-form.create-button')} />
            </HStack>
        </Box>
    );
};