/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Workday,
    WorkdayFromJSON,
    WorkdayFromJSONTyped,
    WorkdayToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdvisorSchedule
 */
export interface AdvisorSchedule {
    /**
     * 
     * @type {string}
     * @memberof AdvisorSchedule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorSchedule
     */
    advisorId: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorSchedule
     */
    canBeBooked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorSchedule
     */
    isOnlyExplicitlyAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AdvisorSchedule
     */
    isGloballyAvailable: boolean;
    /**
     * 
     * @type {Array<Workday>}
     * @memberof AdvisorSchedule
     */
    workdays: Array<Workday>;
}

export function AdvisorScheduleFromJSON(json: any): AdvisorSchedule {
    return AdvisorScheduleFromJSONTyped(json, false);
}

export function AdvisorScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvisorSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'advisorId': json['advisorId'],
        'canBeBooked': json['canBeBooked'],
        'isOnlyExplicitlyAvailable': json['isOnlyExplicitlyAvailable'],
        'isGloballyAvailable': json['isGloballyAvailable'],
        'workdays': ((json['workdays'] as Array<any>).map(WorkdayFromJSON)),
    };
}

export function AdvisorScheduleToJSON(value?: AdvisorSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'advisorId': value.advisorId,
        'canBeBooked': value.canBeBooked,
        'isOnlyExplicitlyAvailable': value.isOnlyExplicitlyAvailable,
        'isGloballyAvailable': value.isGloballyAvailable,
        'workdays': ((value.workdays as Array<any>).map(WorkdayToJSON)),
    };
}


