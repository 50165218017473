import { useMemo,  } from "react";
import { useTokenContext } from "../TokenContext";
import { Configuration } from "./client";

class BaseConfiguration extends Configuration {
    constructor(token: string) {
        super({
            basePath: window.location.origin,
            headers: {'Authorization': `Bearer ${token}` }
        });
    }
}

export function useBaseConfiguration() {
    const token = useTokenContext();
    const baseConfig = useMemo(() => {
        return new BaseConfiguration(token)}, [token]
    );
    return baseConfig;
}
