/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateAdvisorSchedule
 */
export interface CreateAdvisorSchedule {
    /**
     * 
     * @type {string}
     * @memberof CreateAdvisorSchedule
     */
    advisorId: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisorSchedule
     */
    canBeBooked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAdvisorSchedule
     */
    isGloballyAvailable: boolean;
}

export function CreateAdvisorScheduleFromJSON(json: any): CreateAdvisorSchedule {
    return CreateAdvisorScheduleFromJSONTyped(json, false);
}

export function CreateAdvisorScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAdvisorSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advisorId': json['advisorId'],
        'canBeBooked': json['canBeBooked'],
        'isGloballyAvailable': json['isGloballyAvailable'],
    };
}

export function CreateAdvisorScheduleToJSON(value?: CreateAdvisorSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advisorId': value.advisorId,
        'canBeBooked': value.canBeBooked,
        'isGloballyAvailable': value.isGloballyAvailable,
    };
}


