import { ThemeOverride } from "@chakra-ui/react";

const overrideColors: ThemeOverride = {
    colors: {
        gray: {
            50: '#f0f0fd',
            100: '#d5d5e1',
            200: '#b8b9c7',
            300: '#9c9daf',
            400: '#808198',
            500: '#66677e',
            600: '#505063',
            700: '#393948',
            800: '#21232d',
            900: '#060c16',
        },
        available: '#06BF2EB8',
        customerMeeting: '#7263D9B8',
        bookedInOwnCalendar: '#EAA624B8',
        backgroundGrey: "#F3F4F8",
        bookMeBlue: "#004568",
        bookMeLightBlue: "#D2D6DF",
        bookMeDarkBlue: "#002132",
    }
}

export default overrideColors;