import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { AdvisorSchedule, CreateAdvisorSchedule } from "./client";
import { AdvisorApi } from "./client/apis/AdvisorApi";
import { Advisor } from "./client/models/Advisor";

export const useAdvisorApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const advisorApi = React.useMemo(
        () => new AdvisorApi(baseConfiguration), [baseConfiguration]
    );

    return advisorApi;
};

export const useGetAllAdvisors = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisors"], async () => {
        return client.getAllAdvisors()
    });
};

export const useGetAllBookableAdvisors = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllBookableAdvisors"], async () => {
        return client.getAllBookableAdvisors()
    });
};

export const useGetAllAdvisorsWithSchedule = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisorsWithSchedule"], async () => {
        try {
            return await client.getAllAdvisorsWithSchedule();
        } catch (e: any) {
            if (e.status === undefined && e.status === 404) return [];
            throw e;
        }
    });
};

export const useGetAllAdvisorsWithoutSchedule = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisorsWithoutSchedule"], async () => {
        try {
            return await client.getAllAdvisorsWithoutSchedule();
        } catch (e: any) {
            if (e.status === undefined && e.status === 404) return [];
            throw e;
        }
    });
};

export const useGetAllAdvisorsWithScheduleNonBookable = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisorsWithScheduleNonBookable"], async () => {
        try {
            return await client.getAllAdvisorsWithScheduleNonBookable();
        } catch (e: any) {
            if (e.status === undefined && e.status === 404) return [];
            throw e;
        }
    });
};

export const useGetAllAdvisorsWithoutCompetences = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisorsWithoutCompetences"], async () => {
        try {
            return await client.getAllAdvisorsWithoutCompetences();
        } catch (e: any) {
            if (e.status === undefined && e.status === 404) return [];
            throw e;
        }
    });
};

export const useGetAllAdvisorsWithoutCustomerCategories = () => {
    const client = useAdvisorApi();
    return useQuery(["getAllAdvisorsWithoutCustomerCategories"], async () => {
        try {
            return await client.getAllAdvisorsWithoutCustomerCategories();
        } catch (e: any) {
            if (e.status === undefined && e.status === 404) return [];
            throw e;
        }
    });
};

export const useUpdateAdvisorScheduleIsBookable = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        ({ advisorId, canBeBooked }: { advisorId: string, canBeBooked: boolean }) =>
            client.updateAdvisorScheduleCanBeBooked({ id: advisorId, canBeBooked: canBeBooked }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisorsWithSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithoutSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithScheduleNonBookable"]);
            }
        }
    );
};

// update a single advisor schedule
export const useUpdateAdvisorSchedule = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        ({ advisorId, advisorSchedule }: { advisorId: string, advisorSchedule: AdvisorSchedule }) =>
            client.updateAdvisorSchedule({ id: advisorId, advisorSchedule: advisorSchedule }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisorsWithSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithoutSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithScheduleNonBookable"]);
            }
        }
    );
};

// update all advisor schedules, filtered on location
export const useUpdateAllAdvisorSchedules = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        ({ advisorSchedule, location }: { advisorSchedule: AdvisorSchedule, location?: string}) =>
            client.updateAllAdvisorSchedules({ advisorSchedule, location }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisorsWithSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithoutSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithScheduleNonBookable"]);
            }
        }
    );
}

export const useDeleteAdvisorSchedule = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        (advisorId: string) =>
            client.deleteAdvisorSchedule({ id: advisorId }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisorsWithSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithoutSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithScheduleNonBookable"]);
            }
        }
    );
};

export const useCreateAdvisorSchedule = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        (advisorSchedule: CreateAdvisorSchedule) =>
            client.createAdvisorSchedule({ createAdvisorSchedule: advisorSchedule }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisorsWithSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithoutSchedule"]);
                queryClient.invalidateQueries(["getAllAdvisorsWithScheduleNonBookable"]);
            }
        }
    );
};

export const useUpdateAdvisor = () => {
    const client = useAdvisorApi();
    const queryClient = useQueryClient();
    return useMutation(
        (advisor: Advisor) =>
            client.updateAdvisor({ advisor: advisor }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllAdvisors"]);
            }
        }
    );
};
