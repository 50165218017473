/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Workday,
    WorkdayFromJSON,
    WorkdayFromJSONTyped,
    WorkdayToJSON,
} from './';

/**
 * 
 * @export
 * @interface ScheduledAdvisor
 */
export interface ScheduledAdvisor {
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledAdvisor
     */
    canBeBooked: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledAdvisor
     */
    isGloballyAvailable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledAdvisor
     */
    isOnlyExplicitlyAvailable: boolean;
    /**
     * 
     * @type {Array<Workday>}
     * @memberof ScheduledAdvisor
     */
    workdays: Array<Workday>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    scheduleId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    initials: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledAdvisor
     */
    location?: string | null;
}

export function ScheduledAdvisorFromJSON(json: any): ScheduledAdvisor {
    return ScheduledAdvisorFromJSONTyped(json, false);
}

export function ScheduledAdvisorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledAdvisor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canBeBooked': json['canBeBooked'],
        'isGloballyAvailable': json['isGloballyAvailable'],
        'isOnlyExplicitlyAvailable': json['isOnlyExplicitlyAvailable'],
        'workdays': ((json['workdays'] as Array<any>).map(WorkdayFromJSON)),
        'scheduleId': !exists(json, 'scheduleId') ? undefined : json['scheduleId'],
        'id': json['id'],
        'displayName': json['displayName'],
        'initials': json['initials'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function ScheduledAdvisorToJSON(value?: ScheduledAdvisor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canBeBooked': value.canBeBooked,
        'isGloballyAvailable': value.isGloballyAvailable,
        'isOnlyExplicitlyAvailable': value.isOnlyExplicitlyAvailable,
        'workdays': ((value.workdays as Array<any>).map(WorkdayToJSON)),
        'scheduleId': value.scheduleId,
        'id': value.id,
        'displayName': value.displayName,
        'initials': value.initials,
        'email': value.email,
        'location': value.location,
    };
}


