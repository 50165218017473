/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserType,
    UserTypeFromJSON,
    UserTypeFromJSONTyped,
    UserTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface BookingBy
 */
export interface BookingBy {
    /**
     * 
     * @type {UserType}
     * @memberof BookingBy
     */
    canBeBookedBy: UserType;
    /**
     * 
     * @type {string}
     * @memberof BookingBy
     */
    customerCategoryId?: string | null;
}

export function BookingByFromJSON(json: any): BookingBy {
    return BookingByFromJSONTyped(json, false);
}

export function BookingByFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookingBy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'canBeBookedBy': UserTypeFromJSON(json['canBeBookedBy']),
        'customerCategoryId': !exists(json, 'customerCategoryId') ? undefined : json['customerCategoryId'],
    };
}

export function BookingByToJSON(value?: BookingBy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'canBeBookedBy': UserTypeToJSON(value.canBeBookedBy),
        'customerCategoryId': value.customerCategoryId,
    };
}


