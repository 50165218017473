/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ServiceGroup,
    ServiceGroupFromJSON,
    ServiceGroupToJSON,
    ServiceGroupMembership,
    ServiceGroupMembershipFromJSON,
    ServiceGroupMembershipToJSON,
    ServiceLevel,
    ServiceLevelFromJSON,
    ServiceLevelToJSON,
    ServiceTrigger,
    ServiceTriggerFromJSON,
    ServiceTriggerToJSON,
} from '../models';

export interface CreateServiceGroupRequest {
    serviceGroup?: ServiceGroup;
}

export interface DeleteServiceGroupRequest {
    id: string;
}

export interface GetServiceGroupRequest {
    id: string;
}

export interface GetServiceGroupMembershipRequest {
    id: string;
}

export interface UpdateServiceGroupRequest {
    id: string;
    serviceGroup?: ServiceGroup;
}

export interface UpdateServiceGroupServiceLevelRequest {
    id: string;
    serviceLevel?: ServiceLevel;
}

export interface UpdateServiceGroupServiceTriggerRequest {
    id: string;
    serviceTrigger?: ServiceTrigger;
}

/**
 * 
 */
export class ServiceGroupApi extends runtime.BaseAPI {

    /**
     */
    async createServiceGroupRaw(requestParameters: CreateServiceGroupRequest): Promise<runtime.ApiResponse<ServiceGroup>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/ServiceGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceGroupToJSON(requestParameters.serviceGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceGroupFromJSON(jsonValue));
    }

    /**
     */
    async createServiceGroup(requestParameters: CreateServiceGroupRequest): Promise<ServiceGroup> {
        const response = await this.createServiceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteServiceGroupRaw(requestParameters: DeleteServiceGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteServiceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ServiceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteServiceGroup(requestParameters: DeleteServiceGroupRequest): Promise<void> {
        await this.deleteServiceGroupRaw(requestParameters);
    }

    /**
     */
    async getAllServiceGroupsRaw(): Promise<runtime.ApiResponse<Array<ServiceGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ServiceGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ServiceGroupFromJSON));
    }

    /**
     */
    async getAllServiceGroups(): Promise<Array<ServiceGroup>> {
        const response = await this.getAllServiceGroupsRaw();
        return await response.value();
    }

    /**
     */
    async getServiceGroupRaw(requestParameters: GetServiceGroupRequest): Promise<runtime.ApiResponse<ServiceGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ServiceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceGroupFromJSON(jsonValue));
    }

    /**
     */
    async getServiceGroup(requestParameters: GetServiceGroupRequest): Promise<ServiceGroup> {
        const response = await this.getServiceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getServiceGroupMembershipRaw(requestParameters: GetServiceGroupMembershipRequest): Promise<runtime.ApiResponse<ServiceGroupMembership>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceGroupMembership.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/ServiceGroup/{id}/membership`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceGroupMembershipFromJSON(jsonValue));
    }

    /**
     */
    async getServiceGroupMembership(requestParameters: GetServiceGroupMembershipRequest): Promise<ServiceGroupMembership> {
        const response = await this.getServiceGroupMembershipRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateServiceGroupRaw(requestParameters: UpdateServiceGroupRequest): Promise<runtime.ApiResponse<ServiceGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateServiceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/ServiceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceGroupToJSON(requestParameters.serviceGroup),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ServiceGroupFromJSON(jsonValue));
    }

    /**
     */
    async updateServiceGroup(requestParameters: UpdateServiceGroupRequest): Promise<ServiceGroup> {
        const response = await this.updateServiceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateServiceGroupServiceLevelRaw(requestParameters: UpdateServiceGroupServiceLevelRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateServiceGroupServiceLevel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/ServiceGroup/{id}/serviceLevel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceLevelToJSON(requestParameters.serviceLevel),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateServiceGroupServiceLevel(requestParameters: UpdateServiceGroupServiceLevelRequest): Promise<void> {
        await this.updateServiceGroupServiceLevelRaw(requestParameters);
    }

    /**
     */
    async updateServiceGroupServiceTriggerRaw(requestParameters: UpdateServiceGroupServiceTriggerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateServiceGroupServiceTrigger.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/ServiceGroup/{id}/serviceTrigger`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceTriggerToJSON(requestParameters.serviceTrigger),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateServiceGroupServiceTrigger(requestParameters: UpdateServiceGroupServiceTriggerRequest): Promise<void> {
        await this.updateServiceGroupServiceTriggerRaw(requestParameters);
    }

}
