import { BoxProps, VStack, Heading, HStack, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ServiceGroup, ServiceLevelMeetingType } from "../../api/client";
import { useGetMeetingTypeLabels } from "../../api/useBankOptionsApi";
import { useGetLocations } from "../../api/useLocationApi";
import { useUpdateServiceLevel } from "../../api/useServiceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard";
import { OptionValue } from "../DataList";
import { DisplayList } from "./DisplayList";

interface Props extends BoxProps {
    selectedServiceGroup: ServiceGroup;
}
export const UpdateServiceLevel = ({ selectedServiceGroup, ...props }: Props) => {
    const { data: locationConfigurations } = useGetLocations();
    const { data: meetingTypes } = useGetMeetingTypeLabels();
    const updateServiceLevelMutation = useUpdateServiceLevel();
    const { t } = useTranslation();

    const locationConfigurationOptions: OptionValue[] = useMemo(() => {
        return locationConfigurations?.map(lc => { return { value: lc.id, label: lc.location } }) || [];
    }, [locationConfigurations]);

    const meetingTypeOptions: OptionValue[] = useMemo(() => {
        return meetingTypes?.map(mt => { return { value: mt.name!, label: mt.label! } }) || [];
    }, [meetingTypes]);

    const serviceLevel = useMemo(() => {
        return selectedServiceGroup.serviceLevel;
    }, [selectedServiceGroup]);

    if (!serviceLevel) {
        return (<></>);
    }

    function handleLocationsChanged(ids: string[]): void {
        if (!serviceLevel) {
            return;
        }

        updateServiceLevelMutation.mutate({
            id: selectedServiceGroup.id!,
            serviceLevel: {
                ...serviceLevel,
                locationConfigurationIds: ids
            }
        });
    }

    function handleMeetingTypesChanged(ids: string[]): void {
        const serviceLevelMeetingTypes: ServiceLevelMeetingType[] | undefined = meetingTypes?.filter(mt => ids.includes(mt.name!)).map(mt => {
            return {
                type: mt.name!,
            }
        });

        if (!serviceLevel) {
            return;
        }

        updateServiceLevelMutation.mutate({
            id: selectedServiceGroup.id!,
            serviceLevel: {
                ...serviceLevel,
                meetingTypes: serviceLevelMeetingTypes
            }
        });
    }

    return (
        <ContentCard {...props}>
            <VStack align="start" spacing="8">
                <VStack align="start" w="50%">
                    <Heading as="h2" size="lg" fontWeight="semibold">{t('servicegrouppage.update-level.heading')}</Heading>
                    <Text color="gray.300" fontSize="xs">{t('servicegrouppage.update-level.description')} </Text>
                </VStack>
                <HStack spacing="8" align="stretch" justify="stretch" w="100%">
                    <DisplayList heading={t('servicegrouppage.update-level.location-list.heading')} selectedItems={serviceLevel.locationConfigurationIds || []} options={locationConfigurationOptions} placeholder={t('servicegrouppage.update-level.location-list.placeholder')}  handleOnChange={ids => handleLocationsChanged(ids)} />
                    <DisplayList heading={t('servicegrouppage.update-level.meeting-type-list.heading')} selectedItems={serviceLevel.meetingTypes?.map(x => x.type!.toString()) || []} options={meetingTypeOptions} placeholder={t('servicegrouppage.update-level.meeting-type-list.placeholder')} handleOnChange={ids => handleMeetingTypesChanged(ids)} />
                </HStack>
            </VStack>
        </ContentCard>
    )
}