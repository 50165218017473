import React from "react";
import { VStack, FormControl, FormLabel, Input, Select, HStack, OrderedList, Text, Checkbox, Box } from "@chakra-ui/react"
import { AdvisorBankOptionsItem, BankAdvisorOptions, MeetingTypeBankOptionItem, MeetingTypeEnum, UpdateBankOptions } from "../api/client";
import { useCreateClosingDays, useDeleteClosingDays, useGetBankOptions, useUpdateBankOptions } from "../api/useBankOptionsApi";
import { ContentCard } from "../components/ContentCard/ContentCard";
import { TimeRangeInput } from "../components/TimeRangeInput/TimeRangeInput";
import { useDebouncedMutation } from "../helpers/debounce";
import { OrdereableListItem } from "../components/ItemListView/OrderableListItem";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { DateSelector } from "../components/DateSelector";
import { DateList } from "../components/DateList";



export const DefaultValuesPage = () => {
    const { data: bankOptions } = useGetBankOptions();
    const [physicalLabel, setPhysicalLabel] = React.useState("");
    const [onlineLabel, setOnlineLabel] = React.useState("");
    const [telephoneLabel, setTelephoneLabel] = React.useState("");
    const [offSiteLabel, setOffSiteLabel] = React.useState("");
    const [primaryAdvisorLabel, setPrimaryAdvisorLabel] = React.useState("");
    const [otherAdvisorsLabel, setOtherAdvisorsLabel] = React.useState("");
    const [showTimeAsCustomerFilterDefault, setShowTimeAsCustomerFilterDefault] = React.useState<boolean>();
    const [pendingChanges, setPendingChanges] = React.useState<UpdateBankOptions | null>(null);
    const [advisorLabels, setAdvisorLabels] = React.useState<AdvisorBankOptionsItem[] | undefined>();
    const [meetingTypeLabels, setMeetingTypeLabels] = React.useState<MeetingTypeBankOptionItem[] | undefined>();
    const [closingDays, setClosingDays] = React.useState<Date[]>([]);
    const resetPendingChanges = React.useCallback(() => setPendingChanges(null), []);
    const { mutate } = useDebouncedMutation(useUpdateBankOptions(resetPendingChanges), 800);
    const createClosingDaysMutation = useCreateClosingDays();
    const deleteClosingDaysMutation = useDeleteClosingDays();
    const { t } = useTranslation();

    const dayOptions = Array.from(Array(61).keys()).map(x => ({ label: x.toString() + (x !== 1 ? ` ${i18n.t('defaultvaluepage.general.days.label')}` : ` ${i18n.t('defaultvaluepage.general.day.label')}`), value: `${x}.00:00:00` }));
    const meetingAmountOptions = Array.from(Array(20).keys()).map(x => {
        const value = x + 1;
        return ({ label: (value.toString() + (value !== 1 ? ` ${i18n.t('defaultvaluepage.general.meetings.label')}` : ` ${i18n.t('defaultvaluepage.general.meeting.label')}`)), value: value });
    });

    React.useEffect(() => {
        if (bankOptions === undefined) return;
        setPhysicalLabel(bankOptions.meetingTypeLabels.find(x => x.name === MeetingTypeEnum.Physical)?.label || "");
        setOnlineLabel(bankOptions.meetingTypeLabels.find(x => x.name === MeetingTypeEnum.Online)?.label || "");
        setTelephoneLabel(bankOptions.meetingTypeLabels.find(x => x.name === MeetingTypeEnum.Telephone)?.label || "");
        setOffSiteLabel(bankOptions.meetingTypeLabels.find(x => x.name === MeetingTypeEnum.OffSite)?.label || "");
        setPrimaryAdvisorLabel(bankOptions.advisorLabels.find(x => x.name === BankAdvisorOptions.PrimaryAdvisor)?.label || "");
        setOtherAdvisorsLabel(bankOptions.advisorLabels.find(x => x.name === BankAdvisorOptions.OtherAdvisors)?.label || "");
        setAdvisorLabels(bankOptions.advisorLabels);
        setMeetingTypeLabels(bankOptions.meetingTypeLabels);
        setShowTimeAsCustomerFilterDefault(bankOptions.isFilterShowTimesAsCustomerEnabled);

        // Sort the closing days to ensure they are displayed in the correct order
        setClosingDays(bankOptions.closingDays.map(x => new Date(x)).sort((a, b) => b.getTime() - a.getTime()));
    }, [bankOptions]);
    React.useEffect(() => {
        if (pendingChanges === null) return;
        mutate(pendingChanges);
    }, [pendingChanges, mutate]);


    if (bankOptions === undefined) return null;


    function handleUpdateMeetingTypeLabel(type: MeetingTypeEnum, value: string) {
        if (bankOptions === undefined) return;
        let newMeetingTypeLabels: MeetingTypeBankOptionItem[] = [];

        const existingMeetingTypeLabel = bankOptions.meetingTypeLabels.find(x => x.name === type);
        if (existingMeetingTypeLabel === undefined) return;

        if (!pendingChanges?.meetingTypeLabels) {
            newMeetingTypeLabels = [...bankOptions.meetingTypeLabels.filter(x => x.name !== type), { ...existingMeetingTypeLabel, label: value }];
        } else {
            newMeetingTypeLabels = [...pendingChanges.meetingTypeLabels.filter(x => x.name !== type), { ...existingMeetingTypeLabel, label: value }];
        }
        setPendingChanges(prev => ({ ...prev, id: bankOptions.id, meetingTypeLabels: newMeetingTypeLabels }));
    }

    function handleUpdateAdvisorLabel(type: BankAdvisorOptions, value: string) {
        if (bankOptions === undefined) return;
        let newAdvisorLabels: AdvisorBankOptionsItem[] = [];

        const existingAdvisorLabel = bankOptions.advisorLabels.find(x => x.name === type);
        if (existingAdvisorLabel === undefined) return;

        if (!pendingChanges?.advisorLabels) {
            newAdvisorLabels = [...bankOptions.advisorLabels.filter(x => x.name !== type), { ...existingAdvisorLabel, label: value }];
        } else {
            newAdvisorLabels = [...pendingChanges.advisorLabels.filter(x => x.name !== type), { ...existingAdvisorLabel, label: value }];
        }
        setPendingChanges(prev => ({ ...prev, id: bankOptions.id, advisorLabels: newAdvisorLabels }));
    }

    function handlePhysicalLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPhysicalLabel(event.target.value);
        handleUpdateMeetingTypeLabel(MeetingTypeEnum.Physical, event.target.value);
    }

    function handleOnlineLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setOnlineLabel(event.target.value);
        handleUpdateMeetingTypeLabel(MeetingTypeEnum.Online, event.target.value);
    }

    function handleTelephoneLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setTelephoneLabel(event.target.value);
        handleUpdateMeetingTypeLabel(MeetingTypeEnum.Telephone, event.target.value);
    }

    function handleOffSiteLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setOffSiteLabel(event.target.value);
        handleUpdateMeetingTypeLabel(MeetingTypeEnum.OffSite, event.target.value);
    }

    function handlePrimaryAdvisorLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPrimaryAdvisorLabel(event.target.value);
        handleUpdateAdvisorLabel(BankAdvisorOptions.PrimaryAdvisor, event.target.value);
    }

    function handleOtherAdvisorsLabelChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setOtherAdvisorsLabel(event.target.value);
        handleUpdateAdvisorLabel(BankAdvisorOptions.OtherAdvisors, event.target.value);
    }

    function handleShowTimeAsCustomerFilterDefaultChanged(value: boolean) {
        if (bankOptions === undefined) return;
        setShowTimeAsCustomerFilterDefault(value);
        setPendingChanges(prev => ({ ...prev, id: bankOptions?.id, isFilterShowTimesAsCustomerEnabled: value }));
    }

    type BankOptionLabelType = "advisor" | "meeting";

    type MoveDirection = "up" | "down";

    function handleMoveItemClicked(name: string | undefined, direction: MoveDirection, type: BankOptionLabelType) {
        if (!bankOptions) return;

        if (type == "advisor" && advisorLabels) {
            const newOrderedLabels = getNewOrderedLabels(advisorLabels, direction, name) as AdvisorBankOptionsItem[] | undefined;
            if (newOrderedLabels === undefined) return;

            setAdvisorLabels(newOrderedLabels);
            setPendingChanges(prev => ({ ...prev, id: bankOptions.id, advisorLabels: newOrderedLabels }));
        }

        if (type === "meeting" && meetingTypeLabels) {
            const newOrderedLabels = getNewOrderedLabels(meetingTypeLabels, direction, name) as MeetingTypeBankOptionItem[] | undefined;
            if (newOrderedLabels === undefined) return;

            setMeetingTypeLabels(newOrderedLabels);
            setPendingChanges(prev => ({ ...prev, id: bankOptions.id, meetingTypeLabels: newOrderedLabels }));
        }
    }

    function getNewOrderedLabels(orderedLabels: MeetingTypeBankOptionItem[] | AdvisorBankOptionsItem[], direction: MoveDirection, name: string | undefined) {
        const index = orderedLabels?.findIndex(x => x.name === name);
        if (index === -1) return;

        const targetIndex = direction === "down" ? index + 1 : index - 1;

        if (targetIndex < 0 || targetIndex > orderedLabels.length - 1) return;

        const newOrderedLabels = direction === "down"
            ? [...orderedLabels.slice(0, index), orderedLabels[targetIndex], orderedLabels[index], ...orderedLabels.slice(targetIndex + 1)]
            : [...orderedLabels.slice(0, targetIndex), orderedLabels[index], orderedLabels[targetIndex], ...orderedLabels.slice(index + 1)];
        newOrderedLabels.forEach((x, i) => x.order = i);

        return newOrderedLabels;
    }

    function handleAddClosingDate(date: Date | null) {
        if (!bankOptions) return;

        if (date && !closingDays.includes(date)) {
            createClosingDaysMutation.mutate({ bankOptionsId: bankOptions.id, closingDays: [date] });
        }
    }

    function handleRemoveClosingDate(dateToRemove: Date) {
        if (!bankOptions) return;

        deleteClosingDaysMutation.mutate({ bankOptionsId: bankOptions.id, closingDays: [dateToRemove] });

    }

    return (
        <VStack justifyContent="start" spacing="8" width={["100%", "100%", "100%", "100%", "70%", "50%"]} alignItems="start">
            <HStack width="100%" alignItems="stretch" spacing={8}>
                <ContentCard isFullWidth heading={t('defaultvaluepage.general.header')}>
                    <VStack alignItems="start" spacing="6">
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.general.opening-hours.label')}</FormLabel>
                            <TimeRangeInput minW="40%" startTime={bankOptions.openingTime} endTime={bankOptions.closingTime}
                                onStartTimeChanged={x => mutate({ id: bankOptions.id, openingTime: x })}
                                onEndTimeChanged={x => mutate({ id: bankOptions.id, closingTime: x })} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.general.max-booking-days.label')}</FormLabel>
                            <Select minW="40%" value={bankOptions.maxTimeFromBookingToMeeting} onChange={x => mutate({ id: bankOptions.id, maxTimeFromBookingToMeeting: x.target.value })}>
                                {dayOptions.map(x => (<option key={x.value.toString()} id={x.value.toString()} value={x.value}>{x.label}</option>))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.general.max-meetings.placeholder')}</FormLabel>
                            <Select minW="40%" value={bankOptions.maxNumberOfMeetingsPerDay} onChange={x => mutate({ id: bankOptions.id, maxNumberOfMeetingsPerDay: Number(x.target.value) })}>
                                {meetingAmountOptions.map(x => (<option key={x.value.toString()} id={x.value.toString()} value={x.value}>{x.label}</option>))}
                            </Select>
                        </FormControl>
                    </VStack>
                </ContentCard>
                <ContentCard isFullWidth heading={t('defaultvaluepage.filter-configuration.header')} subHeading={t('defaultvaluepage.filter-configuration.description')} >
                    {showTimeAsCustomerFilterDefault !== undefined &&
                        <Checkbox size="lg" onChange={val => handleShowTimeAsCustomerFilterDefaultChanged(val.target.checked)}
                            isChecked={showTimeAsCustomerFilterDefault}>{t('defaultvaluepage.filter-configuration.show-as-customer.checkbox')}</Checkbox>
                    }
                </ContentCard>
            </HStack>

            <HStack width="100%" alignItems="stretch" spacing={8}>
                <ContentCard width="50%" heading={t('defaultvaluepage.closing-days-selector.header')} subHeading={t('defaultvaluepage.closing-days-selector.subheader')}>
                    <DateSelector header={t('date-selector.header')}  onDateAdded={(date: Date | null) => handleAddClosingDate(date)} />
                </ContentCard>
                <ContentCard width="50%" heading={t('defaultvaluepage.closing-days.header')} subHeading={t('defaultvaluepage.closing-days.subheader')}>
                    <DateList selectedDates={closingDays}  onDateRemoved={(date: Date) => handleRemoveClosingDate(date)} />
                </ContentCard>
            </HStack>

            <HStack width="100%" alignItems="stretch" spacing={8}>
                <ContentCard width="50%" heading={t('defaultvaluepage.meetingtype-naming.header')}>
                    <VStack alignItems="start" spacing="6">
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.meetingtype-naming.physical')}</FormLabel>
                            <Input type="text" value={physicalLabel}
                                onChange={handlePhysicalLabelChanged} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.meetingtype-naming.online')}</FormLabel>
                            <Input type="text" value={onlineLabel}
                                onChange={handleOnlineLabelChanged} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.meetingtype-naming.telephone')}</FormLabel>
                            <Input type="text" value={telephoneLabel}
                                onChange={handleTelephoneLabelChanged} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.meetingtype-naming.offsite')}</FormLabel>
                            <Input type="text" value={offSiteLabel}
                                onChange={handleOffSiteLabelChanged} />
                        </FormControl>
                    </VStack>
                </ContentCard>
                <ContentCard width="50%" heading={t('defaultvaluepage.meetingtype-order.header')}>
                    <OrderedList>
                        {meetingTypeLabels && meetingTypeLabels.map((meetingType, index) => {
                            return <OrdereableListItem key={index}
                                handleMoveDownClicked={() => handleMoveItemClicked(meetingType.name, "down", "meeting")}
                                handleMoveUpClicked={() => handleMoveItemClicked(meetingType.name, "up", "meeting")}>
                                <Text>{meetingType.label}</Text>
                            </OrdereableListItem>
                        })}
                    </OrderedList>
                </ContentCard>
            </HStack>

            <HStack width="100%" alignItems="stretch" spacing={8}>
                <ContentCard width="50%" heading={t('defaultvaluepage.employee-naming.header')}>
                    <VStack alignItems="start" spacing="6">
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.employee-naming.primary-employee.label')}</FormLabel>
                            <Input type="text" value={primaryAdvisorLabel} onChange={handlePrimaryAdvisorLabelChanged} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>{t('defaultvaluepage.employee-naming.all-employees.label')}</FormLabel>
                            <Input type="text" value={otherAdvisorsLabel} onChange={handleOtherAdvisorsLabelChanged} />
                        </FormControl>
                    </VStack>
                </ContentCard>
                <ContentCard width="50%" heading={t('defaultvaluepage.employee-order.header')}>
                    <OrderedList>
                        {advisorLabels && advisorLabels.map((advisorLabel, index) => {
                            return <OrdereableListItem key={index}
                                handleMoveDownClicked={() => handleMoveItemClicked(advisorLabel.name, "down", "advisor")}
                                handleMoveUpClicked={() => handleMoveItemClicked(advisorLabel.name, "up", "advisor")}>
                                <Text>{advisorLabel.label}</Text>
                            </OrdereableListItem>
                        })}
                    </OrderedList>
                </ContentCard>
            </HStack>


        </VStack>
    )
};
