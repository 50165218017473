import { TranslationTermDto } from './api/client';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend, { ChainedBackendOptions } from 'i18next-chained-backend';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { USE_FALLBACK_LANGUAGE } from './environmentVariables';



const httpBackendOptions: HttpBackendOptions = {
  loadPath: '/api/Localization/{{lng}}',
  parse: (data) => {
    return Object.fromEntries(
      JSON.parse(data).map((item: TranslationTermDto) => [
        item.term,
        item.value,
      ])
    );
  }
};


// const USE_HTTP_BACKEND = !USE_LOCALIZATION_LOCAL_LOAD_PATH;

// At the moment, always use http backend to get translations
const backends = [HttpBackend];
const backendOptions = [httpBackendOptions];

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<ChainedBackendOptions>({
    lng: 'da',
    fallbackLng: USE_FALLBACK_LANGUAGE ? 'da' : undefined,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    backend: {
      backends,
      backendOptions,
    },
    returnEmptyString: false
  });


export default i18n;
