/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Taxonomy,
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompetenceInheritage
 */
export interface CompetenceInheritage {
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof CompetenceInheritage
     */
    customerCategories?: Array<Taxonomy> | null;
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof CompetenceInheritage
     */
    themes?: Array<Taxonomy> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceInheritage
     */
    advisorIds?: Array<string> | null;
}

export function CompetenceInheritageFromJSON(json: any): CompetenceInheritage {
    return CompetenceInheritageFromJSONTyped(json, false);
}

export function CompetenceInheritageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetenceInheritage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerCategories': !exists(json, 'customerCategories') ? undefined : (json['customerCategories'] === null ? null : (json['customerCategories'] as Array<any>).map(TaxonomyFromJSON)),
        'themes': !exists(json, 'themes') ? undefined : (json['themes'] === null ? null : (json['themes'] as Array<any>).map(TaxonomyFromJSON)),
        'advisorIds': !exists(json, 'advisorIds') ? undefined : json['advisorIds'],
    };
}

export function CompetenceInheritageToJSON(value?: CompetenceInheritage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerCategories': value.customerCategories === undefined ? undefined : (value.customerCategories === null ? null : (value.customerCategories as Array<any>).map(TaxonomyToJSON)),
        'themes': value.themes === undefined ? undefined : (value.themes === null ? null : (value.themes as Array<any>).map(TaxonomyToJSON)),
        'advisorIds': value.advisorIds,
    };
}


