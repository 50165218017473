import React from "react";
import {
    Button,
    Box,
    Flex
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
    onResetClicked?: () => void;
}

export const InputWithReset = ({ onResetClicked, children }: React.PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    return (
        <Flex alignItems="end">
            <Box flex="1 1">{children}</Box>
            <Button ml="8" onClick={onResetClicked}>{t('meetingconfigurationpage.input-reset-button')}</Button>
        </Flex>
    );
};