/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LanguageDto,
    LanguageDtoFromJSON,
    LanguageDtoToJSON,
    TranslationTermDto,
    TranslationTermDtoFromJSON,
    TranslationTermDtoToJSON,
} from '../models';

export interface GetTranslationTermsRequest {
    language: string;
}

/**
 * 
 */
export class LocalizationApi extends runtime.BaseAPI {

    /**
     */
    async getLanguagesRaw(): Promise<runtime.ApiResponse<Array<LanguageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Localization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LanguageDtoFromJSON));
    }

    /**
     */
    async getLanguages(): Promise<Array<LanguageDto>> {
        const response = await this.getLanguagesRaw();
        return await response.value();
    }

    /**
     */
    async getTranslationTermsRaw(requestParameters: GetTranslationTermsRequest): Promise<runtime.ApiResponse<Array<TranslationTermDto>>> {
        if (requestParameters.language === null || requestParameters.language === undefined) {
            throw new runtime.RequiredError('language','Required parameter requestParameters.language was null or undefined when calling getTranslationTerms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Localization/{language}`.replace(`{${"language"}}`, encodeURIComponent(String(requestParameters.language))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TranslationTermDtoFromJSON));
    }

    /**
     */
    async getTranslationTerms(requestParameters: GetTranslationTermsRequest): Promise<Array<TranslationTermDto>> {
        const response = await this.getTranslationTermsRaw(requestParameters);
        return await response.value();
    }

}
