import { BoxProps, VStack, Box, Text, Input, HStack, Button, Heading, useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ServiceGroup } from "../../api/client";
import { useGetAllAdvisors } from "../../api/useAdvisorApi";
import { useDeleteServiceGroup, useUpdateServiceGroup } from "../../api/useServiceGroupApi";
import { ArrowLeftIcon } from "../../icons/ArrowLeftIcon";
import { ContentCard } from "../ContentCard/ContentCard";
import { OptionValue } from "../DataList";
import { DeleteModal } from "../ItemListView/DeleteModal";
import { DisplayList } from "./DisplayList";

interface Props extends BoxProps {
    selectedServiceGroupInput: ServiceGroup;
    backClicked: () => void;
    onServiceGroupDeleted: () => void;
}
export const UpdateServiceGroup = ({ selectedServiceGroupInput, backClicked, onServiceGroupDeleted, ...props }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const updateServiceGroupMutation = useUpdateServiceGroup();
    const deleteServiceGroupMutation = useDeleteServiceGroup();
    const { t } = useTranslation();

    const selectedServiceGroup = useMemo(() => {
        return selectedServiceGroupInput;
    }, [selectedServiceGroupInput]);


    function onDeleteConfirm() {
        deleteServiceGroupMutation.mutate(selectedServiceGroup.id!);
        onServiceGroupDeleted();
        onClose();
    }

    function handleUpdateServiceGroupName(value: string): void {
        if (!value) {
            return;
        }
        updateServiceGroupMutation.mutate({
            id: selectedServiceGroup.id!, serviceGroup: {
                ...selectedServiceGroup,
                name: value
            }
        });
    }

    return (
        <ContentCard {...props}>
            <VStack align="stretch" spacing="8">
                <HStack justify="space-between">
                    <HStack>
                        <Button borderRadius="full" bgColor="unset" _hover={{ bgColor: "bookMeLightBlue" }} onClick={() => backClicked()}>
                            <ArrowLeftIcon width="24px" height="24px" />
                        </Button>
                        <Heading as="h2" size="xl" fontWeight="semibold">{t('servicegrouppage.update-group.heading', { groupName: selectedServiceGroup?.name })}</Heading>
                    </HStack>
                    <Button aria-label="delete-button" colorScheme="red" onClick={onOpen}>{t('servicegrouppage.update-group.delete.button')}</Button>
                </HStack>
                <HStack spacing="8" align="stretch" justify="stretch">
                    <VStack w="50%" spacing="4" align="stretch" justify="end">
                        <Box >
                            <Text color="gray.300" fontSize="xs">{t('servicegrouppage.update-group.name.label')}</Text>
                            <Input placeholder={t('servicegrouppage.update-group.name.placeholder')} size="lg" shadow="md" defaultValue={selectedServiceGroup.name || ""} onChange={e => handleUpdateServiceGroupName(e.target.value)}
                                borderRadius="0">
                            </Input>
                        </Box>
                    </VStack>
                </HStack>
            </VStack>
            <DeleteModal isOpen={isOpen} itemName={selectedServiceGroup.name || ""} onClose={onClose} onConfirm={onDeleteConfirm}>
                {t('servicegrouppage.update-group.delete.modal', { groupName: selectedServiceGroup?.name })}
            </DeleteModal>
        </ContentCard>);
}