/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvisorBankOptionsItem,
    AdvisorBankOptionsItemFromJSON,
    AdvisorBankOptionsItemFromJSONTyped,
    AdvisorBankOptionsItemToJSON,
    MeetingTypeBankOptionItem,
    MeetingTypeBankOptionItemFromJSON,
    MeetingTypeBankOptionItemFromJSONTyped,
    MeetingTypeBankOptionItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface BankOptions
 */
export interface BankOptions {
    /**
     * 
     * @type {string}
     * @memberof BankOptions
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BankOptions
     */
    openingTime: string;
    /**
     * 
     * @type {string}
     * @memberof BankOptions
     */
    closingTime: string;
    /**
     * 
     * @type {string}
     * @memberof BankOptions
     */
    maxTimeFromBookingToMeeting: string;
    /**
     * 
     * @type {number}
     * @memberof BankOptions
     */
    maxNumberOfMeetingsPerDay: number;
    /**
     * 
     * @type {boolean}
     * @memberof BankOptions
     */
    isFilterShowTimesAsCustomerEnabled: boolean;
    /**
     * 
     * @type {Array<MeetingTypeBankOptionItem>}
     * @memberof BankOptions
     */
    meetingTypeLabels: Array<MeetingTypeBankOptionItem>;
    /**
     * 
     * @type {Array<AdvisorBankOptionsItem>}
     * @memberof BankOptions
     */
    advisorLabels: Array<AdvisorBankOptionsItem>;
    /**
     * 
     * @type {Array<Date>}
     * @memberof BankOptions
     */
    closingDays: Array<Date>;
}

export function BankOptionsFromJSON(json: any): BankOptions {
    return BankOptionsFromJSONTyped(json, false);
}

export function BankOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'openingTime': json['openingTime'],
        'closingTime': json['closingTime'],
        'maxTimeFromBookingToMeeting': json['maxTimeFromBookingToMeeting'],
        'maxNumberOfMeetingsPerDay': json['maxNumberOfMeetingsPerDay'],
        'isFilterShowTimesAsCustomerEnabled': json['isFilterShowTimesAsCustomerEnabled'],
        'meetingTypeLabels': ((json['meetingTypeLabels'] as Array<any>).map(MeetingTypeBankOptionItemFromJSON)),
        'advisorLabels': ((json['advisorLabels'] as Array<any>).map(AdvisorBankOptionsItemFromJSON)),
        'closingDays': json['closingDays'],
    };
}

export function BankOptionsToJSON(value?: BankOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'openingTime': value.openingTime,
        'closingTime': value.closingTime,
        'maxTimeFromBookingToMeeting': value.maxTimeFromBookingToMeeting,
        'maxNumberOfMeetingsPerDay': value.maxNumberOfMeetingsPerDay,
        'isFilterShowTimesAsCustomerEnabled': value.isFilterShowTimesAsCustomerEnabled,
        'meetingTypeLabels': ((value.meetingTypeLabels as Array<any>).map(MeetingTypeBankOptionItemToJSON)),
        'advisorLabels': ((value.advisorLabels as Array<any>).map(AdvisorBankOptionsItemToJSON)),
        'closingDays': value.closingDays,
    };
}


