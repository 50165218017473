import { useState } from "react";
import { Taxonomy } from "../api/client";
import { useGetAllCustomerCategories } from "../api/useCustomerCategoriesApi";
import { useGetAllMeetingThemes } from "../api/useMeetingThemeApi"
import { ColumnLayout } from "../components/layout/ColumnLayout";
import { ContentCard } from "../components/ContentCard/ContentCard";
import { MeetingConfigurationInputs } from "../components/MeetingConfiguration/MeetingConfigurationInputs";
import { MeetingConfigurationThemeNavigation } from "../components/MeetingConfiguration/MeetingConfigurationThemeNavigation";
import { MeetingConfigurationPane } from "../components/MeetingConfiguration/MeetingConfigurationPane";
import { useGetActualMeetingConfiguration, useGetMeetingConfigurationDifference, useGetMeetingConfigurations } from "../api/useMeetingConfigurationApi";
import React from "react";
import { MeetingConfigurationProvider } from "../components/MeetingConfiguration/MeetingConfigurationContext";

export const MeetingConfigurationPage = () => {
    const { data: meetingThemes } = useGetAllMeetingThemes();
    const { data: customerCategories } = useGetAllCustomerCategories();
    const [chosenMeetingTheme, setChosenMeetingTheme] = useState<Taxonomy | null>(null);
    const { data: meetingConfigurations } = useGetMeetingConfigurations(chosenMeetingTheme?.id || undefined);
    const { data: meetingConfigurationDifference } = useGetMeetingConfigurationDifference(chosenMeetingTheme?.id || undefined);
    const { data: actual } = useGetActualMeetingConfiguration(chosenMeetingTheme?.id || undefined);

    const actualMeetingConfiguration = React.useMemo(() => {
        if (meetingConfigurations === undefined) return undefined;
        return chosenMeetingTheme ? actual : meetingConfigurations.find(x => x.themeId === null || x.themeId === undefined);
    }, [meetingConfigurations, chosenMeetingTheme, actual]);


    const themeSpecificMeetingConfiguration = React.useMemo(() => {
        if (meetingConfigurations === undefined) return undefined;
        return chosenMeetingTheme ? meetingConfigurations.find(x => x.themeId === chosenMeetingTheme.id) : undefined;
    }, [meetingConfigurations, chosenMeetingTheme]);

    if (!meetingThemes || !customerCategories) {
        return null;
    }

    return (
        <ColumnLayout>
            <ContentCard w="30%">
                <MeetingConfigurationThemeNavigation meetingThemes={meetingThemes} chosenMeetingTheme={chosenMeetingTheme} onThemeChosen={theme => setChosenMeetingTheme(theme)} />
            </ContentCard>
            <ContentCard w="70%">
                <MeetingConfigurationProvider
                    customerCategories={customerCategories}
                    actualMeetingConfiguration={actualMeetingConfiguration}
                    themeSpecificMeetingConfiguration={themeSpecificMeetingConfiguration}
                    meetingConfigurationDifference={meetingConfigurationDifference}>

                    {(customerCategories && actualMeetingConfiguration) && (chosenMeetingTheme === null ? <MeetingConfigurationInputs />
                        : <MeetingConfigurationPane />)}
                        
                </MeetingConfigurationProvider>
            </ContentCard>
        </ColumnLayout>
    );
};