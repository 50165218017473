/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvisorDay,
    AdvisorDayFromJSON,
    AdvisorDayFromJSONTyped,
    AdvisorDayToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingDay
 */
export interface MeetingDay {
    /**
     * 
     * @type {Date}
     * @memberof MeetingDay
     */
    date?: Date;
    /**
     * 
     * @type {Array<AdvisorDay>}
     * @memberof MeetingDay
     */
    advisorDays?: Array<AdvisorDay> | null;
}

export function MeetingDayFromJSON(json: any): MeetingDay {
    return MeetingDayFromJSONTyped(json, false);
}

export function MeetingDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': !exists(json, 'date') ? undefined : (new Date(json['date'])),
        'advisorDays': !exists(json, 'advisorDays') ? undefined : (json['advisorDays'] === null ? null : (json['advisorDays'] as Array<any>).map(AdvisorDayFromJSON)),
    };
}

export function MeetingDayToJSON(value?: MeetingDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date === undefined ? undefined : (value.date.toISOString()),
        'advisorDays': value.advisorDays === undefined ? undefined : (value.advisorDays === null ? null : (value.advisorDays as Array<any>).map(AdvisorDayToJSON)),
    };
}


