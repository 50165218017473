import { useEffect } from 'react';
import { useGetEmbedUrl } from "../api/useEmbedApi";

export const PortalConfigurationsPage = () => {
  const { data: embedUrl } = useGetEmbedUrl();

  useEffect(() => {
    if (embedUrl) {
      window.location.href = `${embedUrl}/portals/configurations`;
    }
  }, [embedUrl]);

  return null;
}
