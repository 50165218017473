import { Box, HStack, IconButton, ListItem, UnorderedList, Text } from "@chakra-ui/react"
import { use } from "i18next";
import { useEffect, useState } from "react";
import { DeleteIcon } from "../icons/DeleteIcon";


interface DateListProps {
    selectedDates: Date[];
    onDateRemoved: (date: Date) => void;
}


export const DateList = ({ selectedDates, onDateRemoved }: DateListProps) => {
    const [selectedDatesState, setSelectedDatesState] = useState<Date[]>(selectedDates);

    useEffect(() => {
        setSelectedDatesState(selectedDates);
    }, [selectedDates]);

    return (<Box alignItems="start" maxH="200px" overflow="scroll">
        <UnorderedList alignItems="start">
            {selectedDatesState.map((date) => (
                <ListItem justifyContent="stretch" key={date.toString()}>
                    <DateRow date={date} onDateRemoved={onDateRemoved} />
                </ListItem>
            ))}
        </UnorderedList>
    </Box>
    );
}


const DateRow = ({ date, onDateRemoved }: { date: Date, onDateRemoved: (date: Date) => void }) => {
    const [buttonIsVisible, setButtonIsVisible] = useState(false);

    return (
        <HStack padding={2} justifyContent="space-between" height="10" onMouseEnter={() => setButtonIsVisible(true)}
            onMouseLeave={() => setButtonIsVisible(false)} _hover={{ bgColor: "bookMeLightBlue" }}>
            <Text>{date.toLocaleDateString()}</Text>

            {buttonIsVisible && <IconButton _focus={{ boxShadow: "0" }} bgColor="unset" icon={<DeleteIcon height="24px" width="24px" />}
                aria-label="delete-button" onClick={(e) => onDateRemoved(date)} />}

        </HStack>
    );
}
