import { extendTheme, ThemeOverride } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { ContentCardConfig, ContentCardConfigKey } from "../components/ContentCard/ContentCardConfig";
import { ColumnLayoutConfig, ColumnLayoutConfigKey } from "../components/layout/ColumnLayoutConfig";
import { TimeRangeInputConfig, TimeRangeInputConfigKey } from "../components/TimeRangeInput/TimeRangeInputConfig";
import { TabsConfig } from "./components/TabsConfig";
import overrideColors from "./colors";
import { ButtonConfig } from "./components/ButtonConfig";
import { CheckboxConfig } from "./components/CheckboxConfig";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1921px",
  "3xl": "2500px"
});

const textStyles = {
  h1: {
    fontWeight: 'bold'
  }
};

const overrides: ThemeOverride = {
  ...overrideColors,
  breakpoints,
  textStyles,
  components: {
    [ColumnLayoutConfigKey]: ColumnLayoutConfig,
    [ContentCardConfigKey]: ContentCardConfig,
    [TimeRangeInputConfigKey]: TimeRangeInputConfig,
    Tabs: TabsConfig,
    Button: ButtonConfig,
    Checkbox: CheckboxConfig
  },
  fonts: {
    body: 'POIOrbiter, sans-serif',
    heading: 'POIOrbiter, sans-serif',
    label: 'POIOrbiter, sans-serif',
  },
};

const theme = extendTheme(overrides);

export default theme;