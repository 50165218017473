/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Taxonomy
 */
export interface Taxonomy {
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Taxonomy
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Taxonomy
     */
    order?: number;
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof Taxonomy
     */
    children?: Array<Taxonomy> | null;
    /**
     * 
     * @type {Taxonomy}
     * @memberof Taxonomy
     */
    parent?: Taxonomy;
}

export function TaxonomyFromJSON(json: any): Taxonomy {
    return TaxonomyFromJSONTyped(json, false);
}

export function TaxonomyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Taxonomy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(TaxonomyFromJSON)),
        'parent': !exists(json, 'parent') ? undefined : TaxonomyFromJSON(json['parent']),
    };
}

export function TaxonomyToJSON(value?: Taxonomy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'order': value.order,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(TaxonomyToJSON)),
        'parent': TaxonomyToJSON(value.parent),
    };
}


