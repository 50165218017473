import { VStack, Heading, Text, HStack, BoxProps, Box } from "@chakra-ui/react"
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompetenceGroup, CompetenceGroupInput } from "../../api/client";
import { useGetAllCompetenceGroups, useGetAllowedChildrenIds, useUpdateCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard"
import { DisplayList } from "../ServiceGroup/DisplayList";
import { ListView } from "./ListView";

interface Props extends BoxProps {
    selectedCompetenceGroupInput: CompetenceGroup;
    handleParentClicked?: (id: string) => void;
}

export const UpdateCompetenceChildren = ({ selectedCompetenceGroupInput, handleParentClicked, ...props }: Props) => {
    const updateCompetenceGroupMutation = useUpdateCompetenceGroup();
    const { data: competenceGroups } = useGetAllCompetenceGroups();
    const { data: allowedCompetenceGroupChildrenIds } = useGetAllowedChildrenIds(selectedCompetenceGroupInput.id!);
    const { t } = useTranslation();

    const allowedChildOptions = useMemo(() => {
        if (!competenceGroups || !allowedCompetenceGroupChildrenIds) return [];

        return competenceGroups?.filter(x => allowedCompetenceGroupChildrenIds.includes(x.id!)).map(x => { return { value: x.id!, label: x.name! } }) || [];
    }, [competenceGroups, allowedCompetenceGroupChildrenIds]);

    const selectedCompetenceGroupView = useMemo(() => {
        return selectedCompetenceGroupInput;
    }, [selectedCompetenceGroupInput]);

    const directParentsItems = useMemo(() => {

        if (!competenceGroups) return [];
        return competenceGroups?.filter(x =>
            x.childGroupIds?.includes(selectedCompetenceGroupInput.id!)
        )?.map(x => {
            return { value: x.id!, label: x.name! }
        }) || [];
    }, [competenceGroups, selectedCompetenceGroupInput]);

    const selectedCompetenceGroupUpdateDTO: CompetenceGroupInput = useMemo(() => {
        return {
            name: selectedCompetenceGroupInput.name,
            advisorIds: selectedCompetenceGroupInput.advisorIds,
            childGroupIds: selectedCompetenceGroupInput.childGroupIds,
            customerCategoryIds: selectedCompetenceGroupInput.customerCategories?.map(x => x.id!) || [],
            themeIds: selectedCompetenceGroupInput.themes?.map(x => x.id!) || []
        }
    }, [selectedCompetenceGroupInput]);

    if (!selectedCompetenceGroupView) {
        return (<></>);
    }

    function handleChildrenChanged(ids: string[]): void {
        if (!selectedCompetenceGroupView) {
            return;
        }

        updateCompetenceGroupMutation.mutate({
            id: selectedCompetenceGroupView.id!,
            competenceGroup: {
                ...selectedCompetenceGroupUpdateDTO,
                childGroupIds: ids
            }
        });
    }


    return (
        <ContentCard {...props}>
            <VStack align="stretch" spacing="8">
                <VStack align="start">
                    <Heading as="h2" size="lg" fontWeight="semibold">{t('competencegrouppage.update-child-groups.heading')}</Heading>
                    <Text w="50%" color="gray.300" fontSize="xs">{t('competencegrouppage.update-child-groups.description', { groupName: selectedCompetenceGroupInput?.name }) }</Text>
                </VStack>
                <HStack spacing="8" align="stretch" justify="stretch">
                    <Box w="50%">
                        <DisplayList heading={t('competencegrouppage.update-child-groups.child-group-list.heading')} selectedItems={selectedCompetenceGroupView.childGroupIds || []} options={allowedChildOptions} placeholder={t('competencegrouppage.update-child-groups.child-group-list.placeholder')} handleOnChange={ids => handleChildrenChanged(ids)} />
                    </Box>
                    <Box w="50%">
                        <ListView isClickable={true} handleOnClick={id => handleParentClicked && handleParentClicked(id)} items={directParentsItems} heading={t('competencegrouppage.update-child-groups.parent-group-list.heading')} />
                    </Box>
                </HStack>
            </VStack>
        </ContentCard>
    )
}