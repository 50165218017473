import React from "react";
import {
    HStack,
    StackProps,
    useStyleConfig,
    CSSObject
} from "@chakra-ui/react";
import { ColumnLayoutConfigKey } from "./ColumnLayoutConfig";

interface Props extends StackProps {
    autoWidth?: boolean;
}

export const ColumnLayout = ({ children, autoWidth, ...props }: React.PropsWithChildren<Props>) => {
    const styles = useStyleConfig(ColumnLayoutConfigKey, {});
    const extraStyles: CSSObject | {} = React.useMemo(() => {
        if (autoWidth) {
            return {
                "& > *": {
                    flex: "1 1"
                }
            };
        }
        return {};
    }, [autoWidth]);

    return (
        <HStack sx={{ ...styles, ...extraStyles }} spacing="2em" {...props}>
            {children}
        </HStack>
    );
};