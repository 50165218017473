import {
    ComponentMultiStyleConfig,
} from "@chakra-ui/react";

export const CheckboxConfig: ComponentMultiStyleConfig = {
    parts: ["container", "control"],
    baseStyle: {
        control: {
            _indeterminate: {
                bg: "white",
                color: "blue.500",
                borderColor: "gray.200"
            }
        }
    }
};