/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSlot,
    TimeSlotFromJSON,
    TimeSlotFromJSONTyped,
    TimeSlotToJSON,
    Workday,
    WorkdayFromJSON,
    WorkdayFromJSONTyped,
    WorkdayToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdvisorDay
 */
export interface AdvisorDay {
    /**
     * 
     * @type {string}
     * @memberof AdvisorDay
     */
    advisorId: string;
    /**
     * 
     * @type {string}
     * @memberof AdvisorDay
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof AdvisorDay
     */
    date: Date;
    /**
     * 
     * @type {Array<TimeSlot>}
     * @memberof AdvisorDay
     */
    timeSlots: Array<TimeSlot>;
    /**
     * 
     * @type {Workday}
     * @memberof AdvisorDay
     */
    workday?: Workday;
}

export function AdvisorDayFromJSON(json: any): AdvisorDay {
    return AdvisorDayFromJSONTyped(json, false);
}

export function AdvisorDayFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvisorDay {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'advisorId': json['advisorId'],
        'title': json['title'],
        'date': (new Date(json['date'])),
        'timeSlots': ((json['timeSlots'] as Array<any>).map(TimeSlotFromJSON)),
        'workday': !exists(json, 'workday') ? undefined : WorkdayFromJSON(json['workday']),
    };
}

export function AdvisorDayToJSON(value?: AdvisorDay | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'advisorId': value.advisorId,
        'title': value.title,
        'date': (value.date.toISOString()),
        'timeSlots': ((value.timeSlots as Array<any>).map(TimeSlotToJSON)),
        'workday': WorkdayToJSON(value.workday),
    };
}


