import { 
    useDisclosure, 
    Box,
    VStack, 
    HStack, 
    Accordion,
    Text
} from "@chakra-ui/react";
import { useState } from "react";
import { UseMutationResult } from "react-query";
import { Taxonomy } from "../../api/client";
import { DeleteModal } from "./DeleteModal";
import { EditableRow } from "./EditableRow";

interface Props {
    currentTaxonomy?: Taxonomy;
    taxonomies?: Taxonomy[];
    useUpdateTaxonomyMutation: UseMutationResult<Taxonomy, unknown, Taxonomy, unknown>;
    useDeleteTaxonomyMutation: UseMutationResult<unknown, unknown, string, unknown>;
    disallowReordering?: boolean;
}

export const EditableItemListView = ({currentTaxonomy, taxonomies, useDeleteTaxonomyMutation, useUpdateTaxonomyMutation, disallowReordering}: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dataPointToDelete, setDataPointToDelete] = useState<Taxonomy>();

    function handleDeleteButtonClicked(taxonomy: Taxonomy) {
        onOpen();
        setDataPointToDelete(taxonomy);
    }

    function onDeleteConfirm() {
        if(dataPointToDelete && dataPointToDelete.id) {
            useDeleteTaxonomyMutation.mutate(dataPointToDelete.id);
            onClose();
        }
    }

    return (
        <VStack spacing={5} alignItems="stretch">
            {taxonomies?.map((taxonomy) => {
                const bgColor = currentTaxonomy === taxonomy ? "gray.100" : "unset";
                return ( 
                    <Box key={taxonomy.id} flex="1" textAlign="left" fontSize="sm"
                    _hover={{bgColor: "bookMeLightBlue"}} bgColor={bgColor} tabIndex={0}>
                        <EditableRow useUpdateTaxonomyMutation={useUpdateTaxonomyMutation} taxonomyFontSize="18px"
                            handleDeleteButtonClicked={handleDeleteButtonClicked} taxonomy={taxonomy}
                            disallowReordering={disallowReordering} />
                    </Box>
                );
            })}
            <DeleteModal isOpen={isOpen} itemName={dataPointToDelete?.name || ""} onClose={onClose} onConfirm={onDeleteConfirm}>
                Er du sikker på at du vil slette "{dataPointToDelete?.name}"?
            </DeleteModal>
        </VStack>
    );
};