/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeatureFlagsDto
 */
export interface FeatureFlagsDto {
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagsDto
     */
    enablePortalConfiguration: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FeatureFlagsDto
     */
    enableInsights: boolean;
}

export function FeatureFlagsDtoFromJSON(json: any): FeatureFlagsDto {
    return FeatureFlagsDtoFromJSONTyped(json, false);
}

export function FeatureFlagsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeatureFlagsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enablePortalConfiguration': json['enablePortalConfiguration'],
        'enableInsights': json['enableInsights'],
    };
}

export function FeatureFlagsDtoToJSON(value?: FeatureFlagsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enablePortalConfiguration': value.enablePortalConfiguration,
        'enableInsights': value.enableInsights,
    };
}


