import { Input } from "@chakra-ui/input"
import { Box, Text, VStack } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/react"
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/tabs"
import React, { useEffect, useState } from "react"
import { Taxonomy } from "../api/client/models"
import { useCreateMeetingTheme, useDeleteMeetingTheme, useGetAllMeetingThemes, useUpdateMeetingTheme, useUpdateMeetingThemeOrdering } from "../api/useMeetingThemeApi"
import { AccordionView } from "../components/ItemListView"
import { CreateButton } from "../components/Buttons/CreateButton"
import { ColumnLayout } from "../components/layout/ColumnLayout"
import { ContentCard } from "../components/ContentCard/ContentCard"
import { CreateTaxonomyForm } from "../components/CreateTaxonomyForm"
import { useDebouncedMutation } from "../helpers/debounce"
import { useTranslation } from "react-i18next"

interface CreateSubThemeInputProps {
    currentTheme: Taxonomy;
    onCreateSubTheme: (name: string) => void;
}

const CreateSubThemeInput = ({ currentTheme, onCreateSubTheme }: CreateSubThemeInputProps) => {
    const { t } = useTranslation();
    const [meetingSubThemeName, setMeetingSubThemeName] = useState("");
    React.useEffect(() => {
        setMeetingSubThemeName("");
    }, [currentTheme]);

    return (
        <Box>
            <Text color="gray.300" fontSize="xs">{t('meetingthemepage.create-subtheme-input.label')}</Text>
            <Input placeholder={t('meetingthemepage.create-subtheme-input.placeholder', { parentThemeName: currentTheme.name })} shadow="md" value={meetingSubThemeName} onChange={e => setMeetingSubThemeName(e.target.value)}
                size="lg" borderRadius="0" />
            <CreateButton w="100%" mt="2" onClick={() => onCreateSubTheme(meetingSubThemeName)} text={t('meetingthemepage.create-taxonomy-form.create-button')} />
        </Box>
    );
};

export const MeetingTaxonomyPage = () => {
    const { data: meetingThemes } = useGetAllMeetingThemes();
    const [currentTheme, setCurrentTheme] = useState<Taxonomy>();
    const useCreateMeetingThemeMutation = useCreateMeetingTheme();
    const useUpdateMeetingThemeMutation = useUpdateMeetingTheme();
    const useDeleteMeetingThemeMutation = useDeleteMeetingTheme();
    const useUpdateMeetingThemeOrderMutation = useUpdateMeetingThemeOrdering();
    const useUpdateMeetingThemeOrderMutationDebounced = useDebouncedMutation(useUpdateMeetingThemeOrderMutation, 5000);
    const [orderedMeetingThemes, setOrderedMeetingThemes] = useState<Taxonomy[]>();
    const { t } = useTranslation();


    useEffect(() => {
        if (!meetingThemes) {
            return;
        }
        let themeIsUnordered = false;
        meetingThemes.sort((a, b) => a.order! - b.order!).forEach((theme, index) => {
            if (theme.order === 0 || (theme.order !== index + 1)) {
                themeIsUnordered = true;
                theme.order = index + 1;
            }

            let subThemeIsUnordered = false;
            theme.children?.sort((subA, subB) => subA.order! - subB.order!).forEach((subTheme, subIndex) => {
                if (subTheme.order === 0 || (subTheme.order !== subIndex + 1)) {
                    subThemeIsUnordered = true;
                    subTheme.order = subIndex + 1
                }
            });
            if (subThemeIsUnordered) {
                useUpdateMeetingThemeOrderMutation.mutate(theme.children!);
            }
        });
        if (themeIsUnordered) {
            useUpdateMeetingThemeOrderMutation.mutate(meetingThemes);
        }
        setOrderedMeetingThemes(meetingThemes);
    }, [meetingThemes]);

    if (!orderedMeetingThemes) {
        return <></>;
    }

    function handleMeetingThemeChoice(e: React.ChangeEvent<HTMLSelectElement>) {
        if (orderedMeetingThemes) {
            setCurrentTheme(orderedMeetingThemes[parseInt(e.target.value)]);
        }
    }

    function handleCreateMeetingThemeSubmit(theme: Taxonomy) {
        if (orderedMeetingThemes) {
            theme.order = orderedMeetingThemes?.length + 1
            useCreateMeetingThemeMutation.mutate(theme);
        }
    }

    function handleCreateMeetingSubThemeSubmit(name: string) {
        if (currentTheme && currentTheme.children) {
            const meetingSubThemeToCreate: Taxonomy = { name: name, parent: currentTheme, order: currentTheme.children.length + 1 };
            useCreateMeetingThemeMutation.mutate(meetingSubThemeToCreate);
        }
    }

    function handleMoveUpClicked(taxonomy: Taxonomy) {
        if (!orderedMeetingThemes) {
            return;
        }
        const parent = orderedMeetingThemes.find(x => x.children?.some(child => child.id === taxonomy.id));
        if (parent && parent.children) {
            // Reordering of SubThemes

            // find index of element which has 1 less in order
            const childIndex = parent.children?.findIndex(x => x.order === taxonomy.order!);
            const targetChildIndex = parent.children?.findIndex(x => x.order === taxonomy.order! - 1);
            if (targetChildIndex !== undefined && targetChildIndex > -1 &&
                childIndex !== undefined && childIndex > -1) {
                const parentIndex = orderedMeetingThemes.findIndex(x => x.children?.some(child => child.id === taxonomy.id));
                if (parentIndex !== undefined && parentIndex > -1) {
                    const currentChild: Taxonomy = { ...parent.children[childIndex], order: parent.children[targetChildIndex].order };
                    const targetChild: Taxonomy = { ...parent.children[targetChildIndex], order: parent.children[childIndex].order };
                    const elementToUpdate: Taxonomy = { ...parent, children: [...parent.children.slice(0, targetChildIndex), currentChild, targetChild, ...parent.children.slice(childIndex + 1)] };
                    const elementsToUpdate: Taxonomy[] = [...orderedMeetingThemes.slice(0, parentIndex), elementToUpdate, ...orderedMeetingThemes.slice(parentIndex + 1)]
                    setOrderedMeetingThemes(elementsToUpdate);
                    useUpdateMeetingThemeOrderMutationDebounced.mutate(elementToUpdate.children!);
                }
            }
        } else {
            // Reordering of Themes

            // find index of element which has 1 less in order
            const index = orderedMeetingThemes.findIndex(x => x.order === taxonomy.order!);
            const targetIndex = orderedMeetingThemes.findIndex(x => x.order === taxonomy.order! - 1);
            if (targetIndex !== undefined && targetIndex > -1 &&
                index !== undefined && index > -1) {
                const current: Taxonomy = { ...orderedMeetingThemes[index], order: orderedMeetingThemes[targetIndex].order }
                const target: Taxonomy = { ...orderedMeetingThemes[targetIndex], order: orderedMeetingThemes[index].order }
                const elementsToUpdate: Taxonomy[] = [...orderedMeetingThemes.slice(0, targetIndex), current, target, ...orderedMeetingThemes.slice(index + 1)];
                setOrderedMeetingThemes(elementsToUpdate);
                useUpdateMeetingThemeOrderMutationDebounced.mutate(elementsToUpdate);
            }
        }
    }

    function handleMoveDownClicked(taxonomy: Taxonomy) {
        if (!orderedMeetingThemes) {
            return;
        }
        const parent = orderedMeetingThemes.find(x => x.children?.some(child => child.id === taxonomy.id));
        if (parent && parent.children) {
            // Reordering of SubThemes

            // find index of element which has 1 less in order
            const childIndex = parent.children?.findIndex(x => x.order === taxonomy.order!);
            const targetChildIndex = parent.children?.findIndex(x => x.order === taxonomy.order! + 1);
            if (targetChildIndex !== undefined && targetChildIndex > -1 &&
                childIndex !== undefined && childIndex > -1) {
                const parentIndex = orderedMeetingThemes.findIndex(x => x.children?.some(child => child.id === taxonomy.id));
                if (parentIndex !== undefined && parentIndex > -1) {
                    const currentChild: Taxonomy = { ...parent.children[childIndex], order: parent.children[targetChildIndex].order };
                    const targetChild: Taxonomy = { ...parent.children[targetChildIndex], order: parent.children[childIndex].order };
                    const elementToUpdate: Taxonomy = { ...parent, children: [...parent.children.slice(0, childIndex), targetChild, currentChild, ...parent.children.slice(targetChildIndex + 1)] };
                    const elementsToUpdate: Taxonomy[] = [...orderedMeetingThemes.slice(0, parentIndex), elementToUpdate, ...orderedMeetingThemes.slice(parentIndex + 1)];
                    setOrderedMeetingThemes(elementsToUpdate);
                    useUpdateMeetingThemeOrderMutationDebounced.mutate(elementToUpdate.children!);
                }
            }
        } else {
            // Reordering of Themes

            // find index of element which has 1 less in order
            const index = orderedMeetingThemes.findIndex(x => x.order === taxonomy.order!);
            const targetIndex = orderedMeetingThemes.findIndex(x => x.order === taxonomy.order! + 1);
            if (targetIndex !== undefined && targetIndex > -1 &&
                index !== undefined && index > -1) {
                const current: Taxonomy = { ...orderedMeetingThemes[index], order: orderedMeetingThemes[targetIndex].order }
                const target: Taxonomy = { ...orderedMeetingThemes[targetIndex], order: orderedMeetingThemes[index].order }
                const elementsToUpdate: Taxonomy[] = [...orderedMeetingThemes.slice(0, index), target, current, ...orderedMeetingThemes.slice(targetIndex + 1)]
                setOrderedMeetingThemes(elementsToUpdate);
                useUpdateMeetingThemeOrderMutationDebounced.mutate(elementsToUpdate);
            }
        }
    }

    return (
        <ColumnLayout autoWidth>
            <ContentCard minH="540px" heading={t('meetingthemepage.theme-overview.header')}>
                <AccordionView useUpdateTaxonomyMutation={useUpdateMeetingThemeMutation} useDeleteTaxonomyMutation={useDeleteMeetingThemeMutation}
                    currentTaxonomy={currentTheme} subText={t('meetingthemepage.theme-overview.subtheme-header')} taxonomies={orderedMeetingThemes} disallowDeletionOfLastChild
                    disallowReordering={false}
                    handleMoveUpClicked={handleMoveUpClicked}
                    handleMoveDownClicked={handleMoveDownClicked} />
            </ContentCard>
            <ContentCard minH="540px" heading={t('meetingthemepage.create-theme.header')} subHeading={t('meetingthemepage.create-theme.description')}>
                <Tabs size="lg">
                    <TabList width={["100%", null, null, null, "50%"]}>
                        <Tab width="50%">{t('meetingthemepage.tablist.theme-tab')}</Tab>
                        <Tab width="50%">{t('meetingthemepage.tablist.subheme-tab')}</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel w="100%">
                            <CreateTaxonomyForm onSave={handleCreateMeetingThemeSubmit} mainAreaLabel={t('meetingthemepage.theme-name-input.label')} childInputLabel={t('meetingthemepage.subtheme-name-input.placeholder')} createSubTaxonomyLabel={t('meetingthemepage.add-subtheme-button')} />
                        </TabPanel>
                        <TabPanel w={["100%", null, null, null, "50%"]}>
                            <VStack alignItems="stretch" spacing="1em">
                                {meetingThemes ?
                                    <Box>
                                        <Text color="gray.300" fontSize="xs">{t('meetingthemepage.create-subtheme.theme-select.label')}</Text>
                                        <Select size="lg" fontSize="18px" placeholder={t('meetingthemepage.create-subtheme.theme-select.placeholder')} bg="white" boxShadow="md" rounded="unset" onChange={e => handleMeetingThemeChoice(e)}>
                                            {meetingThemes.map((mt, index) => {
                                                if (mt.name) {
                                                    return <option key={mt.name} value={index}>{mt.name}</option>;
                                                } else return null;
                                            })}
                                        </Select>
                                    </Box> : null}
                                {currentTheme ?
                                    <CreateSubThemeInput onCreateSubTheme={handleCreateMeetingSubThemeSubmit} currentTheme={currentTheme} />
                                    : null}
                            </VStack>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </ContentCard>
        </ColumnLayout>
    );
}
