/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeetingProcessing
 */
export interface MeetingProcessing {
    /**
     * 
     * @type {boolean}
     * @memberof MeetingProcessing
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingProcessing
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingProcessing
     */
    customerCategoryId?: string | null;
}

export function MeetingProcessingFromJSON(json: any): MeetingProcessing {
    return MeetingProcessingFromJSONTyped(json, false);
}

export function MeetingProcessingFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingProcessing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'customerCategoryId': !exists(json, 'customerCategoryId') ? undefined : json['customerCategoryId'],
    };
}

export function MeetingProcessingToJSON(value?: MeetingProcessing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'duration': value.duration,
        'customerCategoryId': value.customerCategoryId,
    };
}


