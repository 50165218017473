import { HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateWorkingTimeFromBookingToMeetings } from "../../../api/useMeetingConfigurationApi";
import { useDebounce } from "../../../helpers/debounce";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";
import { durationHoursToTimeSpan } from "../../../helpers/durationHelper";

interface CustomerCategoryDurationValues {
    customerCategory: string | undefined;
    durationValue: number | undefined;
}

export const WorkingTimeFromBookingToMeetingInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updateTimeFromBookingToMeetingMutation = useUpdateWorkingTimeFromBookingToMeetings();
    const debouncedUpdateTimeFromBookingToMeetingMutation = useDebounce(updateTimeFromBookingToMeetingMutation.mutate, 500);
    const [customerCategoryDurationValues, setCustomerCategoryDurationValues] = useState<CustomerCategoryDurationValues[]>();
    const { t } = useTranslation();

    useEffect(() => {
        if (customerCategories && actualMeetingConfiguration) {
            const workingTimeFromBookingToMeetings = actualMeetingConfiguration!.workingTimeFromBookingToMeetings!;
            // Make array of category ids and undefined (the "id" for the general configuration all customer categories)
            const categoryIds = Array.from(customerCategories.map(x => x.id)).concat([undefined]);
            const value: CustomerCategoryDurationValues[] = categoryIds.map(customerCategoryId => {
                const customerCategoryDuration = workingTimeFromBookingToMeetings.find(x => x.customerCategoryId === customerCategoryId);
                // format of duration is either 'd.hh:mm:ss', 'hh:mm:ss'
                const daysAndHours = customerCategoryDuration?.duration.split(':')[0];
                let hours: number | undefined = undefined;
                if (daysAndHours) {
                    if (daysAndHours.includes('.')) {
                        hours = (+daysAndHours.split('.')[0])*24;
                        hours = hours + +daysAndHours.split('.')[1];
                    } else {
                        hours = +daysAndHours;
                    }
                }
                return { customerCategory: customerCategoryId ? customerCategoryId : undefined, durationValue: hours}
            });
            setCustomerCategoryDurationValues(value);
        }
    },[customerCategories, actualMeetingConfiguration])

    function handleTimeFromBookingToMeetingChanged(newValue: number, customerCategoryId?: string) {
        if (Number.isNaN(newValue)) {
            // should reset the customercategory duration
            newValue = 0;
        }
        const index = customerCategoryDurationValues?.findIndex(x => x.customerCategory === customerCategoryId);
        if (customerCategoryDurationValues && (index !== undefined && index !== -1)) {
            setCustomerCategoryDurationValues([...customerCategoryDurationValues.slice(0, index), { customerCategory: customerCategoryId, durationValue: newValue}, ...customerCategoryDurationValues.slice(index + 1)]);
            const durationAsTimeSpan = durationHoursToTimeSpan(newValue);
            debouncedUpdateTimeFromBookingToMeetingMutation({ id: actualMeetingConfiguration!.id || undefined, duration: { customerCategoryId, duration: durationAsTimeSpan } });
        }
    }

    if (!customerCategoryDurationValues) {
        return <></>;
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.working-time-from-booking-to-meeting.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const hours = customerCategoryDurationValues?.find(x => x.customerCategory === customerCategoryId)?.durationValue;
                const workingTimeFromBookingToMeetings = actualMeetingConfiguration!.workingTimeFromBookingToMeetings!;
                let isDisabled = false
                if (customerCategoryId === undefined && workingTimeFromBookingToMeetings.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }
                return (
                    <HStack>
                        <NumberInput size="md" maxWidth="10em" value={hours === undefined ? "" : hours} min={0} max={999}
                        onChange={(_,newValue) => handleTimeFromBookingToMeetingChanged(newValue, customerCategoryId)}
                        isDisabled={isDisabled}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        {hours === undefined ? 
                        <Text color="gray.200">
                            {t('meetingconfigurationpage.time-from-booking-to-meeting.hours')}
                        </Text> :
                        <Text>
                            {t('meetingconfigurationpage.time-from-booking-to-meeting.hours')}
                        </Text>}
                    </HStack>
                );
            }}
        </ConfigurationOptionItem>
    );
};