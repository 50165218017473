import { ListProps, VStack, Heading, UnorderedList, ListItem, HStack, Text } from "@chakra-ui/react";

interface Props extends ListProps {
    heading: string;
    items: { label: string, value: string }[];
    isClickable?: boolean;
    handleOnClick?: (id: string) => void;
}

export const ListView = ({ items, heading, isClickable = false, handleOnClick, ...props }: Props) => {


    return (
        <VStack align="stretch" w="100%">
            <Heading as="h3" size="md" fontWeight="semibold">{heading}</Heading>
            <UnorderedList  {...props} align="end" justifyContent="flex-end" h="200px" overflowY="auto" overflowX="visible">
                {items.map((item) => {
                    return (
                        <ListItem key={item.value} flex="1" textAlign="left" fontSize="18px">
                            {isClickable ? 
                            
                            <HStack spacing="4" paddingY="2" onClick={() => handleOnClick && handleOnClick(item.value)} _hover={{ bgColor: "bookMeLightBlue", cursor: "pointer" }}>
                                <Text fontSize="16px">{item.label}</Text>
                                </HStack>
                                :
                            <Text fontSize="16px">{item.label}</Text>
                            }
                        </ListItem>
                    );
                })}
            </UnorderedList>
        </VStack>
    );
}