import React from "react";
import {
    Button,
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
    itemName: string;
    onClose: () => void;
    isOpen?: boolean;
    onConfirm?: () => void;
}

export const DeleteModal = ({ itemName, isOpen, onClose, onConfirm, children }: React.PropsWithChildren<Props>) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen || false} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t('delete-modal.header', { name: itemName})}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button textColor="white" bgColor="gray.100" mr={3} onClick={onClose}>
                        {t('delete-modal.cancel-button')}
                    </Button>
                    <Button aria-label="delete-button" colorScheme="red" onClick={onConfirm}>{t('delete-modal.confirm-button')}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};