import React from "react";
import {
    Button,
    Modal, 
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

interface Props {
    headerText: string;
    onClose: () => void;
    isOpen?: boolean;
    onConfirm?: () => void;
    isLoading?: boolean;
}

export const ConfirmationModal = ({ headerText, isOpen, onClose, onConfirm, isLoading, children }: React.PropsWithChildren<Props>) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen || false} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{headerText}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {children}
                </ModalBody>
                <ModalFooter>
                    <Button textColor="white" bgColor="gray.100" mr={3} onClick={onClose}>
                        {t('confirmationModal.cancel')}
                    </Button>
                    <Button variant="bookMe" textColor="white" onClick={onConfirm} isLoading={isLoading}>
                        {t('confirmationModal.confirm')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};