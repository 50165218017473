/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdvisorsOfferedUpdateDTO,
    AdvisorsOfferedUpdateDTOFromJSON,
    AdvisorsOfferedUpdateDTOToJSON,
    BookingBy,
    BookingByFromJSON,
    BookingByToJSON,
    CalendarTimeFromBookingToMeetings,
    CalendarTimeFromBookingToMeetingsFromJSON,
    CalendarTimeFromBookingToMeetingsToJSON,
    ConfigurationTypes,
    ConfigurationTypesFromJSON,
    ConfigurationTypesToJSON,
    EnabledMeetingTypes,
    EnabledMeetingTypesFromJSON,
    EnabledMeetingTypesToJSON,
    MeetingConfiguration,
    MeetingConfigurationFromJSON,
    MeetingConfigurationToJSON,
    MeetingConfigurationDifference,
    MeetingConfigurationDifferenceFromJSON,
    MeetingConfigurationDifferenceToJSON,
    MeetingDuration,
    MeetingDurationFromJSON,
    MeetingDurationToJSON,
    MeetingProcessing,
    MeetingProcessingFromJSON,
    MeetingProcessingToJSON,
} from '../models';

export interface CreateMeetingConfigurationRequest {
    meetingConfiguration?: MeetingConfiguration;
}

export interface DeleteMeetingConfigurationRequest {
    id?: string;
}

export interface GetActualMeetingConfigurationRequest {
    themeId: string;
}

export interface GetMeetingConfigurationRequest {
    id: string;
}

export interface GetMeetingConfigurationDifferenceRequest {
    themeId: string;
}

export interface GetMeetingConfigurationsRequest {
    themeId?: string;
}

export interface ResetConfigurationTypeRequest {
    id: string;
    configurationType?: ConfigurationTypes;
}

export interface UpdateAdvisorsOfferedRequest {
    id?: string;
    advisorsOfferedUpdateDTO?: AdvisorsOfferedUpdateDTO;
}

export interface UpdateBookingByRequest {
    id?: string;
    bookingBy?: BookingBy;
}

export interface UpdateCalendarTimeFromBookingToMeetingsRequest {
    id?: string;
    calendarTimeFromBookingToMeetings?: CalendarTimeFromBookingToMeetings;
}

export interface UpdateEnabledMeetingTypesRequest {
    id?: string;
    enabledMeetingTypes?: EnabledMeetingTypes;
}

export interface UpdateMeetingDurationRequest {
    id?: string;
    meetingDuration?: MeetingDuration;
}

export interface UpdatePostProcessingRequest {
    id?: string;
    meetingProcessing?: MeetingProcessing;
}

export interface UpdatePreProcessingRequest {
    id?: string;
    meetingProcessing?: MeetingProcessing;
}

export interface UpdateTimeBetweenMeetingsRequest {
    id?: string;
    meetingDuration?: MeetingDuration;
}

export interface UpdateTravelTimeBufferRequest {
    id?: string;
    meetingProcessing?: MeetingProcessing;
}

export interface UpdateWorkingTimeFromBookingToMeetingsRequest {
    id?: string;
    meetingDuration?: MeetingDuration;
}

/**
 * 
 */
export class MeetingConfigurationApi extends runtime.BaseAPI {

    /**
     */
    async createMeetingConfigurationRaw(requestParameters: CreateMeetingConfigurationRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingConfigurationToJSON(requestParameters.meetingConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async createMeetingConfiguration(requestParameters: CreateMeetingConfigurationRequest): Promise<MeetingConfiguration> {
        const response = await this.createMeetingConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteMeetingConfigurationRaw(requestParameters: DeleteMeetingConfigurationRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMeetingConfiguration(requestParameters: DeleteMeetingConfigurationRequest): Promise<void> {
        await this.deleteMeetingConfigurationRaw(requestParameters);
    }

    /**
     */
    async getActualMeetingConfigurationRaw(requestParameters: GetActualMeetingConfigurationRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        if (requestParameters.themeId === null || requestParameters.themeId === undefined) {
            throw new runtime.RequiredError('themeId','Required parameter requestParameters.themeId was null or undefined when calling getActualMeetingConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration/actual/{themeId}`.replace(`{${"themeId"}}`, encodeURIComponent(String(requestParameters.themeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async getActualMeetingConfiguration(requestParameters: GetActualMeetingConfigurationRequest): Promise<MeetingConfiguration> {
        const response = await this.getActualMeetingConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMeetingConfigurationRaw(requestParameters: GetMeetingConfigurationRequest): Promise<runtime.ApiResponse<Array<MeetingConfiguration>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMeetingConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingConfigurationFromJSON));
    }

    /**
     */
    async getMeetingConfiguration(requestParameters: GetMeetingConfigurationRequest): Promise<Array<MeetingConfiguration>> {
        const response = await this.getMeetingConfigurationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMeetingConfigurationDifferenceRaw(requestParameters: GetMeetingConfigurationDifferenceRequest): Promise<runtime.ApiResponse<MeetingConfigurationDifference>> {
        if (requestParameters.themeId === null || requestParameters.themeId === undefined) {
            throw new runtime.RequiredError('themeId','Required parameter requestParameters.themeId was null or undefined when calling getMeetingConfigurationDifference.');
        }

        const queryParameters: any = {};

        if (requestParameters.themeId !== undefined) {
            queryParameters['themeId'] = requestParameters.themeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration/difference`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationDifferenceFromJSON(jsonValue));
    }

    /**
     */
    async getMeetingConfigurationDifference(requestParameters: GetMeetingConfigurationDifferenceRequest): Promise<MeetingConfigurationDifference> {
        const response = await this.getMeetingConfigurationDifferenceRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getMeetingConfigurationsRaw(requestParameters: GetMeetingConfigurationsRequest): Promise<runtime.ApiResponse<Array<MeetingConfiguration>>> {
        const queryParameters: any = {};

        if (requestParameters.themeId !== undefined) {
            queryParameters['themeId'] = requestParameters.themeId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingConfigurationFromJSON));
    }

    /**
     */
    async getMeetingConfigurations(requestParameters: GetMeetingConfigurationsRequest): Promise<Array<MeetingConfiguration>> {
        const response = await this.getMeetingConfigurationsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getThemeIdsWithMeetingConfigurationRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration/themeIdsWithConfiguration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getThemeIdsWithMeetingConfiguration(): Promise<Array<string>> {
        const response = await this.getThemeIdsWithMeetingConfigurationRaw();
        return await response.value();
    }

    /**
     */
    async resetConfigurationTypeRaw(requestParameters: ResetConfigurationTypeRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetConfigurationType.');
        }

        const queryParameters: any = {};

        if (requestParameters.configurationType !== undefined) {
            queryParameters['configurationType'] = requestParameters.configurationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingConfiguration/{id}/reset`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async resetConfigurationType(requestParameters: ResetConfigurationTypeRequest): Promise<MeetingConfiguration> {
        const response = await this.resetConfigurationTypeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateAdvisorsOfferedRaw(requestParameters: UpdateAdvisorsOfferedRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/advisorsOffered`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdvisorsOfferedUpdateDTOToJSON(requestParameters.advisorsOfferedUpdateDTO),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateAdvisorsOffered(requestParameters: UpdateAdvisorsOfferedRequest): Promise<MeetingConfiguration> {
        const response = await this.updateAdvisorsOfferedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateBookingByRaw(requestParameters: UpdateBookingByRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/bookingBy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BookingByToJSON(requestParameters.bookingBy),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateBookingBy(requestParameters: UpdateBookingByRequest): Promise<MeetingConfiguration> {
        const response = await this.updateBookingByRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCalendarTimeFromBookingToMeetingsRaw(requestParameters: UpdateCalendarTimeFromBookingToMeetingsRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/calendarTimeFromBookingToMeetings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarTimeFromBookingToMeetingsToJSON(requestParameters.calendarTimeFromBookingToMeetings),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateCalendarTimeFromBookingToMeetings(requestParameters: UpdateCalendarTimeFromBookingToMeetingsRequest): Promise<MeetingConfiguration> {
        const response = await this.updateCalendarTimeFromBookingToMeetingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateEnabledMeetingTypesRaw(requestParameters: UpdateEnabledMeetingTypesRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/enabledMeetingTypes`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnabledMeetingTypesToJSON(requestParameters.enabledMeetingTypes),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateEnabledMeetingTypes(requestParameters: UpdateEnabledMeetingTypesRequest): Promise<MeetingConfiguration> {
        const response = await this.updateEnabledMeetingTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateMeetingDurationRaw(requestParameters: UpdateMeetingDurationRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/meetingDuration`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingDurationToJSON(requestParameters.meetingDuration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateMeetingDuration(requestParameters: UpdateMeetingDurationRequest): Promise<MeetingConfiguration> {
        const response = await this.updateMeetingDurationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePostProcessingRaw(requestParameters: UpdatePostProcessingRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/postProcessing`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingProcessingToJSON(requestParameters.meetingProcessing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updatePostProcessing(requestParameters: UpdatePostProcessingRequest): Promise<MeetingConfiguration> {
        const response = await this.updatePostProcessingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updatePreProcessingRaw(requestParameters: UpdatePreProcessingRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/preProcessing`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingProcessingToJSON(requestParameters.meetingProcessing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updatePreProcessing(requestParameters: UpdatePreProcessingRequest): Promise<MeetingConfiguration> {
        const response = await this.updatePreProcessingRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateTimeBetweenMeetingsRaw(requestParameters: UpdateTimeBetweenMeetingsRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/timeBetweenMeetings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingDurationToJSON(requestParameters.meetingDuration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateTimeBetweenMeetings(requestParameters: UpdateTimeBetweenMeetingsRequest): Promise<MeetingConfiguration> {
        const response = await this.updateTimeBetweenMeetingsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateTravelTimeBufferRaw(requestParameters: UpdateTravelTimeBufferRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/travelTimeBuffer`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingProcessingToJSON(requestParameters.meetingProcessing),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateTravelTimeBuffer(requestParameters: UpdateTravelTimeBufferRequest): Promise<MeetingConfiguration> {
        const response = await this.updateTravelTimeBufferRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateWorkingTimeFromBookingToMeetingsRaw(requestParameters: UpdateWorkingTimeFromBookingToMeetingsRequest): Promise<runtime.ApiResponse<MeetingConfiguration>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingConfiguration/workingTimeFromBookingToMeetings`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingDurationToJSON(requestParameters.meetingDuration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateWorkingTimeFromBookingToMeetings(requestParameters: UpdateWorkingTimeFromBookingToMeetingsRequest): Promise<MeetingConfiguration> {
        const response = await this.updateWorkingTimeFromBookingToMeetingsRaw(requestParameters);
        return await response.value();
    }

}
