/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomerCategory,
    CustomerCategoryFromJSON,
    CustomerCategoryToJSON,
} from '../models';

export interface CreateCustomerCategoryRequest {
    customerCategory?: CustomerCategory;
}

export interface DeleteCustomerCategoryRequest {
    id?: string;
}

export interface UpdateCustomerCategoryRequest {
    customerCategory?: CustomerCategory;
}

/**
 * 
 */
export class CustomerCategoryApi extends runtime.BaseAPI {

    /**
     */
    async createCustomerCategoryRaw(requestParameters: CreateCustomerCategoryRequest): Promise<runtime.ApiResponse<CustomerCategory>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/CustomerCategory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerCategoryToJSON(requestParameters.customerCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerCategoryFromJSON(jsonValue));
    }

    /**
     */
    async createCustomerCategory(requestParameters: CreateCustomerCategoryRequest): Promise<CustomerCategory> {
        const response = await this.createCustomerCategoryRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCustomerCategoryRaw(requestParameters: DeleteCustomerCategoryRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CustomerCategory`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCustomerCategory(requestParameters: DeleteCustomerCategoryRequest): Promise<void> {
        await this.deleteCustomerCategoryRaw(requestParameters);
    }

    /**
     */
    async getAllCustomerCategoriesRaw(): Promise<runtime.ApiResponse<Array<CustomerCategory>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CustomerCategory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomerCategoryFromJSON));
    }

    /**
     */
    async getAllCustomerCategories(): Promise<Array<CustomerCategory>> {
        const response = await this.getAllCustomerCategoriesRaw();
        return await response.value();
    }

    /**
     */
    async updateCustomerCategoryRaw(requestParameters: UpdateCustomerCategoryRequest): Promise<runtime.ApiResponse<CustomerCategory>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/CustomerCategory`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerCategoryToJSON(requestParameters.customerCategory),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerCategoryFromJSON(jsonValue));
    }

    /**
     */
    async updateCustomerCategory(requestParameters: UpdateCustomerCategoryRequest): Promise<CustomerCategory> {
        const response = await this.updateCustomerCategoryRaw(requestParameters);
        return await response.value();
    }

}
