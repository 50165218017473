import { HStack, ListItem, UnorderedList, Text, BoxProps } from "@chakra-ui/react"
import React, { useMemo } from "react";
import { ArrowRightIcon } from "../../icons/ArrowRightIcon";
import { ContentCard } from "../ContentCard/ContentCard";

interface Props extends BoxProps {
    selectedGroupItemInput?: GroupItem | undefined;
    handleGroupItemClicked: (id: string | undefined) => void;
    groupItems: GroupItem[];
    heading?: string;
}

export interface GroupItem {
    name: string;
    id: string;
}

export const GroupItemsList = ( {heading, groupItems, selectedGroupItemInput, handleGroupItemClicked, ...props}: Props) => {
    const selectedGroupItem = useMemo(() => selectedGroupItemInput, [selectedGroupItemInput]);

    // style used for the selected groupitem list item
    const selectedStyle = { bgColor: "bookMeLightBlue", borderLeftColor: "gray.500", cursor: "pointer" };

    function handleClick(id: string): void {
        handleGroupItemClicked(id);
    }

    return (
        <ContentCard {...props} heading={heading}>
        <UnorderedList spacing={4} styleType="none">
            {groupItems?.map((groupItem) => {

                return (
                    <ListItem {...(selectedGroupItem?.id === groupItem.id ? { ...selectedStyle } : {})}
                        key={groupItem.id} flex="1" textAlign="left" fontSize="18px"
                        _hover={{ bgColor: "bookMeLightBlue", cursor: "pointer" }} onClick={() => handleClick(groupItem.id)}>
                        <HStack justifyContent="space-between" width="100%" paddingX="1em" paddingY="2">
                            <Text fontSize="18px">{groupItem.name}</Text>
                            {selectedGroupItem?.id === groupItem.id ? <ArrowRightIcon width="24px" height="24px" color="gray.500" /> : null}
                        </HStack>
                    </ListItem>)
            })}
        </UnorderedList>
    </ContentCard>
    );
}