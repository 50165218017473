import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { apiRequest } from "./authconfig";


const context = React.createContext("");

export const useTokenContext = () => {
    return React.useContext(context);
}

export const TokenContextProvider = ({children}: React.PropsWithChildren<{}>) => {
    const TokenContext = context.Provider;
    const { instance, inProgress, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [accessToken, setAccessToken] = useState<string>("");

    useEffect(() => {
        if (account && inProgress === "none") {
            const request = {
                ...apiRequest,
                account: account
            };
            instance.acquireTokenSilent(request).then((response) => {
                setAccessToken(response.accessToken);
            }).catch((silentError) => {
                if (silentError instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenPopup(request).then(response => {
                        setAccessToken(response.accessToken);
                    }).catch(popUpError => {
                        console.log(popUpError);
                    });
                }
                console.log(silentError);
            });
        }
    }, [account, instance, inProgress]);

    if (!accessToken) {
        return <></>;
    }

    return (
    <TokenContext value={accessToken}>
        {children}
    </TokenContext>);

}