/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingTypeEnum,
    MeetingTypeEnumFromJSON,
    MeetingTypeEnumFromJSONTyped,
    MeetingTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingTypeForCustomer
 */
export interface MeetingTypeForCustomer {
    /**
     * 
     * @type {MeetingTypeEnum}
     * @memberof MeetingTypeForCustomer
     */
    type: MeetingTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingTypeForCustomer
     */
    enabled: boolean;
}

export function MeetingTypeForCustomerFromJSON(json: any): MeetingTypeForCustomer {
    return MeetingTypeForCustomerFromJSONTyped(json, false);
}

export function MeetingTypeForCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingTypeForCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': MeetingTypeEnumFromJSON(json['type']),
        'enabled': json['enabled'],
    };
}

export function MeetingTypeForCustomerToJSON(value?: MeetingTypeForCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MeetingTypeEnumToJSON(value.type),
        'enabled': value.enabled,
    };
}


