import { useMemo } from "react"
import { useQuery } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { EmbedApi } from "./client";

export const useEmbedApi = () => {
  const baseConfiguration = useBaseConfiguration();
  const advisorApi = useMemo(
    () => new EmbedApi(baseConfiguration), [baseConfiguration]
  );

  return advisorApi;
};

export const useGetEmbedUrl = () => {
  const client = useEmbedApi();
  return useQuery(["getEmbedUrl"], async () => {
    return client.getEmbedUrl();
  });
};