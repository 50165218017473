import {
    ComponentMultiStyleConfig
} from "@chakra-ui/react";

export const TimeRangeInputConfigKey = "TimeRangeInput";

export const TimeRangeInputConfig: ComponentMultiStyleConfig = {
    parts: ["container", "startTimeInput", "divider", "endTimeInput", "timeSelectContainer", "timeSelectColumn", "selectedTimeButton", "timeButton"],
    baseStyle: {
        container: {
            display: "flex",
            position: "relative",
            width: "fit-content",
            justifyContent: "space-evenly",
            alignItems: "center",
            outline: "2px solid transparent",
            outlineOffset: "2px",
            appearance: "none",
            transitionProperty: "common",
            transitionDuration: "normal",
            fontSize: "md",
            paddingStart: "4",
            paddingEnd: "4",
            paddingInlineStart: "4",
            paddingInlineEnd: "4",
            height: "10",
            borderRadius: "md",
            border: "1px solid",
            borderColor: "inherit"
        },
        startTimeInput: {
            appearance: "none",
            width: "5ch"
        },
        divider: {
            px: "4"
        },
        endTimeInput: {
            appearance: "none",
            width: "5ch"
        }, 
        timeSelectContainer: {
            position: "absolute",
            maxH: "xs",
            backgroundColor: "white",
            shadow: "md",
        },
        timeSelectColumn: {
            overflowY: "auto",
            flex: "1 1",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                width: 0,
                background: "transparent"
            },
            "&::-webkit-scrollbar-thumb":{
                background: "red"
            }
        },
        timeButton: {
            py: "2",
            px: "6",
            textAlign: "center",
            userSelect: "none",
            _hover: {
                backgroundColor: "gray.300"
            }
        }, 
        selectedTimeButton: {
            py: "2",
            px: "4",
            textAlign: "center",
            userSelect: "none",
            backgroundColor: "blue.300",
            color: "white",
            _hover: {
                backgroundColor: "gray.300"
            }
        }
    }
};