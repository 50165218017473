import { 
    Tab, 
    TabList, 
    TabPanel, 
    TabPanels, 
    Tabs 
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MeetingConfigurationDifferenceInputs } from "./MeetingConfigurationDifferenceInputs";
import { MeetingConfigurationInputs } from "./MeetingConfigurationInputs";

interface Props {

}

export const MeetingConfigurationPane = () => {
    const { t } = useTranslation();
    return (
        <Tabs variant="button-like" colorScheme="gray">
            <TabList mb="1em">
                <Tab>{t('meetingconfigurationpage.configurationpane.tab1')}</Tab>
                <Tab>{t('meetingconfigurationpage.configurationpane.tab2')}</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <MeetingConfigurationDifferenceInputs />
                </TabPanel>
                <TabPanel>
                    <MeetingConfigurationInputs />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};