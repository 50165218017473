/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceLevelMeetingType,
    ServiceLevelMeetingTypeFromJSON,
    ServiceLevelMeetingTypeFromJSONTyped,
    ServiceLevelMeetingTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevel
 */
export interface ServiceLevel {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceLevel
     */
    locationConfigurationIds?: Array<string> | null;
    /**
     * 
     * @type {Array<ServiceLevelMeetingType>}
     * @memberof ServiceLevel
     */
    meetingTypes?: Array<ServiceLevelMeetingType> | null;
}

export function ServiceLevelFromJSON(json: any): ServiceLevel {
    return ServiceLevelFromJSONTyped(json, false);
}

export function ServiceLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationConfigurationIds': !exists(json, 'locationConfigurationIds') ? undefined : json['locationConfigurationIds'],
        'meetingTypes': !exists(json, 'meetingTypes') ? undefined : (json['meetingTypes'] === null ? null : (json['meetingTypes'] as Array<any>).map(ServiceLevelMeetingTypeFromJSON)),
    };
}

export function ServiceLevelToJSON(value?: ServiceLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationConfigurationIds': value.locationConfigurationIds,
        'meetingTypes': value.meetingTypes === undefined ? undefined : (value.meetingTypes === null ? null : (value.meetingTypes as Array<any>).map(ServiceLevelMeetingTypeToJSON)),
    };
}


