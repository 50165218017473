import {
  ComponentSingleStyleConfig
} from "@chakra-ui/react";

export const ButtonConfig: ComponentSingleStyleConfig = {
  variants: {
    "bookMe": {
      bgColor: 'bookMeBlue',
      color: 'white',
      "_hover": {
        bgColor: "rgb(0, 48, 72)",
        "_disabled": {
          bgColor: "bookMeBlue",
          cursor: "not-allowed"
        },
      },
      "_active": {
        bgColor: "rgb(0, 48, 72)"
      },
    },
    "solid": {
      borderRadius: 2
    },
    "navLink": {
      "_hover": {
        textDecoration: "none"
      },
      "_active": {
        textDecoration: "none"
      },
      fontWeight: "normal",
      textDecoration: "none"
    }
  }
};