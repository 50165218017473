/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Advisor,
    AdvisorFromJSON,
    AdvisorToJSON,
    AdvisorSchedule,
    AdvisorScheduleFromJSON,
    AdvisorScheduleToJSON,
    CreateAdvisorSchedule,
    CreateAdvisorScheduleFromJSON,
    CreateAdvisorScheduleToJSON,
    ScheduledAdvisor,
    ScheduledAdvisorFromJSON,
    ScheduledAdvisorToJSON,
} from '../models';

export interface CreateAdvisorScheduleRequest {
    createAdvisorSchedule?: CreateAdvisorSchedule;
}

export interface DeleteAdvisorScheduleRequest {
    id: string;
}

export interface GetAdvisorRequest {
    id: string;
}

export interface GetAdvisorScheduleRequest {
    id: string;
}

export interface UpdateAdvisorRequest {
    advisor?: Advisor;
}

export interface UpdateAdvisorScheduleRequest {
    id: string;
    advisorSchedule?: AdvisorSchedule;
}

export interface UpdateAdvisorScheduleCanBeBookedRequest {
    id: string;
    canBeBooked: boolean;
}

export interface UpdateAllAdvisorSchedulesRequest {
    location?: string;
    advisorSchedule?: AdvisorSchedule;
}

/**
 * 
 */
export class AdvisorApi extends runtime.BaseAPI {

    /**
     */
    async createAdvisorScheduleRaw(requestParameters: CreateAdvisorScheduleRequest): Promise<runtime.ApiResponse<AdvisorSchedule>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Advisor/schedule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAdvisorScheduleToJSON(requestParameters.createAdvisorSchedule),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvisorScheduleFromJSON(jsonValue));
    }

    /**
     */
    async createAdvisorSchedule(requestParameters: CreateAdvisorScheduleRequest): Promise<AdvisorSchedule> {
        const response = await this.createAdvisorScheduleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteAdvisorScheduleRaw(requestParameters: DeleteAdvisorScheduleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAdvisorSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/{id}/schedule`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteAdvisorSchedule(requestParameters: DeleteAdvisorScheduleRequest): Promise<void> {
        await this.deleteAdvisorScheduleRaw(requestParameters);
    }

    /**
     */
    async getAdvisorRaw(requestParameters: GetAdvisorRequest): Promise<runtime.ApiResponse<Advisor>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdvisor.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvisorFromJSON(jsonValue));
    }

    /**
     */
    async getAdvisor(requestParameters: GetAdvisorRequest): Promise<Advisor> {
        const response = await this.getAdvisorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAdvisorScheduleRaw(requestParameters: GetAdvisorScheduleRequest): Promise<runtime.ApiResponse<AdvisorSchedule>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAdvisorSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/{id}/schedule`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvisorScheduleFromJSON(jsonValue));
    }

    /**
     */
    async getAdvisorSchedule(requestParameters: GetAdvisorScheduleRequest): Promise<AdvisorSchedule> {
        const response = await this.getAdvisorScheduleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisors(): Promise<Array<Advisor>> {
        const response = await this.getAllAdvisorsRaw();
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsWithScheduleRaw(): Promise<runtime.ApiResponse<Array<ScheduledAdvisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/withSchedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScheduledAdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisorsWithSchedule(): Promise<Array<ScheduledAdvisor>> {
        const response = await this.getAllAdvisorsWithScheduleRaw();
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsWithScheduleNonBookableRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/nonBookable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisorsWithScheduleNonBookable(): Promise<Array<Advisor>> {
        const response = await this.getAllAdvisorsWithScheduleNonBookableRaw();
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsWithoutCompetencesRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/withoutCompetences`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisorsWithoutCompetences(): Promise<Array<Advisor>> {
        const response = await this.getAllAdvisorsWithoutCompetencesRaw();
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsWithoutCustomerCategoriesRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/withoutCategories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisorsWithoutCustomerCategories(): Promise<Array<Advisor>> {
        const response = await this.getAllAdvisorsWithoutCustomerCategoriesRaw();
        return await response.value();
    }

    /**
     */
    async getAllAdvisorsWithoutScheduleRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/withoutSchedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllAdvisorsWithoutSchedule(): Promise<Array<Advisor>> {
        const response = await this.getAllAdvisorsWithoutScheduleRaw();
        return await response.value();
    }

    /**
     */
    async getAllBookableAdvisorsRaw(): Promise<runtime.ApiResponse<Array<Advisor>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/bookable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdvisorFromJSON));
    }

    /**
     */
    async getAllBookableAdvisors(): Promise<Array<Advisor>> {
        const response = await this.getAllBookableAdvisorsRaw();
        return await response.value();
    }

    /**
     */
    async updateAdvisorRaw(requestParameters: UpdateAdvisorRequest): Promise<runtime.ApiResponse<Advisor>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Advisor`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdvisorToJSON(requestParameters.advisor),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvisorFromJSON(jsonValue));
    }

    /**
     */
    async updateAdvisor(requestParameters: UpdateAdvisorRequest): Promise<Advisor> {
        const response = await this.updateAdvisorRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateAdvisorScheduleRaw(requestParameters: UpdateAdvisorScheduleRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAdvisorSchedule.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Advisor/{id}/schedule`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdvisorScheduleToJSON(requestParameters.advisorSchedule),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAdvisorSchedule(requestParameters: UpdateAdvisorScheduleRequest): Promise<void> {
        await this.updateAdvisorScheduleRaw(requestParameters);
    }

    /**
     */
    async updateAdvisorScheduleCanBeBookedRaw(requestParameters: UpdateAdvisorScheduleCanBeBookedRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAdvisorScheduleCanBeBooked.');
        }

        if (requestParameters.canBeBooked === null || requestParameters.canBeBooked === undefined) {
            throw new runtime.RequiredError('canBeBooked','Required parameter requestParameters.canBeBooked was null or undefined when calling updateAdvisorScheduleCanBeBooked.');
        }

        const queryParameters: any = {};

        if (requestParameters.canBeBooked !== undefined) {
            queryParameters['canBeBooked'] = requestParameters.canBeBooked;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Advisor/{id}/schedule/canBeBooked`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAdvisorScheduleCanBeBooked(requestParameters: UpdateAdvisorScheduleCanBeBookedRequest): Promise<void> {
        await this.updateAdvisorScheduleCanBeBookedRaw(requestParameters);
    }

    /**
     */
    async updateAllAdvisorSchedulesRaw(requestParameters: UpdateAllAdvisorSchedulesRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.location !== undefined) {
            queryParameters['location'] = requestParameters.location;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Advisor/schedules`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdvisorScheduleToJSON(requestParameters.advisorSchedule),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateAllAdvisorSchedules(requestParameters: UpdateAllAdvisorSchedulesRequest): Promise<void> {
        await this.updateAllAdvisorSchedulesRaw(requestParameters);
    }

}
