/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Taxonomy,
    TaxonomyFromJSON,
    TaxonomyToJSON,
} from '../models';

export interface CreateMeetingThemeRequest {
    taxonomy?: Taxonomy;
}

export interface DeleteMeetingThemeRequest {
    id?: string;
}

export interface UpdateMeetingThemeRequest {
    taxonomy?: Taxonomy;
}

export interface UpdateMeetingThemeOrderRequest {
    taxonomy: Array<Taxonomy>;
}

/**
 * 
 */
export class MeetingThemeApi extends runtime.BaseAPI {

    /**
     */
    async createMeetingThemeRaw(requestParameters: CreateMeetingThemeRequest): Promise<runtime.ApiResponse<Taxonomy>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingTheme`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyToJSON(requestParameters.taxonomy),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyFromJSON(jsonValue));
    }

    /**
     */
    async createMeetingTheme(requestParameters: CreateMeetingThemeRequest): Promise<Taxonomy> {
        const response = await this.createMeetingThemeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteMeetingThemeRaw(requestParameters: DeleteMeetingThemeRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingTheme`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMeetingTheme(requestParameters: DeleteMeetingThemeRequest): Promise<void> {
        await this.deleteMeetingThemeRaw(requestParameters);
    }

    /**
     */
    async getAllMeetingThemesRaw(): Promise<runtime.ApiResponse<Array<Taxonomy>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MeetingTheme`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyFromJSON));
    }

    /**
     */
    async getAllMeetingThemes(): Promise<Array<Taxonomy>> {
        const response = await this.getAllMeetingThemesRaw();
        return await response.value();
    }

    /**
     */
    async updateMeetingThemeRaw(requestParameters: UpdateMeetingThemeRequest): Promise<runtime.ApiResponse<Taxonomy>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingTheme`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TaxonomyToJSON(requestParameters.taxonomy),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TaxonomyFromJSON(jsonValue));
    }

    /**
     */
    async updateMeetingTheme(requestParameters: UpdateMeetingThemeRequest): Promise<Taxonomy> {
        const response = await this.updateMeetingThemeRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateMeetingThemeOrderRaw(requestParameters: UpdateMeetingThemeOrderRequest): Promise<runtime.ApiResponse<Array<Taxonomy>>> {
        if (requestParameters.taxonomy === null || requestParameters.taxonomy === undefined) {
            throw new runtime.RequiredError('taxonomy','Required parameter requestParameters.taxonomy was null or undefined when calling updateMeetingThemeOrder.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/MeetingTheme/ordering`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.taxonomy.map(TaxonomyToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TaxonomyFromJSON));
    }

    /**
     */
    async updateMeetingThemeOrder(requestParameters: UpdateMeetingThemeOrderRequest): Promise<Array<Taxonomy>> {
        const response = await this.updateMeetingThemeOrderRaw(requestParameters);
        return await response.value();
    }

}
