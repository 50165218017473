import { Box, Text, Input } from "@chakra-ui/react";
import { useState } from "react";
import { CustomerCategory } from "../api/client";
import { useCreateCustomerCategory, useDeleteCustomerCategory, useGetAllCustomerCategories, useUpdateCustomerCategory } from "../api/useCustomerCategoriesApi";
import { EditableItemListView } from "../components/ItemListView";
import { ColumnLayout } from "../components/layout/ColumnLayout";
import { ContentCard } from "../components/ContentCard/ContentCard";
import { CreateButton } from "../components/Buttons/CreateButton";
import { useTranslation } from "react-i18next";

export const CustomerCategoriesPage = () => {
    const {data: customerCategories} = useGetAllCustomerCategories();
    const [customerCategoryName, setCustomerCategoryName] = useState("");
    const useDeleteCustomerCategoryMutation = useDeleteCustomerCategory();
    const useUpdateCustomerCategoryMutation = useUpdateCustomerCategory();
    const useCreateCustomerCategoryMutation = useCreateCustomerCategory();

    const { t } = useTranslation();

    function handleCreateCustomerSubmit() {
        const categoryToCreate: CustomerCategory = {name: customerCategoryName}
        useCreateCustomerCategoryMutation.mutate(categoryToCreate)
    }

    return (
        <ColumnLayout autoWidth>
            <ContentCard minHeight="500px" heading={t('customercategoriespage.overview.header')}>
                <EditableItemListView useDeleteTaxonomyMutation={useDeleteCustomerCategoryMutation} useUpdateTaxonomyMutation={useUpdateCustomerCategoryMutation} 
                    taxonomies={customerCategories} disallowReordering={true}  />
            </ContentCard>
            <ContentCard minHeight="500px" heading={t('customercategoriespage.create-form.header')} subHeading={t('customercategoriespage.create-form.subheader')}>
                <Box width="50%">
                    <Text color="gray.300" fontSize="xs">{t('customercategoriespage.create-form.input.label')}</Text>
                    <Input placeholder={t('customercategoriespage.create-form.input.placeholder')} size="lg" shadow="md" defaultValue={customerCategoryName} onBlur={e => setCustomerCategoryName(e.target.value)}
                            borderRadius="0">
                    </Input>
                </Box>
                <CreateButton w="50%" mt="2" onClick={handleCreateCustomerSubmit} text={t('customercategoriespage.create-form.create-button')} />
            </ContentCard>
        </ColumnLayout>
    );
};