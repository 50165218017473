import { Text, VStack, Box, Input, BoxProps, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceGroup } from "../../api/client";
import { useCreateServiceGroup } from "../../api/useServiceGroupApi";
import { useDebounce } from "../../helpers/debounce";
import { CreateButton } from "../Buttons/CreateButton";
import { ContentCard } from "../ContentCard/ContentCard";

interface Props extends BoxProps {
    onGroupCreated: React.Dispatch<React.SetStateAction<ServiceGroup | undefined>>;
}

export const CreateServiceGroup = ({ onGroupCreated, ...props }: Props) => {
    const useCreateServiceGroupMutation = useCreateServiceGroup();
    const removeInvalidStateDebounced = useDebounce(removeInvalidState, 5000);

    const [serviceGroupName, setServiceGroupName] = useState("");
    const [serviceGroupNameIsInvalid, setServiceGroupNameIsInvalid] = useState(false);

    const { t } = useTranslation();

    function removeInvalidState() {
        setServiceGroupNameIsInvalid(false);
    }

    function handleCreateServiceGroup(): void {
        const nameInvalid = serviceGroupName === "";
        setServiceGroupNameIsInvalid(nameInvalid);
        if (nameInvalid) {
            removeInvalidStateDebounced();
            return;
        }

        const serviceGroupToCreate: ServiceGroup = {
            name: serviceGroupName
        }

        useCreateServiceGroupMutation.mutateAsync(serviceGroupToCreate)
            .then(group => {
                onGroupCreated(group);
                setServiceGroupName("");
            });
    }

    return (
        <ContentCard {...props} w="70%" minHeight="500px" heading={t('servicegrouppage.create-form.header')}
            subHeading={t('servicegrouppage.create-form.subheader')}>
            <VStack spacing="4" align="stretch">
                <Box width="50%">
                    <Text color="gray.300" fontSize="xs">{t('servicegrouppage.create-form.input.label')}</Text>
                    <FormControl isInvalid={serviceGroupNameIsInvalid}>
                        <Input placeholder={t('servicegrouppage.create-form.input.placeholder')} size="lg" shadow="md" value={serviceGroupName} onChange={e => setServiceGroupName(e.target.value)}
                            borderRadius="0">
                        </Input>
                        {serviceGroupNameIsInvalid && <FormErrorMessage>{t('servicegrouppage.create-form.input.error-message')}</FormErrorMessage>}
                    </FormControl>
                </Box>
                <CreateButton w="50%" mt="2" onClick={handleCreateServiceGroup} text={t('servicegrouppage.create-form.create-button')}/>
            </VStack>
        </ContentCard>);
}