/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvisorBankOptionsItem,
    AdvisorBankOptionsItemFromJSON,
    AdvisorBankOptionsItemFromJSONTyped,
    AdvisorBankOptionsItemToJSON,
    MeetingTypeBankOptionItem,
    MeetingTypeBankOptionItemFromJSON,
    MeetingTypeBankOptionItemFromJSONTyped,
    MeetingTypeBankOptionItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateBankOptions
 */
export interface UpdateBankOptions {
    /**
     * 
     * @type {string}
     * @memberof UpdateBankOptions
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankOptions
     */
    openingTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankOptions
     */
    closingTime?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBankOptions
     */
    maxTimeFromBookingToMeeting?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateBankOptions
     */
    maxNumberOfMeetingsPerDay?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateBankOptions
     */
    isFilterShowTimesAsCustomerEnabled?: boolean | null;
    /**
     * 
     * @type {Array<MeetingTypeBankOptionItem>}
     * @memberof UpdateBankOptions
     */
    meetingTypeLabels?: Array<MeetingTypeBankOptionItem> | null;
    /**
     * 
     * @type {Array<AdvisorBankOptionsItem>}
     * @memberof UpdateBankOptions
     */
    advisorLabels?: Array<AdvisorBankOptionsItem> | null;
}

export function UpdateBankOptionsFromJSON(json: any): UpdateBankOptions {
    return UpdateBankOptionsFromJSONTyped(json, false);
}

export function UpdateBankOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBankOptions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'openingTime': !exists(json, 'openingTime') ? undefined : json['openingTime'],
        'closingTime': !exists(json, 'closingTime') ? undefined : json['closingTime'],
        'maxTimeFromBookingToMeeting': !exists(json, 'maxTimeFromBookingToMeeting') ? undefined : json['maxTimeFromBookingToMeeting'],
        'maxNumberOfMeetingsPerDay': !exists(json, 'maxNumberOfMeetingsPerDay') ? undefined : json['maxNumberOfMeetingsPerDay'],
        'isFilterShowTimesAsCustomerEnabled': !exists(json, 'isFilterShowTimesAsCustomerEnabled') ? undefined : json['isFilterShowTimesAsCustomerEnabled'],
        'meetingTypeLabels': !exists(json, 'meetingTypeLabels') ? undefined : (json['meetingTypeLabels'] === null ? null : (json['meetingTypeLabels'] as Array<any>).map(MeetingTypeBankOptionItemFromJSON)),
        'advisorLabels': !exists(json, 'advisorLabels') ? undefined : (json['advisorLabels'] === null ? null : (json['advisorLabels'] as Array<any>).map(AdvisorBankOptionsItemFromJSON)),
    };
}

export function UpdateBankOptionsToJSON(value?: UpdateBankOptions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'openingTime': value.openingTime,
        'closingTime': value.closingTime,
        'maxTimeFromBookingToMeeting': value.maxTimeFromBookingToMeeting,
        'maxNumberOfMeetingsPerDay': value.maxNumberOfMeetingsPerDay,
        'isFilterShowTimesAsCustomerEnabled': value.isFilterShowTimesAsCustomerEnabled,
        'meetingTypeLabels': value.meetingTypeLabels === undefined ? undefined : (value.meetingTypeLabels === null ? null : (value.meetingTypeLabels as Array<any>).map(MeetingTypeBankOptionItemToJSON)),
        'advisorLabels': value.advisorLabels === undefined ? undefined : (value.advisorLabels === null ? null : (value.advisorLabels as Array<any>).map(AdvisorBankOptionsItemToJSON)),
    };
}


