import React from "react";
import {
    VStack,
    Text
} from "@chakra-ui/react";
import { ConfigurationTypes } from "../../api/client";
import { useResetMeetingConfigurationType } from "../../api/useMeetingConfigurationApi";
import { AdvisorsOfferedInput } from "./Inputs/AdvisorsOfferedInput";
import { BookingByInput } from "./Inputs/BookingByInput";
import { EnabledMeetingTypesInput } from "./Inputs/EnabledMeetingTypesInput";
import { InputWithReset } from "./Inputs/InputWithReset";
import { MeetingDurationInput } from "./Inputs/MeetingDurationInput";
import { PostProcessingInput } from "./Inputs/PostProcessingInput";
import { PreProcessingInput } from "./Inputs/PreProcessingInput";
import { TimeBetweenMeetingsInput } from "./Inputs/TimeBetweenMeetingsInput";
import { CalendarTimeFromBookingToMeetingInput } from "./Inputs/CalendarTimeFromBookingToMeetingInput";
import { useMeetingConfigurationContext } from "./MeetingConfigurationContext";
import { useTranslation } from "react-i18next";
import { WorkingTimeFromBookingToMeetingInput } from "./Inputs/WorkingTimeFromBookingToMeetingInput";

export const MeetingConfigurationDifferenceInputs = () => {
    const resetMeetingConfigurationTypeMutation = useResetMeetingConfigurationType();
    const { meetingConfigurationDifference } = useMeetingConfigurationContext();
    const { t } = useTranslation();

    const anyVisibleElements = React.useMemo(() => {
        if (meetingConfigurationDifference === undefined) return false;

        return (meetingConfigurationDifference.isBookingByDifferent ||
            meetingConfigurationDifference.isEnabledMeetingTypesDifferent || 
            meetingConfigurationDifference.isMeetingDurationsDifferent || 
            meetingConfigurationDifference.isPreProcessingsDifferent || 
            meetingConfigurationDifference.isPostProcessingsDifferent || 
            meetingConfigurationDifference.isCalendarTimeFromBookingToMeetingsDifferent || 
            meetingConfigurationDifference.isWorkingTimeFromBookingToMeetingsDifferent || 
            meetingConfigurationDifference.isTimeBetweenMeetingsDifferent || 
            meetingConfigurationDifference.isAdvisorsOfferedDifferent)
    }, [meetingConfigurationDifference]);

    if (meetingConfigurationDifference === undefined) return null;

    function resetMeetingConfigurationType(type: ConfigurationTypes) {
        resetMeetingConfigurationTypeMutation.mutate({ id: meetingConfigurationDifference!.id!, configurationType: type });
    }

    return (
        <VStack alignItems="stretch" spacing="2em">
            {!anyVisibleElements && (<Text as="em">{t('meetingconfigurationpage.configuration-difference.text')}</Text>)}
            {meetingConfigurationDifference.isBookingByDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.BookingBy)}><BookingByInput /></InputWithReset>)}
            {meetingConfigurationDifference.isEnabledMeetingTypesDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.EnabledMeetingTypes)}><EnabledMeetingTypesInput /></InputWithReset>)}
            {meetingConfigurationDifference.isMeetingDurationsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.MeetingDuration)}><MeetingDurationInput /></InputWithReset>)}
            {meetingConfigurationDifference.isPreProcessingsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.PreProcessing)}><PreProcessingInput /></InputWithReset>)}
            {meetingConfigurationDifference.isPostProcessingsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.PostProcessing)}><PostProcessingInput /></InputWithReset>)}
            {meetingConfigurationDifference.isTravelTimeBuffersDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.TravelTimeBuffer)}><PostProcessingInput /></InputWithReset>)}
            {meetingConfigurationDifference.isCalendarTimeFromBookingToMeetingsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.CalendarTimeFromBookingToMeetings)}><CalendarTimeFromBookingToMeetingInput /></InputWithReset>)}
            {meetingConfigurationDifference.isWorkingTimeFromBookingToMeetingsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.WorkingTimeFromBookingToMeetings)}><WorkingTimeFromBookingToMeetingInput /></InputWithReset>)}
            {meetingConfigurationDifference.isTimeBetweenMeetingsDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.TimeBetweenMeetings)}><TimeBetweenMeetingsInput /></InputWithReset>)}
            {meetingConfigurationDifference.isAdvisorsOfferedDifferent && (<InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.AdvisorsOffered)}><AdvisorsOfferedInput /></InputWithReset>)}
        </VStack>
    );
};