/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationConfiguration
 */
export interface LocationConfiguration {
    /**
     * 
     * @type {string}
     * @memberof LocationConfiguration
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationConfiguration
     */
    addRoomsToBookedMeetings: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocationConfiguration
     */
    requireAvailableRoom: boolean;
    /**
     * 
     * @type {string}
     * @memberof LocationConfiguration
     */
    location: string;
}

export function LocationConfigurationFromJSON(json: any): LocationConfiguration {
    return LocationConfigurationFromJSONTyped(json, false);
}

export function LocationConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'addRoomsToBookedMeetings': json['addRoomsToBookedMeetings'],
        'requireAvailableRoom': json['requireAvailableRoom'],
        'location': json['location'],
    };
}

export function LocationConfigurationToJSON(value?: LocationConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'addRoomsToBookedMeetings': value.addRoomsToBookedMeetings,
        'requireAvailableRoom': value.requireAvailableRoom,
        'location': value.location,
    };
}


