/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ConfigurationTypes {
    BookingBy = 'BookingBy',
    EnabledMeetingTypes = 'EnabledMeetingTypes',
    MeetingDuration = 'MeetingDuration',
    PreProcessing = 'PreProcessing',
    PostProcessing = 'PostProcessing',
    TravelTimeBuffer = 'TravelTimeBuffer',
    CalendarTimeFromBookingToMeetings = 'CalendarTimeFromBookingToMeetings',
    WorkingTimeFromBookingToMeetings = 'WorkingTimeFromBookingToMeetings',
    TimeBetweenMeetings = 'TimeBetweenMeetings',
    AdvisorsOffered = 'AdvisorsOffered'
}

export function ConfigurationTypesFromJSON(json: any): ConfigurationTypes {
    return ConfigurationTypesFromJSONTyped(json, false);
}

export function ConfigurationTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationTypes {
    return json as ConfigurationTypes;
}

export function ConfigurationTypesToJSON(value?: ConfigurationTypes | null): any {
    return value as any;
}

