import {
    HStack,
    RadioGroup,
    Radio,
    Select
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

type TimeResolution = "minutes" | "days" | "hours";

interface Props {
    timeResolution: TimeResolution;
    label?: string;
    hasYesNoRadio?: boolean;
    yesNoValue?: boolean;
    stepSize?: number;
    stepStart?: number;
    stepEnd?: number;
    value?: string;
    isDisabled?: boolean;
    onUserChoiceChanged?: (isYes: boolean) => void;
    onSelectChanged?: (value: string) => void;
}

interface TimeOption {
    label: string;
    value: string;
}

export const TimeDropdown = ({ timeResolution, label, hasYesNoRadio, 
    stepSize, stepStart, stepEnd, onUserChoiceChanged, onSelectChanged, value, yesNoValue, isDisabled }: Props) => {
    const { t } = useTranslation();
    const options = React.useMemo(() => {
        const options: TimeOption[] = [];
        if (timeResolution === "days") {
            for (let i = (stepStart || 0); i <= (stepEnd || 60); i += (stepSize || 1)) {
                options.push({ label: getTimeOptionLabel(timeResolution, i, 0, 0), value: getTimeOptionValue(timeResolution, i, 0, 0) });
            }
        } else if (timeResolution === "hours") {
            for (let i = (stepStart || 0); i <= (stepEnd || 60); i += (stepSize || 1)) {
                const val = i * (stepSize || 1);
                const days = Math.floor(val / 24);
                const hours = val % 24;
                const label = getTimeOptionLabel(timeResolution, days, hours, 0);
                const value = getTimeOptionValue(timeResolution, days, hours, 0);
                options.push({ label, value: `${i === 0 ? `00:00:00` : value}` });
            }
        } else {
            for (let i = (stepStart || 0); i <= (stepEnd || 24); i++) {
                const val = i * (stepSize || 5);
                const hours = Math.floor(val / 60);
                const minutes = val % 60;
                const label = getTimeOptionLabel(timeResolution, 0, hours, minutes);
                const value = getTimeOptionValue(timeResolution, 0, hours, minutes);
                options.push({ label, value });
            }
        }
        // Insert the stored value if it isn't one of the select options
        if (value && !options.find(x => x.value === value)) {
            // get Timespan Option of value
            const opt = timeSpanToTimeOption(value, timeResolution);
            if (opt) {
                options.unshift(opt);
            }
        }
        return options;
    }, [timeResolution, stepSize, stepStart, stepEnd, t]);


    function timeSpanToTimeOption(timeSpan: string, timeResolution: TimeResolution): TimeOption | undefined {
        const split = timeSpan.split(':');

        if (split.length !== 3) {
            return;
        }
        
        const days = +(split[0].includes('.') ? split[0].split('.')[0] : '0');
        const hours = +(split[0].includes('.') ? split[0].split('.')[1] : split[0]);
        const minutes = +(split[1]);

        const label = getTimeOptionLabel(timeResolution, days, hours, minutes);

        return { label: label, value: timeSpan};
    }

    function getTimeOptionLabel(timeResolution: TimeResolution, days: number, hours: number, minutes: number): string {
        if (timeResolution === "days") {
            return `${days} ${days === 1 ? t('timedropdown.day') : t('timedropdown.days')}`
        }
        if (timeResolution === "hours") {
            return `${days > 0 ? `${days} ${days === 1 ? t('timedropdown.day') : t('timedropdown.days') }` : ""} ${hours}${t('timedropdown.hour.abbrevation')}`;
        }
        if (timeResolution === "minutes") {
            return `${hours > 0 ? `${hours}${t('timedropdown.hour.abbrevation')}` : ""} ${minutes}${t('timedropdown.minute.abbrevation')}`;
        } else {
            throw new Error("The provided time resolution does not exist");
            
        }
    }

    function getTimeOptionValue(timeResolution: TimeResolution, days: number, hours: number, minutes: number): string {
        if (timeResolution === "days") {
            return `${days === 0 ? `00:00:00` : days +`.00:00:00` }`
        }
        if (timeResolution === "hours") {
            return `${days > 0 ? days.toString() + '.' : ""}` + `${hours.toString().padStart(2, "0")}:00:00`;
        }
        if (timeResolution === "minutes") {
            return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;
        } else {
            throw new Error("The provided time resolution does not exist");
        }
    }

    return (
        <HStack justifyContent="space-between" spacing="5em">
            {hasYesNoRadio && (
                <RadioGroup isDisabled={isDisabled} value={yesNoValue ? "true" : "false"} onChange={value => onUserChoiceChanged?.(value.toLowerCase() === "true")}>
                    <HStack>
                        <Radio value="true">{t('timedropdown.radio.yes')}</Radio>
                        <Radio value="false">{t('timedropdown.radio.no')}</Radio>
                    </HStack>
                </RadioGroup>
            )}
            <Select placeholder={label} size="md" width="10em" value={value} isDisabled={(hasYesNoRadio && !yesNoValue) || isDisabled} onChange={e => onSelectChanged?.(e.target.value)}>
                {options.map(timeOpt => {
                    return <option key={`${timeOpt.value}`} value={timeOpt.value}>{timeOpt.label}</option>;
                })}
            </Select>
        </HStack>
    );
};