import jwtDecode from "jwt-decode";
import { useEffect, useMemo, useState } from "react";
import { useTokenContext } from "./TokenContext";

export function useHasRoles(roles: string[]) {
    const [hasRoles, setHasRoles] = useState(false);
    const token: string = useTokenContext();
    const userRoles: string[] = useMemo(() => {
        const decodedToken: { [key: string]: any } = jwtDecode(token);
        const roles = decodedToken['roles'];
        if (roles) {
            return roles;
        } else {
            return [];
        }
    }, [token]);

    useEffect(() => {
        if (userRoles) {
            const intersection = roles
            .filter(role => userRoles.includes(role));

            intersection.length > 0 ? setHasRoles(true) : setHasRoles(false);
        }
    }, [userRoles, roles]);

    return hasRoles;

}