import { Icon, IconProps } from "@chakra-ui/react";

export const SettingsIcon = (props: IconProps) => {
    return (
        <Icon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M22.8262 7.05098C22.6799 6.53724 22.6387 6.51379 22.1062 6.48005C21.3018 6.42942 20.4984 6.37692 19.7062 6.31223C19.2618 6.27661 19.2721 6.2663 19.3218 5.83223C19.4399 
            4.83849 19.5412 3.84004 19.6555 2.81063C19.6433 2.64095 19.5299 2.49563 19.3677 2.44315C18.0571 1.80283 16.7484 1.15876 15.4416 0.510953C15.2447 0.395641 14.9944 0.435015 14.8416
            0.604702C14.0831 1.34345 13.3153 2.07096 12.5569 2.81064C12.5081 2.88376 12.4266 2.92783 12.3384 2.92783C12.2503 2.92783 12.1687 2.88376 12.12 2.81064C11.2987 1.99877 10.4691 1.20002
             9.6432 0.393838C9.4032 0.153838 9.29068 0.129454 8.99258 0.273838C7.68007 0.902902 6.37874 1.53852 5.07098 2.16009C4.86473 2.24259 4.75036 2.46197 4.80004 2.67854C4.91536 3.73699 5.01567
              4.80009 5.13379 5.85854C5.16191 5.94198 5.14598 6.03385 5.09066 6.10229C5.03629 6.17072 4.95004 6.20635 4.86285 6.19697C3.69659 6.34604 2.53223 6.50917 1.36605 6.67697C1.15324 6.68541 0.977921
               6.84572 0.950729 7.05572C0.646049 8.45727 0.338537 9.86636 0.0338574 11.2802C-0.0411426 11.6308 0.000108624 11.7143 0.295409 11.9046L2.93541 13.5658C3.17541 13.7121 3.17541 13.758 3.03853
                13.9999L1.30322 16.985C1.13072 17.2803 1.14009 17.3788 1.35853 17.6403L4.11853 20.9545C4.17197 21.0586 4.26759 21.1354 4.38008 21.1664C4.49352 21.1973 4.61445 21.1804 4.71382 21.1176L7.62478
                 20.062C7.96323 19.9392 7.98198 19.9467 8.10478 20.2729C8.52198 21.3192 8.94478 22.3655 9.35072 23.4174C9.41541 23.6696 9.65259 23.8384 9.91227 23.8159H14.1797C14.5519 23.8159 14.6241 23.7531
                  14.7366 23.3959C15.0628 22.359 15.3919 21.3221 15.7106 20.2759C15.7135 20.1915 15.7603 20.1156 15.8344 20.0743C15.9075 20.034 15.9975 20.034 16.0706 20.0762C17.1506 20.4006 18.2306 20.7099
                   19.3154 21.0362C19.757 21.1656 19.8264 21.144 20.0926 20.7793L22.5939 17.3696C22.7833 17.1109 22.7833 17.005 22.5939 16.7312C22.008 15.8603 21.4277 14.9865 20.8352 14.1174H20.8342C20.7724
                    14.0536 20.7452 13.9627 20.7639 13.8745C20.7817 13.7874 20.8427 13.7142 20.9261 13.6805C21.8674 13.0158 22.8002 12.3389 23.7368 11.6649C24.0011 11.4755 24.0442 11.3649 23.9768 11.0864C23.5905
                     9.73923 23.2071 8.39386 22.8264 7.05154L22.8262 7.05098ZM11.94 16.4568C10.3256 16.4568 8.8332 15.5953 8.02584 14.1974C7.21865 12.7987 7.21865 11.0765 8.02584 9.67776C8.83303 8.27995 10.3255
                      7.41837 11.94 7.41837C13.5545 7.41837 15.0468 8.27993 15.8542 9.67776C16.6614 11.0765 16.6614 12.7987 15.8542 14.1974C15.047 15.5952 13.5545 16.4568 11.94 16.4568Z" fill="white"/>
        </Icon>
    );
}