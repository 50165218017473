import { Icon } from "@chakra-ui/react"
import { BsTrashFill } from "react-icons/bs"

interface Props {
    height: string;
    width: string;
}

export const DeleteIcon = ({height, width}: Props) => {
    return <Icon height={height} width={width} fill="#1A8CF9" as={BsTrashFill}/>
}