import { BoxProps, VStack, Box, Text, Input, HStack, Button, Heading, useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompetenceGroup, CompetenceGroupInput } from "../../api/client";
import { useGetAllAdvisors } from "../../api/useAdvisorApi";
import { useDeleteCompetenceGroup, useUpdateCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { ArrowLeftIcon } from "../../icons/ArrowLeftIcon";
import { ContentCard } from "../ContentCard/ContentCard";
import { OptionValue } from "../DataList";
import { DeleteModal } from "../ItemListView/DeleteModal";
import { DisplayList } from "../ServiceGroup/DisplayList";

interface Props extends BoxProps {
    selectedCompetenceGroupInput: CompetenceGroup;
    backClicked: () => void;
    onCompetenceGroupDeleted: () => void;
}
export const UpdateCompetenceGroup = ({ selectedCompetenceGroupInput, backClicked, onCompetenceGroupDeleted, ...props }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const updateCompetenceGroupMutation = useUpdateCompetenceGroup();
    const deleteCompetenceGroupMutation = useDeleteCompetenceGroup();
    const { t } = useTranslation();

    const selectedCompetenceGroupView = useMemo(() => {
        return selectedCompetenceGroupInput;
    }, [selectedCompetenceGroupInput]);

    const selectedCompetenceGroupUpdateDTO: CompetenceGroupInput = useMemo(() => {
        return {
            name: selectedCompetenceGroupInput.name,
            advisorIds: selectedCompetenceGroupInput.advisorIds,
            childGroupIds: selectedCompetenceGroupInput.childGroupIds,
            customerCategoryIds: selectedCompetenceGroupInput.customerCategories?.map(x => x.id!) || [],
            themeIds: selectedCompetenceGroupInput.themes?.map(x => x.id!) || []
        }
    }, [selectedCompetenceGroupInput]);

    function onDeleteConfirm() {
        deleteCompetenceGroupMutation.mutate(selectedCompetenceGroupView.id!);
        onCompetenceGroupDeleted();
        onClose();
    }

    function handleUpdateCompetenceGroupName(value: string): void {
        if (!value) {
            return;
        }
        updateCompetenceGroupMutation.mutate({
            id: selectedCompetenceGroupView.id!, competenceGroup: {
                ...selectedCompetenceGroupUpdateDTO,
                name: value
            }
        });
    }

    return (
        <ContentCard {...props}>
            <VStack align="stretch" spacing="8">
                <HStack justify="space-between">
                    <HStack>
                        <Button borderRadius="full" bgColor="unset" _hover={{ bgColor: "bookMeLightBlue" }} onClick={() => backClicked()}>
                            <ArrowLeftIcon width="24px" height="24px" />
                        </Button>
                        <Heading as="h2" size="xl" fontWeight="semibold">{t('competencegrouppage.update-group.heading', { groupName: selectedCompetenceGroupView?.name })}</Heading>
                    </HStack>
                    <Button aria-label="delete-button" colorScheme="red" onClick={onOpen}>{t('competencegrouppage.update-group.delete-button')}</Button>
                </HStack>
                <HStack spacing="8" align="stretch" justify="stretch">

                    <VStack w="50%" spacing="4" align="stretch" justify="end">
                        <Box >
                            <Text color="gray.300" fontSize="xs">{t('competencegrouppage.update-group.name-input.label')}</Text>
                            <Input placeholder={t('competencegrouppage.update-group.name-input.placeholder')} size="lg" shadow="md" defaultValue={selectedCompetenceGroupView.name || ""} onChange={e => handleUpdateCompetenceGroupName(e.target.value)}
                                borderRadius="0">
                            </Input>
                        </Box>
                    </VStack>
                    <Box w="50%" />

                </HStack>
            </VStack>
            <DeleteModal isOpen={isOpen} itemName={selectedCompetenceGroupView.name || ""} onClose={onClose} onConfirm={onDeleteConfirm}>
                {t('competencegrouppage.update-group.delete-modal', { groupName: selectedCompetenceGroupView?.name})}
            </DeleteModal>
        </ContentCard>);
}