// language selector component

import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetLanguages } from "../api/useLocalizationApi";
import { LanguageIcon } from "../icons/LanguageIcon";


export const LanguageSelector = () => {
    const { i18n } = useTranslation();
    const { data: languages } = useGetLanguages();
    const [selectedLanguageCode, setSelectedLanguageCode] = useState(i18n.language);
    const selectedLanguage = useMemo(() => {
        if (!languages) return undefined;
        return languages.find(x => x.code === selectedLanguageCode)?.name;
    }, [languages, selectedLanguageCode]);

    const handleChange = (event: any) => {
        setSelectedLanguageCode(event.target.value);
        i18n.changeLanguage(event.target.value);
    };


    if (!languages) return <></>;

    return (
            <Menu>
                <MenuButton as={Button} variant="navLink" color="white" fontSize="lg" rightIcon={<LanguageIcon height="24px" width="24px" />}
                >
                    {selectedLanguage}
                </MenuButton>
                <MenuList>
                    {languages?.map((language) => (
                        <MenuItem key={language.code} value={language.code!} onClick={evt => handleChange(evt)} >{language.name}</MenuItem>
                    ))}
                </MenuList>
            </Menu >
    );
}