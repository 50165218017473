/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingTypeEnum,
    MeetingTypeEnumFromJSON,
    MeetingTypeEnumFromJSONTyped,
    MeetingTypeEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceLevelMeetingType
 */
export interface ServiceLevelMeetingType {
    /**
     * 
     * @type {MeetingTypeEnum}
     * @memberof ServiceLevelMeetingType
     */
    type?: MeetingTypeEnum;
}

export function ServiceLevelMeetingTypeFromJSON(json: any): ServiceLevelMeetingType {
    return ServiceLevelMeetingTypeFromJSONTyped(json, false);
}

export function ServiceLevelMeetingTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceLevelMeetingType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : MeetingTypeEnumFromJSON(json['type']),
    };
}

export function ServiceLevelMeetingTypeToJSON(value?: ServiceLevelMeetingType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': MeetingTypeEnumToJSON(value.type),
    };
}


