import i18next from "i18next";
import { WeekdayEnum } from "../api/client";

export function mapEnglishWeekdayToDanish(weekday: WeekdayEnum | undefined): string {
    switch(weekday) {
        case WeekdayEnum.Monday:
            return i18next.t('weekday.monday');
        case WeekdayEnum.Tuesday:
            return i18next.t('weekday.tuesday');
        case WeekdayEnum.Wednesday:
            return i18next.t('weekday.wednesday');
        case WeekdayEnum.Thursday:
            return i18next.t('weekday.thursday');
        case WeekdayEnum.Friday:
            return i18next.t('weekday.friday');
        case WeekdayEnum.Saturday:
            return i18next.t('weekday.saturday');
        case WeekdayEnum.Sunday:
            return i18next.t('weekday.sunday');
        default:
            throw new Error(`Weekday ${weekday} could not be mapped`);           
    }
}
