/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MeetingDay,
    MeetingDayFromJSON,
    MeetingDayToJSON,
} from '../models';

export interface GetDaysRequest {
    startDate?: string;
    endDate?: string;
    advisorEmails?: Array<string>;
}

/**
 * 
 */
export class CalendarApi extends runtime.BaseAPI {

    /**
     */
    async getDaysRaw(requestParameters: GetDaysRequest): Promise<runtime.ApiResponse<Array<MeetingDay>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = requestParameters.startDate;
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = requestParameters.endDate;
        }

        if (requestParameters.advisorEmails) {
            queryParameters['advisorEmails'] = requestParameters.advisorEmails;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Calendar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingDayFromJSON));
    }

    /**
     */
    async getDays(requestParameters: GetDaysRequest): Promise<Array<MeetingDay>> {
        const response = await this.getDaysRaw(requestParameters);
        return await response.value();
    }

}
