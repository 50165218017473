import { useTranslation } from "react-i18next";
import { useUpdateTimeBetweenMeetings } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { TimeDropdown } from "../../TimeDropdown";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const TimeBetweenMeetingsInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updateTimeBetweenMeetingsMutation = useUpdateTimeBetweenMeetings();
    const { t } = useTranslation();

    function handleTimeBetweenMeetingsChanged(newValue: string, customerCategoryId?: string) {
        updateTimeBetweenMeetingsMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, duration: { customerCategoryId, duration: newValue } });
    }
    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.time-between-meetings.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const timeBetweenMeetings = actualMeetingConfiguration!.timeBetweenMeetings!;
                const value = timeBetweenMeetings.find(x => x.customerCategoryId === customerCategoryId);
                let isDisabled = false
                if (customerCategoryId === undefined && timeBetweenMeetings.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }

                return (
                    <TimeDropdown label={t('meetingconfigurationpage.time-dropdown.label')} isDisabled={isDisabled} timeResolution="minutes" value={value?.duration || ""} onSelectChanged={(newValue) => handleTimeBetweenMeetingsChanged(newValue, customerCategoryId)} />
                );
            }}
        </ConfigurationOptionItem>
    );
};