import './i18n';
import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme/theme';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './queryClient';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authconfig";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <MsalProvider instance={msalInstance}>
            <App />
          </MsalProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
