import { Checkbox } from "@chakra-ui/checkbox";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Box, Center, VStack, HStack } from "@chakra-ui/layout"
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { ListItem, UnorderedList, Text, Heading, Button, useDisclosure, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import i18next from "i18next";
import React, { ChangeEvent, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MeetingTypeEnum, Workday, WeekdayEnum, ScheduledAdvisor, WorkdayMeetingType, MeetingTypeBankOptionItem, Advisor, AdvisorSchedule, LocationConfiguration } from "../api/client";
import {
  useCreateAdvisorSchedule,
  useGetAllAdvisorsWithoutCompetences,
  useGetAllAdvisorsWithoutCustomerCategories,
  useGetAllAdvisorsWithoutSchedule,
  useGetAllAdvisorsWithSchedule,
  useGetAllAdvisorsWithScheduleNonBookable,
  useUpdateAdvisorSchedule,
  useUpdateAdvisorScheduleIsBookable,
  useUpdateAllAdvisorSchedules
} from "../api/useAdvisorApi";
import { useGetMeetingTypeLabels } from "../api/useBankOptionsApi";
import { useGetLocations } from "../api/useLocationApi";
import { appRoles } from "../authconfig";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { ContentCard } from "../components/ContentCard/ContentCard";
import ContentWithHeading from "../components/ContentWithHeading";
import { DataList, OptionValue } from "../components/DataList";
import { useHasRoles } from "../hasRolesHook";
import { useDebounce } from "../helpers/debounce";
import { mapEnglishWeekdayToDanish } from "../helpers/weekdayMapperFunctions";
import { CheckMarkIcon } from "../icons/CheckMarkIcon";

interface WeekdayCardProps {
  advisor: ScheduledAdvisor | undefined;
  dayOfWeek: WeekdayEnum;
  meetingTypes: MeetingTypeBankOptionItem[] | undefined;
  handleWeekdayChange: (workday: Workday) => void;
  locations: LocationConfiguration[] | undefined;
  locationOptions: OptionValue[];
  advisorLocation: string | undefined | null;
}


const allHoursOfDay: string[] = Array.from(Array(24).keys()).map(i => {
  return new Date(0, 0, 0, i, 0, 0).toLocaleTimeString('en-gb', { hour: '2-digit', minute: '2-digit' });
});

const WeekdayCard = ({ advisor, dayOfWeek, meetingTypes, handleWeekdayChange, locations, locationOptions, advisorLocation }: WeekdayCardProps) => {
  const { t } = useTranslation();
  const workday = useMemo(() => {
    return advisor?.workdays.find(x => x.dayOfWeek === dayOfWeek);
  }, [advisor, dayOfWeek]);

  const [mappedLocationOptions, setMappedLocationOptions] = useState<OptionValue[]>();
  const [selectedLocation, setSelectedLocation] = useState<OptionValue[]>();

  useEffect(() => {
    const options = JSON.parse(JSON.stringify(locationOptions)).map((location: OptionValue) => {
      if (location.label === advisorLocation) {
        location.label = t('availabilitypage.configure.standardlocation', {
          locationLabel: location.label
        });

        if (!workday?.location) {
          setSelectedLocation([location]);
        }
      } else if (workday?.location === location.label) {
        setSelectedLocation([location]);
      }

      return location;
    });
    setMappedLocationOptions(options);
  }, [advisorLocation, advisor]);

  function handleIsAvailableChange(event: string) {
    if (workday) {
      const tempWeekday = { ...workday, isAvailable: JSON.parse(event) };
      handleWeekdayChange(tempWeekday);
    }
  }

  function handleFromTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    if (workday) {
      const tempWeekday = { ...workday, from: event.target.value + ":00" };
      handleWeekdayChange(tempWeekday);
    }
  }

  function handleToTimeChange(event: ChangeEvent<HTMLSelectElement>) {
    if (workday) {
      const tempWeekday = { ...workday, to: event.target.value + ":00" };
      handleWeekdayChange(tempWeekday);
    }
  }

  const handleAvailableMeetingTypeChange = React.useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (workday && workday.availableMeetingTypes !== undefined) {
      const meetingType: MeetingTypeEnum = event.target.value as MeetingTypeEnum;
      const distinctWorkdayMeetingTypes: WorkdayMeetingType[] = [...workday.availableMeetingTypes.filter((x, i, arr) => arr.findIndex(y => y.type === x.type) === i)];
      const index = distinctWorkdayMeetingTypes.findIndex(x => x.type === meetingType, 0);
      if (index > -1) {
        distinctWorkdayMeetingTypes.splice(index, 1);
      } else {
        distinctWorkdayMeetingTypes.push({ type: meetingType });
      }
      handleWeekdayChange({ ...workday, availableMeetingTypes: distinctWorkdayMeetingTypes });
    }
  }, [handleWeekdayChange, workday]);

  function handleLocationChange(options: OptionValue[]) {
    if (!workday) {
      return;
    }

    if (options && options[0]) {
      const location = locations?.find(x => x.id === options[0].value)
      if (location) {
        const tempWeekday = {
          ...workday,
          location: location.location === advisorLocation ? null : location.location
        };
        handleWeekdayChange(tempWeekday);
      }
    } else {
      const tempWeekday = {
        ...workday,
        location: null
      };
      handleWeekdayChange(tempWeekday);
    }
  }

  return (
    <GridItem w='100%' h='auto' borderBottom="1px" borderColor="gray.50" paddingBottom="20px" display="grid" gridTemplateColumns="20ch 17ch 17ch 17ch 20ch 1fr">
      <Center bg="bookMeBlue" isTruncated w="100%" color="#FFF" fontWeight="bold">
        {mapEnglishWeekdayToDanish(dayOfWeek)}
      </Center>
      <FormControl as="fieldset" paddingLeft="6" isDisabled={workday === undefined || advisor === undefined || !advisor.canBeBooked}>
        <FormLabel as="legend" fontSize="md">
          {t('availabilitypage.configure.formlabel')}
        </FormLabel>
        <RadioGroup name="is-available" value={`${workday?.isAvailable}`} onChange={handleIsAvailableChange}>
          <HStack alignItems="start">
            <Radio value={`${true}`}>{t('availabilitypage.configure.radio.yes')}</Radio>
            <Radio value={`${false}`}>{t('availabilitypage.configure.radio.no')}</Radio>
          </HStack>
        </RadioGroup>
      </FormControl>
      <FormControl as="fieldset" px="2" isDisabled={workday === undefined || advisor === undefined || !advisor.canBeBooked}>
        <FormLabel fontSize="md">{t('availabilitypage.configure.workdayfrom')}</FormLabel>
        <Select width="12ch" value={workday ? `${workday.from!.substr(0, 5)}` :
          `${new Date(new Date().getFullYear(), 0, 0, 8, 0, 0).toLocaleTimeString(i18next.language, { hour: '2-digit', minute: '2-digit' })}`} onChange={handleFromTimeChange}>
          {allHoursOfDay.map(hour => {
            return <option key={"from" + hour} value={hour}>{hour}</option>;
          })
          }
        </Select>
      </FormControl>
      <FormControl as="fieldset" px="2" isDisabled={workday === undefined || advisor === undefined || !advisor.canBeBooked}>
        <FormLabel fontSize="md">{t('availabilitypage.configure.workdayto')}</FormLabel>
        <Select width="12ch" value={workday ? `${workday.to!.substr(0, 5)}` :
          `${new Date(new Date().getFullYear(), 0, 0, 16, 0, 0).toLocaleTimeString(i18next.language, { hour: '2-digit', minute: '2-digit' })}`} onChange={handleToTimeChange}>
          {allHoursOfDay.map(hour => {
            return <option key={"to" + hour} value={hour}>{hour}</option>;
          })
          }
        </Select>
      </FormControl>
      <FormControl as="fieldset" paddingLeft="2" isDisabled={workday === undefined || advisor === undefined || !advisor.canBeBooked}>
        <FormLabel as="legend" fontSize="md">{t('availabilitypage.configure.meetingtypes')}</FormLabel>
        <VStack alignItems="stretch" paddingLeft="0.25em">
          {meetingTypes ? meetingTypes.map(meetingType => {
            return (<Checkbox size="lg" key={meetingType.name}
              value={meetingType.name}
              isChecked={workday?.availableMeetingTypes?.some(x => x.type === meetingType.name)}
              onChange={handleAvailableMeetingTypeChange}
              isDisabled={workday === undefined || advisor === undefined || !advisor.canBeBooked || !workday.isAvailable || (meetingType.name === MeetingTypeEnum.Physical && advisor.isGloballyAvailable)}
              _focus={{}}
            >
              {meetingType.label}
            </Checkbox>);
          }) : null
          }
        </VStack>
      </FormControl>
      <FormControl as="fieldset" px="2">
        <FormLabel fontSize="md">{t('availabilitypage.configure.employee-location.label')}</FormLabel>
        <DataList placeholder={t('availabilitypage.configure.employee-location.placeholder')} defaultSelectedOptions={selectedLocation} values={mappedLocationOptions} isMulti={false} handleOnChange={handleLocationChange} />
      </FormControl>
    </GridItem>
  );
}

export const ConfigureAvailabilityPage = () => {
  const { data: scheduledAdvisors } = useGetAllAdvisorsWithSchedule();
  const { data: locations } = useGetLocations();
  const [currentAdvisor, setCurrentAdvisor] = useState<ScheduledAdvisor>();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const createAdvisorScheduleMutation = useCreateAdvisorSchedule();
  const updateAdvisorScheduleMutation = useUpdateAdvisorSchedule();
  const updateAdvisorScheduleCanBeBookedMutation = useUpdateAdvisorScheduleIsBookable();
  const updateAllAdvisorSchedules = useUpdateAllAdvisorSchedules();
  const { data: meetingTypeLabels } = useGetMeetingTypeLabels();
  const debouncedUpdateAdvisorScheduleMutation = useDebounce(updateAdvisorScheduleMutation.mutate, 500);
  const { isOpen, onClose, onOpen } = useDisclosure(); // used for confirmation modal
  const [isSubmittingAdvisorSchedule, setIsSubmittingAdvisorSchedule] = useState<boolean>(false);
  const hasConfigurationAccess = useHasRoles([appRoles.AMB_configurationAccess]);
  const { t } = useTranslation();

  const advisorOptions: OptionValue[] = useMemo(() => {
    if (scheduledAdvisors) {
      return (selectedLocation ? scheduledAdvisors.filter(x => x.location === selectedLocation) : scheduledAdvisors)
        .filter(x => x.initials !== null)
        .map(adv => {
          const nameString = `${adv.initials} - ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;
          return { value: adv.id, label: nameString };
        });
    }
    return [];
  }, [selectedLocation, scheduledAdvisors])

  const locationOptions: OptionValue[] = useMemo(() => {
    if (locations) {
      return locations.map(loc => {
        return { value: loc.id, label: loc.location };
      });
    }
    return [];
  }, [locations])

  const totalAdvisorCount = useMemo(() => {
    return advisorOptions.length;
  }, [advisorOptions]);

  const advisorsWithSchedule = useMemo(() => {
    if (scheduledAdvisors) {
      return (selectedLocation ?
        scheduledAdvisors.filter(x => x.location === selectedLocation) :
        scheduledAdvisors)
        // filter out advisors without a schedule
        .filter(x => !!x.scheduleId);
    }
  }, [scheduledAdvisors, selectedLocation])

  const affectedLocationText: string = useMemo(() => {
    return selectedLocation || t('availabilitypage.configure.affect-all-locations');
  }, [selectedLocation, t]);

  const affectedAdvisors: { id: string, label: string }[] | undefined = useMemo(() => {
    if (advisorsWithSchedule) {
      return advisorsWithSchedule
        .filter(x => x.id !== currentAdvisor?.id)
        .map(adv => {
          return {
            id: adv.id,
            label: `${adv.initials} - ${adv.displayName} - ${adv.location}`
          }
        })
    }
  }, [advisorsWithSchedule, currentAdvisor])

  const affectedAdvisorsCountText: string = useMemo(() => {
    return t('availabilitypage.configure.affected-schedules-amount', { amount: affectedAdvisors?.length });
  }, [affectedAdvisors]);

  useEffect(() => {
    if (currentAdvisor?.scheduleId === undefined) {
      const updatedAdvisorWithSchedule = scheduledAdvisors?.find(x => x.id === currentAdvisor?.id);
      if (updatedAdvisorWithSchedule)
        setCurrentAdvisor(updatedAdvisorWithSchedule);
    }
  }, [scheduledAdvisors]);

  function handleAdvisorChange(options: OptionValue[]) {
    if (options && options[0]) {
      const advisor = scheduledAdvisors?.find(x => x.id === options[0].value)
      setCurrentAdvisor(advisor);
    } else {
      setCurrentAdvisor(undefined);
    }
  }

  function handleLocationChange(options: OptionValue[]) {
    if (options && options[0]) {
      const location = locations?.find(x => x.id === options[0].value)
      if (location) {
        setSelectedLocation(location.location);
      }
    } else {
      setSelectedLocation(undefined);
    }
  }

  function handleBookableChange(event: string) {
    if (!currentAdvisor) {
      throw new Error("No advisor selected");
    }

    const canBeBooked: boolean = JSON.parse(event);
    setCurrentAdvisor({ ...currentAdvisor, canBeBooked: canBeBooked });
    if (currentAdvisor.scheduleId !== undefined && currentAdvisor.scheduleId !== null) {
      updateAdvisorScheduleCanBeBookedMutation.mutate({ advisorId: currentAdvisor.id, canBeBooked: canBeBooked });
    } else {
      createAdvisorScheduleMutation.mutate({ advisorId: currentAdvisor.id, canBeBooked: canBeBooked, isGloballyAvailable: false });
    }
  }

  function handleGloballyAvailableChange(event: string) {
    if (!currentAdvisor) {
      throw new Error("No advisor selected");
    }

    const isGloballyAvailable: boolean = JSON.parse(event);
    setCurrentAdvisor({ ...currentAdvisor, isGloballyAvailable: isGloballyAvailable });
    if (currentAdvisor.scheduleId !== undefined && currentAdvisor.scheduleId !== null) {
      debouncedUpdateAdvisorScheduleMutation({
        advisorId: currentAdvisor.id, advisorSchedule: {
          ...currentAdvisor,
          isGloballyAvailable: isGloballyAvailable,
          advisorId: currentAdvisor.id
        }
      });
    }
  }

  function handleOnlyExplicitlyAvailableChange(event: string) {
    if (!currentAdvisor) {
      throw new Error("No advisor selected");
    }

    const isOnlyExplicitlyAvailable: boolean = JSON.parse(event);
    setCurrentAdvisor({ ...currentAdvisor, isOnlyExplicitlyAvailable: isOnlyExplicitlyAvailable });
    if (currentAdvisor.scheduleId !== undefined && currentAdvisor.scheduleId !== null) {
      debouncedUpdateAdvisorScheduleMutation({
        advisorId: currentAdvisor.id, advisorSchedule: {
          ...currentAdvisor,
          isOnlyExplicitlyAvailable: isOnlyExplicitlyAvailable,
          advisorId: currentAdvisor.id
        }
      });
    }

  }

  function handleWeekdayChange(weekday: Workday) {
    if (!currentAdvisor) {
      throw new Error("No advisor selected");
    }

    const workdays: Workday[] = [...currentAdvisor?.workdays];
    const idx = workdays.findIndex(x => x.dayOfWeek === weekday.dayOfWeek);
    const advisor: ScheduledAdvisor = {
      ...currentAdvisor, workdays: [
        ...currentAdvisor.workdays.slice(0, idx),
        weekday,
        ...currentAdvisor.workdays.slice(idx + 1)
      ]
    };
    setCurrentAdvisor(advisor);
    if (advisor.scheduleId !== undefined && advisor.scheduleId !== null) {
      debouncedUpdateAdvisorScheduleMutation({
        advisorId: advisor.id, advisorSchedule: {
          ...advisor,
          advisorId: advisor.id
        }
      });
    }
  }

  function handleApplyAvailability() {
    if (!currentAdvisor) {
      throw new Error("No advisor selected");
    }

    setIsSubmittingAdvisorSchedule(true);
    const scheduleToUpdateTo: AdvisorSchedule = {
      advisorId: currentAdvisor.id,
      ...currentAdvisor
    }

    updateAllAdvisorSchedules
      .mutateAsync({ advisorSchedule: scheduleToUpdateTo, location: selectedLocation })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmittingAdvisorSchedule(false);
        onClose();
      });
  }

  return (
    <VStack width={['100%', null, null, null, null, "70%"]} spacing="10">
      <HStack w="100%" spacing="8">
        <Box w="50%">
          <ContentWithHeading heading={t('availabilitypage.configure.location-select.label')}>
            <DataList placeholder={t('availabilitypage.configure.location-select.placeholder')} values={locationOptions} isMulti={false} handleOnChange={handleLocationChange} />
          </ContentWithHeading>
        </Box>
        <Box w="50%">
          <ContentWithHeading heading={t('availabilitypage.configure.employee-select.label')}>
            <DataList placeholder={t('availabilitypage.configure.employee-select.placeholder')} values={advisorOptions} isMulti={false} handleOnChange={handleAdvisorChange} />
          </ContentWithHeading>
        </Box>
      </HStack>
      {currentAdvisor ?
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Box>
            <ContentWithHeading heading={t('availabilitypage.configure.general.header')}>
              <HStack spacing="8" alignItems="flex-start" justifyContent="space-between">
                <ContentCard isFullWidth>
                  <FormControl as="fieldset">
                    <FormLabel as="legend" fontSize="18px">
                      {t('availabilitypage.configure.is-bookable.label')}
                    </FormLabel>
                    <RadioGroup value={`${currentAdvisor?.canBeBooked}`} isDisabled={currentAdvisor === undefined} onChange={handleBookableChange}>
                      <HStack spacing={10}>
                        <Radio value={`${true}`}>{t('availabilitypage.configure.radio.yes')}</Radio>
                        <Radio value={`${false}`}>{t('availabilitypage.configure.radio.no')}</Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </ContentCard>
                <ContentCard isFullWidth>
                  <FormControl as="fieldset">
                    <FormLabel as="legend" fontSize="18px">
                      {t('availabilitypage.configure.is-global.label')}
                    </FormLabel>
                    <RadioGroup value={`${currentAdvisor?.isGloballyAvailable}`} isDisabled={currentAdvisor === undefined || !currentAdvisor.canBeBooked} onChange={handleGloballyAvailableChange}>
                      <HStack spacing={10}>
                        <Radio value={`${true}`}>{t('availabilitypage.configure.radio.yes')}</Radio>
                        <Radio value={`${false}`}>{t('availabilitypage.configure.radio.no')}</Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </ContentCard>
                <ContentCard isFullWidth>
                  <FormControl as="fieldset">
                    <FormLabel as="legend" fontSize="18px">
                      {t('availabilitypage.configure.is-specific.label')}
                    </FormLabel>
                    <RadioGroup value={`${currentAdvisor?.isOnlyExplicitlyAvailable}`} isDisabled={currentAdvisor === undefined || !currentAdvisor.canBeBooked} onChange={handleOnlyExplicitlyAvailableChange} >
                      <HStack spacing={10}>
                        <Radio value={`${true}`}>{t('availabilitypage.configure.radio.yes')}</Radio>
                        <Radio value={`${false}`}>{t('availabilitypage.configure.radio.no')}</Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </ContentCard>
              </HStack>
            </ContentWithHeading>
          </Box>
          <Box>
            <ContentWithHeading heading={t('availabilitypage.configure.configure-availability.header')}>
              <VStack alignItems="start">
                <Text as="i" fontSize="14px">{t('availabilitypage.configure.configure-availability.description')}</Text>
                <ContentCard isFullWidth overflowX="auto">
                  <Grid gap="20px">
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Monday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Tuesday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Wednesday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Thursday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Friday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Saturday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                    <WeekdayCard handleWeekdayChange={handleWeekdayChange} advisor={currentAdvisor} dayOfWeek={WeekdayEnum.Sunday} meetingTypes={meetingTypeLabels} locations={locations} locationOptions={locationOptions} advisorLocation={currentAdvisor?.location} />
                  </Grid>
                </ContentCard>

              </VStack>
            </ContentWithHeading>
          </Box>
          {hasConfigurationAccess &&
            <Flex justifyContent="end">
              <Button size="lg" shadow="md" variant="bookMe" textColor="white" onClick={onOpen}>
                {t('availabilitypage.configure.copy-schedule.button')}
              </Button>
              <ConfirmationModal
                headerText={t('availabilitypage.configure.copy-schedule.modal-header')}
                onClose={onClose}
                isOpen={isOpen}
                onConfirm={handleApplyAvailability}
                isLoading={isSubmittingAdvisorSchedule}
              >
                <VStack align="start" spacing="4">
                  <HStack wrap="wrap">
                    <Text>{t('availabilitypage.configure.copy-schedule.modal-description1')}</Text>
                    <Text as='b'>{t('availabilitypage.configure.copy-schedule.modal-description2')}</Text>
                    <Text>{t('availabilitypage.configure.copy-schedule.modal-description3')}</Text>
                  </HStack>
                  <UnorderedList pl="8">
                    <ListItem pl="2"><Text>{affectedLocationText}</Text></ListItem>
                  </UnorderedList>
                  <Text>{affectedAdvisorsCountText}</Text>
                  <UnorderedList width="100%" maxH="400px" overflowY="auto" overflowX="hidden" pl={8}>
                    {affectedAdvisors?.map(adv => {
                      return <ListItem key={adv.id}>{adv.label}</ListItem>
                    })}
                  </UnorderedList>
                </VStack>
              </ConfirmationModal>
            </Flex>}
        </VStack>
        : (totalAdvisorCount && <AvailabilityOverview location={selectedLocation} totalAdvisorCount={totalAdvisorCount}></AvailabilityOverview>)}
    </VStack>
  );
}


interface AvailabilityOverviewProps {
  location: string | undefined;
  totalAdvisorCount: number;
}

export const AvailabilityOverview = ({ location, totalAdvisorCount }: AvailabilityOverviewProps) => {
  const { data: advisorsWithoutSchedule } = useGetAllAdvisorsWithoutSchedule();
  const { data: advisorsNonBookable } = useGetAllAdvisorsWithScheduleNonBookable();
  const { data: advisorsWithoutCustomerCategories } = useGetAllAdvisorsWithoutCustomerCategories();
  const { data: advisorsWithoutCompetences } = useGetAllAdvisorsWithoutCompetences();
  const { t } = useTranslation();

  const advisorsWithoutScheduleView = useMemo(() => {
    return location ? advisorsWithoutSchedule?.filter(x => x.location === location) : advisorsWithoutSchedule
  }, [location, advisorsWithoutSchedule]);
  const advisorsNonBookableView = useMemo(() => {
    return location ? advisorsNonBookable?.filter(x => x.location === location) : advisorsNonBookable
  }, [location, advisorsNonBookable]);
  const advisorsWithoutCustomerCategoriesView = useMemo(() => {
    return location ? advisorsWithoutCustomerCategories?.filter(x => x.location === location) : advisorsWithoutCustomerCategories
  }, [location, advisorsWithoutCustomerCategories]);
  const advisorsWithoutCompetencesView = useMemo(() => {
    return location ? advisorsWithoutCompetences?.filter(x => x.location === location) : advisorsWithoutCompetences
  }, [location, advisorsWithoutCompetences]);

  const withoutScheduleCount = useMemo(() => {
    return "(" + advisorsWithoutScheduleView?.length + "/" + totalAdvisorCount + ")";
  }, [advisorsWithoutScheduleView, totalAdvisorCount])

  const nonBookableCount = useMemo(() => {
    return "(" + advisorsNonBookableView?.length + "/" + totalAdvisorCount + ")";
  }, [advisorsNonBookableView, totalAdvisorCount])

  const advisorsWithoutCustomerCategoriesCount = useMemo(() => {
    return "(" + advisorsWithoutCustomerCategoriesView?.length + "/" + totalAdvisorCount + ")";
  }, [advisorsWithoutCustomerCategoriesView, totalAdvisorCount]);

  const advisorsWithoutCompetencesCount = useMemo(() => {
    return "(" + advisorsWithoutCompetencesView?.length + "/" + totalAdvisorCount + ")";
  }, [advisorsWithoutCompetencesView, totalAdvisorCount]);

  return (
    <VStack w="100%" spacing="8">
      <HStack w="100%" spacing="8" justifyContent="flex-start" alignItems="flex-start">
        {advisorsWithoutScheduleView &&
          <ConfigurationInformationCard
            advisorList={advisorsWithoutScheduleView}
            advisorCountText={withoutScheduleCount}
            allAdvisorsConfiguredText={t('availabilitypage.overview.all-employees-configured')}
            title={t('availabilitypage.overview.without-schedule.header')}
            subTitle={t('availabilitypage.overview.without-schedule.description')}
          />
        }
        {advisorsNonBookableView &&
          <ConfigurationInformationCard
            advisorList={advisorsNonBookableView}
            advisorCountText={nonBookableCount}
            allAdvisorsConfiguredText={t('availabilitypage.overview.all-employees-configured')}
            title={t('availabilitypage.overview.not-bookable.header')}
            subTitle={t('availabilitypage.overview.not-bookable.description')}
          />
        }
      </HStack>
      <HStack w="100%" spacing="8" justifyContent="flex-start" alignItems="flex-start">
        {advisorsWithoutCustomerCategoriesView &&
          <ConfigurationInformationCard
            advisorList={advisorsWithoutCustomerCategoriesView}
            advisorCountText={advisorsWithoutCustomerCategoriesCount}
            allAdvisorsConfiguredText={t('availabilitypage.overview.all-employees-configured')}
            title={t('availabilitypage.overview.without-categories.header')}
            subTitle={t('availabilitypage.overview.without-categories.description')}
          />
        }
        {advisorsWithoutCompetencesView &&
          <ConfigurationInformationCard
            advisorList={advisorsWithoutCompetencesView}
            advisorCountText={advisorsWithoutCompetencesCount}
            allAdvisorsConfiguredText={t('availabilitypage.overview.all-employees-configured')}
            title={t('availabilitypage.overview.without-themes.header')}
            subTitle={t('availabilitypage.overview.without-themes.description')}
          />
        }
      </HStack>
    </VStack>
  );
}

interface ConfigurationInformationCardProps {
  advisorList: Advisor[];
  advisorCountText: string;
  title: string;
  subTitle: string;
  allAdvisorsConfiguredText: string;
}


const ConfigurationInformationCard = ({ advisorList, advisorCountText, title, subTitle, allAdvisorsConfiguredText }: ConfigurationInformationCardProps) => {
  return (<Box w="50%">
    <ContentCard isFullWidth minH="200px">
      <VStack alignItems="start" spacing="8" justifyContent="space-evenly" h="100%">
        <VStack w="100%" justifyContent="start" alignItems="start">
          <HStack w="100%" justifyContent="space-between">
            <Heading size="md">{title}</Heading>
            <Heading size="md">{advisorCountText}</Heading>
          </HStack>
          <Text as="i" fontSize="14px">{subTitle}</Text>
        </VStack>
        {(advisorList !== undefined && advisorList.length > 0) ?
          <UnorderedList maxH="800px" overflowY="auto" overflowX="hidden" paddingLeft={8}>
            {advisorList.map(adv => {
              const nameString = `${adv.initials} - ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;
              return <ListItem key={adv.id}>{nameString}</ListItem>
            })}
          </UnorderedList>
          :
          <HStack justifyContent="start">
            <CheckMarkIcon height="24px" width="24px" />
            <Text noOfLines={2}>
              {allAdvisorsConfiguredText}
            </Text>
          </HStack>
        }
      </VStack>
    </ContentCard>
  </Box>);
}