/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceGroupMembership
 */
export interface ServiceGroupMembership {
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGroupMembership
     */
    skillBased?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGroupMembership
     */
    assigned?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGroupMembership
     */
    competenceGroupAdvisors?: Array<string> | null;
}

export function ServiceGroupMembershipFromJSON(json: any): ServiceGroupMembership {
    return ServiceGroupMembershipFromJSONTyped(json, false);
}

export function ServiceGroupMembershipFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceGroupMembership {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'skillBased': !exists(json, 'skillBased') ? undefined : json['skillBased'],
        'assigned': !exists(json, 'assigned') ? undefined : json['assigned'],
        'competenceGroupAdvisors': !exists(json, 'competenceGroupAdvisors') ? undefined : json['competenceGroupAdvisors'],
    };
}

export function ServiceGroupMembershipToJSON(value?: ServiceGroupMembership | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'skillBased': value.skillBased,
        'assigned': value.assigned,
        'competenceGroupAdvisors': value.competenceGroupAdvisors,
    };
}


