import { VStack, StackProps } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useGetCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { CompetenceGroupMembership as CompetenceGroupMembership } from "./CompetenceGroupMembership";
import { CompetenceInheritageInfo } from "./CompetenceInheritageInfo";
import { UpdateCompetenceChildren } from "./UpdateCompetenceChildren";
import { UpdateCompetenceGroup } from "./UpdateCompetenceGroup";
import { UpdateCompetences } from "./UpdateCompetences";


interface Props extends StackProps {
    competenceGroupId: string;
    handleBack: () => void;
    handleDelete: () => void;
    handleParentClicked: (id: string) => void;
}

export const CompetenceGroupOverview = ({competenceGroupId, handleBack, handleDelete, handleParentClicked}: Props) => {
    const { data: competenceGroup } = useGetCompetenceGroup(competenceGroupId);
    const overviewComponent = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (overviewComponent.current) {
            // scroll to top of ref
            overviewComponent.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [competenceGroupId]);

    if (competenceGroup === undefined) {
        return null;
    }

    return (
        <VStack ref={overviewComponent} key={competenceGroup.id} w="70%" spacing="4" align="stretch">
            <UpdateCompetenceGroup minH="200px" selectedCompetenceGroupInput={competenceGroup} backClicked={() => handleBack()} onCompetenceGroupDeleted={() => handleDelete()}></UpdateCompetenceGroup>
            <UpdateCompetences selectedCompetenceGroupInput={competenceGroup}></UpdateCompetences>
            <CompetenceGroupMembership selectedCompetenceGroupInput={competenceGroup}></CompetenceGroupMembership>
            <UpdateCompetenceChildren handleParentClicked={id => handleParentClicked(id)} selectedCompetenceGroupInput={competenceGroup}></UpdateCompetenceChildren>
            <CompetenceInheritageInfo selectedCompetenceGroupInput={competenceGroup}></CompetenceInheritageInfo>
        </VStack>
    );
}