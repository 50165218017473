/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BankOptions,
    BankOptionsFromJSON,
    BankOptionsToJSON,
    FeatureFlagsDto,
    FeatureFlagsDtoFromJSON,
    FeatureFlagsDtoToJSON,
    MeetingTypeBankOptionItem,
    MeetingTypeBankOptionItemFromJSON,
    MeetingTypeBankOptionItemToJSON,
    UpdateBankOptions,
    UpdateBankOptionsFromJSON,
    UpdateBankOptionsToJSON,
} from '../models';

export interface CreateClosingDaysRequest {
    id: string;
    requestBody?: Array<Date>;
}

export interface DeleteClosingDaysRequest {
    id: string;
    requestBody?: Array<Date>;
}

export interface UpdateBankOptionsRequest {
    updateBankOptions?: UpdateBankOptions;
}

/**
 * 
 */
export class BankOptionsApi extends runtime.BaseAPI {

    /**
     */
    async createClosingDaysRaw(requestParameters: CreateClosingDaysRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createClosingDays.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/BankOptions/{id}/closingDays`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createClosingDays(requestParameters: CreateClosingDaysRequest): Promise<void> {
        await this.createClosingDaysRaw(requestParameters);
    }

    /**
     */
    async deleteClosingDaysRaw(requestParameters: DeleteClosingDaysRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClosingDays.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/BankOptions/{id}/closingDays`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteClosingDays(requestParameters: DeleteClosingDaysRequest): Promise<void> {
        await this.deleteClosingDaysRaw(requestParameters);
    }

    /**
     */
    async getBankOptionsRaw(): Promise<runtime.ApiResponse<BankOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/BankOptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BankOptionsFromJSON(jsonValue));
    }

    /**
     */
    async getBankOptions(): Promise<BankOptions> {
        const response = await this.getBankOptionsRaw();
        return await response.value();
    }

    /**
     */
    async getFeatureFlagsRaw(): Promise<runtime.ApiResponse<FeatureFlagsDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/BankOptions/featureFlags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FeatureFlagsDtoFromJSON(jsonValue));
    }

    /**
     */
    async getFeatureFlags(): Promise<FeatureFlagsDto> {
        const response = await this.getFeatureFlagsRaw();
        return await response.value();
    }

    /**
     */
    async getMeetingTypeLabelsRaw(): Promise<runtime.ApiResponse<Array<MeetingTypeBankOptionItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/BankOptions/meetingTypeLabels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingTypeBankOptionItemFromJSON));
    }

    /**
     */
    async getMeetingTypeLabels(): Promise<Array<MeetingTypeBankOptionItem>> {
        const response = await this.getMeetingTypeLabelsRaw();
        return await response.value();
    }

    /**
     */
    async updateBankOptionsRaw(requestParameters: UpdateBankOptionsRequest): Promise<runtime.ApiResponse<BankOptions>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/BankOptions`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBankOptionsToJSON(requestParameters.updateBankOptions),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BankOptionsFromJSON(jsonValue));
    }

    /**
     */
    async updateBankOptions(requestParameters: UpdateBankOptionsRequest): Promise<BankOptions> {
        const response = await this.updateBankOptionsRaw(requestParameters);
        return await response.value();
    }

}
