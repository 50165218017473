import { useTranslation } from "react-i18next";
import { useUpdatePreProcessing } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { TimeDropdown } from "../../TimeDropdown";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const PreProcessingInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updatePreProcessingMutation = useUpdatePreProcessing();
    const { t } = useTranslation();

    function handlePreProcessingValueChanged(newValue: string, customerCategoryId?: string) {
        const currentPreProcessing = actualMeetingConfiguration!.preProcessings!.find(x => x.customerCategoryId === customerCategoryId);
        updatePreProcessingMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, meetingProcessing: { customerCategoryId, enabled: currentPreProcessing?.enabled || true, duration: newValue }})
    }

    function handlePreProcessingEnabledChanged(enabled: boolean, customerCategoryId?: string) {
        const currentPreProcessing = actualMeetingConfiguration!.preProcessings!.find(x => x.customerCategoryId === customerCategoryId);
        updatePreProcessingMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, meetingProcessing: { customerCategoryId, enabled, duration: currentPreProcessing?.duration || "00:00:00" }})
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.meeting-prep-time.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const preProcessing = actualMeetingConfiguration!.preProcessings!;
                const value = preProcessing.find(x => x.customerCategoryId === customerCategoryId);
                let isDisabled = false
                if (customerCategoryId === undefined && preProcessing.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }

                return (
                    <TimeDropdown label={t('meetingconfigurationpage.time-dropdown.label')}  isDisabled={isDisabled} timeResolution="minutes" hasYesNoRadio value={value?.duration || ""} yesNoValue={value?.enabled} 
                        onSelectChanged={(newValue) => handlePreProcessingValueChanged(newValue, customerCategoryId)}
                        onUserChoiceChanged={(enabled) => handlePreProcessingEnabledChanged(enabled, customerCategoryId)} />
                );
            }}
        </ConfigurationOptionItem>
    );
};