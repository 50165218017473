import { BoxProps, VStack, Box, Text, Input, HStack, Heading, Divider } from "@chakra-ui/react";
import { useMemo } from "react";
import { Advisor, ServiceGroup } from "../../api/client";
import { useGetAllAdvisors } from "../../api/useAdvisorApi";
import { useGetServiceGroupMembership, useUpdateServiceGroup } from "../../api/useServiceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard";
import { OptionValue } from "../DataList";
import { DisplayList } from "./DisplayList";
import { useGetAllCompetenceGroups } from "../../api/useCompetenceGroupApi";
import { ListView } from "../CompetenceGroup/ListView";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
  selectedServiceGroupInput: ServiceGroup;
}

export const UpdateServiceGroupMembership = ({ selectedServiceGroupInput, ...props }: Props) => {
  const updateServiceGroupMutation = useUpdateServiceGroup();
  const { t } = useTranslation();

  const selectedServiceGroup = useMemo(() => {
    return selectedServiceGroupInput;
  }, [selectedServiceGroupInput]);

  const { data: advisors } = useGetAllAdvisors();
  const { data: competenceGroups } = useGetAllCompetenceGroups();
  const { data: membership } = useGetServiceGroupMembership(selectedServiceGroup.id!);

  const advisorOptions: OptionValue[] = useMemo(() => {
    return advisors?.map(adv => {
      const label = `${(adv.initials ? `${adv.initials} -` : ``)} ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;

      return { value: adv.id, label: label }
    }) || [];
  }, [advisors]);

  const competenceGroupOptions: OptionValue[] = useMemo(() => {
    return competenceGroups?.map(cg => {
      return { value: cg.id!, label: cg.name! }
    }) || [];
  }, [competenceGroups]);

  const competenceGroupAdvisors = useMemo(() => {
    if (!membership) return [];

    // Map themes to include parent, sort by parent name and name, and map to items
    return membership?.competenceGroupAdvisors?.reduce<Advisor[]>((filtered, advId) => {
      const adv = advisors?.find(a => a.id === advId);
      if (adv) {
        filtered.push(adv)
      }
      return filtered;
    }, [])
      .sort((a, b) => {
        return (a.location && b.location) ? a.location!.localeCompare(b.location!) : a.displayName!.localeCompare(b.displayName!);
      }).map(adv => {
        const label = `${(adv.initials ? `${adv.initials} -` : ``)} ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;

        return { value: adv.id, label: label }
      }) || [];
  }, [membership, advisors]);


  function handleAdvisorsChanged(ids: string[]): void {
    const updatedGroup = {
      ...selectedServiceGroup,
      advisors: ids
    }

    updateServiceGroupMutation.mutate({
      id: selectedServiceGroup.id!, serviceGroup: updatedGroup
    });
  }

  function handleCompetenceMembershipChanged(ids: string[]): void {
    const updatedGroup = {
      ...selectedServiceGroup,
      competenceGroupIds: ids
    }

    updateServiceGroupMutation.mutate({
      id: selectedServiceGroup.id!, serviceGroup: updatedGroup
    });
  }

  function handleUpdateServiceGroupEmail(value: string): void {
    if (!value) {
      return;
    }
    updateServiceGroupMutation.mutate({
      id: selectedServiceGroup.id!, serviceGroup: {
        ...selectedServiceGroup,
        membershipEmail: value
      }
    });
  }

  return (
    <ContentCard {...props}>
      <VStack align="stretch" spacing="16">
        <VStack align="stretch" spacing="8">
          <VStack align="stretch" w="50%">
            <Heading as="h2" size="lg" fontWeight="semibold">{t('servicegrouppage.update-membership.heading')}</Heading>
            <Text color="gray.300" fontSize="xs">
              {t('servicegrouppage.update-membership.description')}
            </Text>
          </VStack>
          <HStack spacing="8" align="stretch" justify="stretch">
            <DisplayList w="100%" heading={t('servicegrouppage.update-membership.members-list.heading')} subheading={t('servicegrouppage.update-membership.members-list.subheading')}
              selectedItems={selectedServiceGroup.advisors || []} options={advisorOptions}
              placeholder={t('servicegrouppage.update-membership.members-list.placeholder')} handleOnChange={ids => handleAdvisorsChanged(ids)} />
            <DisplayList w="100%" heading={t('servicegrouppage.update-membership.competence-group-list.heading')} subheading={t('servicegrouppage.update-membership.competence-group-list.subheading')}
              selectedItems={selectedServiceGroup.competenceGroupIds || []} options={competenceGroupOptions}
              placeholder={t('servicegrouppage.update-membership.competence-group-list.placeholder')} handleOnChange={ids => handleCompetenceMembershipChanged(ids)} />
            <ListView w="100%" items={competenceGroupAdvisors} heading={t('servicegrouppage.update-membership.competence-group-members.heading')} />
          </HStack>
        </VStack>
        <Divider></Divider>
        <VStack align="stretch" spacing="8">
          <VStack align="start" w="50%">
            <Heading as="h3" size="md" fontWeight="semibold">{t('servicegrouppage.update-membership.email.heading')}</Heading>
            <Text color="gray.300" fontSize="xs">{t('servicegrouppage.update-membership.email.description')}</Text>
          </VStack>
          <HStack spacing="4" align="stretch" justify="stretch">
            <Box w="100%">
              <Text color="gray.300" fontSize="xs">{t('servicegrouppage.update-membership.email.input.label')}</Text>
              <Input type="email" placeholder={t('servicegrouppage.update-membership.email.input.placeholder')} size="lg" shadow="md" defaultValue={selectedServiceGroup.membershipEmail || ""} onChange={e => handleUpdateServiceGroupEmail(e.target.value)}
                borderRadius="0">
              </Input>
            </Box>
            <Box w="100%" />
            <Box w="100%" />
          </HStack>
        </VStack>
      </VStack>
    </ContentCard>);
}