import { Checkbox } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AdvisorsOfferedUpdateDTO } from "../../../api/client/models/AdvisorsOfferedUpdateDTO";
import { useUpdateAdvisorsOffered } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const AdvisorsOfferedInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updateAdvisorsOfferedMutation = useUpdateAdvisorsOffered();
    const { t } = useTranslation();

    async function handlePrimaryAdvisorChanged(checked: boolean, indeterminate: boolean, customerCategoryId?: string) {
        let advisorsOfferedUpdateDTO: AdvisorsOfferedUpdateDTO;

        if (!indeterminate && !checked && customerCategoryId) {
            advisorsOfferedUpdateDTO = { customerCategoryId: customerCategoryId, primaryAdvisor: checked, removeFromConfiguration: false };
        } else {
            advisorsOfferedUpdateDTO = { customerCategoryId: customerCategoryId, primaryAdvisor: checked, removeFromConfiguration: true };
        }
        
        await updateAdvisorsOfferedMutation.mutateAsync({ id: actualMeetingConfiguration!.id || undefined, advisorsOffered: advisorsOfferedUpdateDTO })
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.advisors-offered.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const availableAdvisors = actualMeetingConfiguration!.advisorsOffered!;
                const value = availableAdvisors.find(x => x.customerCategoryId === customerCategoryId);
                const isIndeterminate: boolean = !value;
                return (
                    <Checkbox size="lg" isChecked={!!value?.primaryAdvisor} isIndeterminate={isIndeterminate} onChange={e => handlePrimaryAdvisorChanged(e.target.checked, e.target.indeterminate, customerCategoryId)}>
                        {t('meetingconfigurationpage.advisors-offered.checkbox')}
                    </Checkbox>
                );
            }}
        </ConfigurationOptionItem>
    );
};