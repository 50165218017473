const getThursdayForDatesWeek = (date: Date) => {
    date = new Date(date)
    const dayNum = date.getUTCDay() || 7
    date.setUTCDate(date.getUTCDate() + 4 - dayNum)
    return date
}

export const getWeekNumberForDate = (date: Date) => {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    date = getThursdayForDatesWeek(date)
    const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
    return Math.ceil((((date.getTime() - yearStart.getTime()) / 86400000) + 1) / 7)
};

export const getYearForDatesWeek = (date: Date) => {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    date = getThursdayForDatesWeek(date)
    return date.getFullYear()
}

export const getNumWeeksForYear = (year: number) => {
    const dayInLastWeek = new Date(Date.UTC(year, 11, 28)) // Dec 28th is alway in last week of a year.
    return getWeekNumberForDate(dayInLastWeek)
}