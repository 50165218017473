/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdvisorsOffered,
    AdvisorsOfferedFromJSON,
    AdvisorsOfferedFromJSONTyped,
    AdvisorsOfferedToJSON,
    BookingBy,
    BookingByFromJSON,
    BookingByFromJSONTyped,
    BookingByToJSON,
    CalendarTimeFromBookingToMeetings,
    CalendarTimeFromBookingToMeetingsFromJSON,
    CalendarTimeFromBookingToMeetingsFromJSONTyped,
    CalendarTimeFromBookingToMeetingsToJSON,
    EnabledMeetingTypes,
    EnabledMeetingTypesFromJSON,
    EnabledMeetingTypesFromJSONTyped,
    EnabledMeetingTypesToJSON,
    MeetingDuration,
    MeetingDurationFromJSON,
    MeetingDurationFromJSONTyped,
    MeetingDurationToJSON,
    MeetingProcessing,
    MeetingProcessingFromJSON,
    MeetingProcessingFromJSONTyped,
    MeetingProcessingToJSON,
    TextToCustomer,
    TextToCustomerFromJSON,
    TextToCustomerFromJSONTyped,
    TextToCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface MeetingConfigurationDifference
 */
export interface MeetingConfigurationDifference {
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isBookingByDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isTextToCustomerDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isEnabledMeetingTypesDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isMeetingDurationsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isPreProcessingsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isPostProcessingsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isTravelTimeBuffersDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isCalendarTimeFromBookingToMeetingsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isWorkingTimeFromBookingToMeetingsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isTimeBetweenMeetingsDifferent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MeetingConfigurationDifference
     */
    isAdvisorsOfferedDifferent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MeetingConfigurationDifference
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MeetingConfigurationDifference
     */
    themeId?: string | null;
    /**
     * 
     * @type {Array<BookingBy>}
     * @memberof MeetingConfigurationDifference
     */
    bookingBy?: Array<BookingBy> | null;
    /**
     * 
     * @type {Array<TextToCustomer>}
     * @memberof MeetingConfigurationDifference
     */
    textToCustomer?: Array<TextToCustomer> | null;
    /**
     * 
     * @type {Array<EnabledMeetingTypes>}
     * @memberof MeetingConfigurationDifference
     */
    enabledMeetingTypes?: Array<EnabledMeetingTypes> | null;
    /**
     * 
     * @type {Array<MeetingDuration>}
     * @memberof MeetingConfigurationDifference
     */
    meetingDurations?: Array<MeetingDuration> | null;
    /**
     * 
     * @type {Array<MeetingProcessing>}
     * @memberof MeetingConfigurationDifference
     */
    preProcessings?: Array<MeetingProcessing> | null;
    /**
     * 
     * @type {Array<MeetingProcessing>}
     * @memberof MeetingConfigurationDifference
     */
    postProcessings?: Array<MeetingProcessing> | null;
    /**
     * 
     * @type {Array<MeetingProcessing>}
     * @memberof MeetingConfigurationDifference
     */
    travelTimeBuffers?: Array<MeetingProcessing> | null;
    /**
     * 
     * @type {Array<CalendarTimeFromBookingToMeetings>}
     * @memberof MeetingConfigurationDifference
     */
    calendarTimeFromBookingToMeetings?: Array<CalendarTimeFromBookingToMeetings> | null;
    /**
     * 
     * @type {Array<MeetingDuration>}
     * @memberof MeetingConfigurationDifference
     */
    workingTimeFromBookingToMeetings?: Array<MeetingDuration> | null;
    /**
     * 
     * @type {Array<MeetingDuration>}
     * @memberof MeetingConfigurationDifference
     */
    timeBetweenMeetings?: Array<MeetingDuration> | null;
    /**
     * 
     * @type {Array<AdvisorsOffered>}
     * @memberof MeetingConfigurationDifference
     */
    advisorsOffered?: Array<AdvisorsOffered> | null;
}

export function MeetingConfigurationDifferenceFromJSON(json: any): MeetingConfigurationDifference {
    return MeetingConfigurationDifferenceFromJSONTyped(json, false);
}

export function MeetingConfigurationDifferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingConfigurationDifference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBookingByDifferent': !exists(json, 'isBookingByDifferent') ? undefined : json['isBookingByDifferent'],
        'isTextToCustomerDifferent': !exists(json, 'isTextToCustomerDifferent') ? undefined : json['isTextToCustomerDifferent'],
        'isEnabledMeetingTypesDifferent': !exists(json, 'isEnabledMeetingTypesDifferent') ? undefined : json['isEnabledMeetingTypesDifferent'],
        'isMeetingDurationsDifferent': !exists(json, 'isMeetingDurationsDifferent') ? undefined : json['isMeetingDurationsDifferent'],
        'isPreProcessingsDifferent': !exists(json, 'isPreProcessingsDifferent') ? undefined : json['isPreProcessingsDifferent'],
        'isPostProcessingsDifferent': !exists(json, 'isPostProcessingsDifferent') ? undefined : json['isPostProcessingsDifferent'],
        'isTravelTimeBuffersDifferent': !exists(json, 'isTravelTimeBuffersDifferent') ? undefined : json['isTravelTimeBuffersDifferent'],
        'isCalendarTimeFromBookingToMeetingsDifferent': !exists(json, 'isCalendarTimeFromBookingToMeetingsDifferent') ? undefined : json['isCalendarTimeFromBookingToMeetingsDifferent'],
        'isWorkingTimeFromBookingToMeetingsDifferent': !exists(json, 'isWorkingTimeFromBookingToMeetingsDifferent') ? undefined : json['isWorkingTimeFromBookingToMeetingsDifferent'],
        'isTimeBetweenMeetingsDifferent': !exists(json, 'isTimeBetweenMeetingsDifferent') ? undefined : json['isTimeBetweenMeetingsDifferent'],
        'isAdvisorsOfferedDifferent': !exists(json, 'isAdvisorsOfferedDifferent') ? undefined : json['isAdvisorsOfferedDifferent'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'themeId': !exists(json, 'themeId') ? undefined : json['themeId'],
        'bookingBy': !exists(json, 'bookingBy') ? undefined : (json['bookingBy'] === null ? null : (json['bookingBy'] as Array<any>).map(BookingByFromJSON)),
        'textToCustomer': !exists(json, 'textToCustomer') ? undefined : (json['textToCustomer'] === null ? null : (json['textToCustomer'] as Array<any>).map(TextToCustomerFromJSON)),
        'enabledMeetingTypes': !exists(json, 'enabledMeetingTypes') ? undefined : (json['enabledMeetingTypes'] === null ? null : (json['enabledMeetingTypes'] as Array<any>).map(EnabledMeetingTypesFromJSON)),
        'meetingDurations': !exists(json, 'meetingDurations') ? undefined : (json['meetingDurations'] === null ? null : (json['meetingDurations'] as Array<any>).map(MeetingDurationFromJSON)),
        'preProcessings': !exists(json, 'preProcessings') ? undefined : (json['preProcessings'] === null ? null : (json['preProcessings'] as Array<any>).map(MeetingProcessingFromJSON)),
        'postProcessings': !exists(json, 'postProcessings') ? undefined : (json['postProcessings'] === null ? null : (json['postProcessings'] as Array<any>).map(MeetingProcessingFromJSON)),
        'travelTimeBuffers': !exists(json, 'travelTimeBuffers') ? undefined : (json['travelTimeBuffers'] === null ? null : (json['travelTimeBuffers'] as Array<any>).map(MeetingProcessingFromJSON)),
        'calendarTimeFromBookingToMeetings': !exists(json, 'calendarTimeFromBookingToMeetings') ? undefined : (json['calendarTimeFromBookingToMeetings'] === null ? null : (json['calendarTimeFromBookingToMeetings'] as Array<any>).map(CalendarTimeFromBookingToMeetingsFromJSON)),
        'workingTimeFromBookingToMeetings': !exists(json, 'workingTimeFromBookingToMeetings') ? undefined : (json['workingTimeFromBookingToMeetings'] === null ? null : (json['workingTimeFromBookingToMeetings'] as Array<any>).map(MeetingDurationFromJSON)),
        'timeBetweenMeetings': !exists(json, 'timeBetweenMeetings') ? undefined : (json['timeBetweenMeetings'] === null ? null : (json['timeBetweenMeetings'] as Array<any>).map(MeetingDurationFromJSON)),
        'advisorsOffered': !exists(json, 'advisorsOffered') ? undefined : (json['advisorsOffered'] === null ? null : (json['advisorsOffered'] as Array<any>).map(AdvisorsOfferedFromJSON)),
    };
}

export function MeetingConfigurationDifferenceToJSON(value?: MeetingConfigurationDifference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBookingByDifferent': value.isBookingByDifferent,
        'isTextToCustomerDifferent': value.isTextToCustomerDifferent,
        'isEnabledMeetingTypesDifferent': value.isEnabledMeetingTypesDifferent,
        'isMeetingDurationsDifferent': value.isMeetingDurationsDifferent,
        'isPreProcessingsDifferent': value.isPreProcessingsDifferent,
        'isPostProcessingsDifferent': value.isPostProcessingsDifferent,
        'isTravelTimeBuffersDifferent': value.isTravelTimeBuffersDifferent,
        'isCalendarTimeFromBookingToMeetingsDifferent': value.isCalendarTimeFromBookingToMeetingsDifferent,
        'isWorkingTimeFromBookingToMeetingsDifferent': value.isWorkingTimeFromBookingToMeetingsDifferent,
        'isTimeBetweenMeetingsDifferent': value.isTimeBetweenMeetingsDifferent,
        'isAdvisorsOfferedDifferent': value.isAdvisorsOfferedDifferent,
        'id': value.id,
        'themeId': value.themeId,
        'bookingBy': value.bookingBy === undefined ? undefined : (value.bookingBy === null ? null : (value.bookingBy as Array<any>).map(BookingByToJSON)),
        'textToCustomer': value.textToCustomer === undefined ? undefined : (value.textToCustomer === null ? null : (value.textToCustomer as Array<any>).map(TextToCustomerToJSON)),
        'enabledMeetingTypes': value.enabledMeetingTypes === undefined ? undefined : (value.enabledMeetingTypes === null ? null : (value.enabledMeetingTypes as Array<any>).map(EnabledMeetingTypesToJSON)),
        'meetingDurations': value.meetingDurations === undefined ? undefined : (value.meetingDurations === null ? null : (value.meetingDurations as Array<any>).map(MeetingDurationToJSON)),
        'preProcessings': value.preProcessings === undefined ? undefined : (value.preProcessings === null ? null : (value.preProcessings as Array<any>).map(MeetingProcessingToJSON)),
        'postProcessings': value.postProcessings === undefined ? undefined : (value.postProcessings === null ? null : (value.postProcessings as Array<any>).map(MeetingProcessingToJSON)),
        'travelTimeBuffers': value.travelTimeBuffers === undefined ? undefined : (value.travelTimeBuffers === null ? null : (value.travelTimeBuffers as Array<any>).map(MeetingProcessingToJSON)),
        'calendarTimeFromBookingToMeetings': value.calendarTimeFromBookingToMeetings === undefined ? undefined : (value.calendarTimeFromBookingToMeetings === null ? null : (value.calendarTimeFromBookingToMeetings as Array<any>).map(CalendarTimeFromBookingToMeetingsToJSON)),
        'workingTimeFromBookingToMeetings': value.workingTimeFromBookingToMeetings === undefined ? undefined : (value.workingTimeFromBookingToMeetings === null ? null : (value.workingTimeFromBookingToMeetings as Array<any>).map(MeetingDurationToJSON)),
        'timeBetweenMeetings': value.timeBetweenMeetings === undefined ? undefined : (value.timeBetweenMeetings === null ? null : (value.timeBetweenMeetings as Array<any>).map(MeetingDurationToJSON)),
        'advisorsOffered': value.advisorsOffered === undefined ? undefined : (value.advisorsOffered === null ? null : (value.advisorsOffered as Array<any>).map(AdvisorsOfferedToJSON)),
    };
}


