export const msalConfig = {
    auth: {
      clientId: (window as any).APP_RUNTIME_ENVIRONMENT.msal.auth.clientId,
      authority: "https://login.microsoftonline.com/organizations", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "/",
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
    scopes: ["User.Read"],
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };

  export const apiRequest = {
    scopes: [(window as any).APP_RUNTIME_ENVIRONMENT.msal.access_as_user_scope],
  }

  export const appRoles = {
    AMB_managementAccess: "AMB_managementAccess",
    AMB_configurationAccess: "AMB_configurationAccess"
}