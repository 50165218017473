import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { BookingBy, CalendarTimeFromBookingToMeetings, ConfigurationTypes, EnabledMeetingTypes, MeetingConfiguration, MeetingConfigurationApi, MeetingDuration, MeetingProcessing } from "./client"
import { AdvisorsOfferedUpdateDTO } from "./client/models/AdvisorsOfferedUpdateDTO";

export const useMeetingConfigurationApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [meetingConfigurationApi] = React.useState(
        () => new MeetingConfigurationApi(baseConfiguration)
    );
    return meetingConfigurationApi;
}

export const useGetMeetingConfigurations = (themeId?: string) => {
    const client = useMeetingConfigurationApi();
    return useQuery(["getMeetingConfigurations", themeId], () =>
        client.getMeetingConfigurations({ themeId }));
};

export const useGetMeetingConfigurationDifference = (themeId?: string) => {
    const client = useMeetingConfigurationApi();
    return useQuery(["getMeetingConfigurationDifference", themeId], () => {
        if (themeId === undefined) throw new Error("ThemeId is undefined");
        return client.getMeetingConfigurationDifference({ themeId });
    }, {
        enabled: themeId !== undefined
    });
};

export const useGetMeetingConfiguration = (id: string) => {
    const client = useMeetingConfigurationApi();
    return useQuery(["getMeetingConfiguration", id], () =>
        client.getMeetingConfiguration({ id }));
};

export const useGetActualMeetingConfiguration = (themeId?: string) => {
    const client = useMeetingConfigurationApi();
    return useQuery(["getActualMeetingConfiguration", themeId], () => {
        if (themeId === undefined) throw new Error("ThemeId is undefined");
        return client.getActualMeetingConfiguration({ themeId });
    }, {
        enabled: themeId !== undefined
    });
};

export const useUpdateBookingBy = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, bookingBy }: { id: string | undefined, bookingBy: BookingBy }) => client.updateBookingBy({ id, bookingBy }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateEnabledMeetingTypes = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, enabledMeetingTypes }: { id: string | undefined, enabledMeetingTypes: EnabledMeetingTypes }) => client.updateEnabledMeetingTypes({ id, enabledMeetingTypes }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateMeetingDuration = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, duration }: { id: string | undefined, duration: MeetingDuration }) => client.updateMeetingDuration({ id, meetingDuration: duration }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdatePreProcessing = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, meetingProcessing }: { id: string | undefined, meetingProcessing: MeetingProcessing }) => client.updatePreProcessing({ id, meetingProcessing }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdatePostProcessing = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, meetingProcessing }: { id: string | undefined, meetingProcessing: MeetingProcessing }) => client.updatePostProcessing({ id, meetingProcessing }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateTravelTimeBuffer = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, meetingProcessing }: { id: string | undefined, meetingProcessing: MeetingProcessing }) => client.updateTravelTimeBuffer({ id, meetingProcessing }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateCalendarTimeFromBookingToMeetings = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, calendarTimeFromBookingToMeeting }: { id: string | undefined, calendarTimeFromBookingToMeeting: CalendarTimeFromBookingToMeetings }) => 
        client.updateCalendarTimeFromBookingToMeetings({ id, calendarTimeFromBookingToMeetings: calendarTimeFromBookingToMeeting }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateWorkingTimeFromBookingToMeetings = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, duration }: { id: string | undefined, duration: MeetingDuration }) => 
        client.updateWorkingTimeFromBookingToMeetings({ id, meetingDuration: duration }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateTimeBetweenMeetings = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, duration }: { id: string | undefined, duration: MeetingDuration }) => client.updateTimeBetweenMeetings({ id, meetingDuration: duration }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useUpdateAdvisorsOffered = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, advisorsOffered }: { id: string | undefined, advisorsOffered: AdvisorsOfferedUpdateDTO }) => client.updateAdvisorsOffered({ id, advisorsOfferedUpdateDTO: advisorsOffered }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
};

export const useResetMeetingConfigurationType = () => {
    const client = useMeetingConfigurationApi();
    const invalidate = useInvalidateMeetingConfigurationQueries();
    return useMutation(({ id, configurationType }: { id: string, configurationType: ConfigurationTypes }) => client.resetConfigurationType({ id, configurationType }), {
        onSuccess: (data, input) => {
            invalidate(data, input.id);
        }
    });
}

export const useGetThemeIdsWithMeetingConfiguration = () => {
    const client = useMeetingConfigurationApi();
    return useQuery(["getThemeIdsWithMeetingConfiguration"], () =>
        client.getThemeIdsWithMeetingConfiguration());
}

function useInvalidateMeetingConfigurationQueries() {
    const queryClient = useQueryClient();

    return (data: MeetingConfiguration, id: string | undefined) => {
        if (data !== null) {
            queryClient.invalidateQueries(["getMeetingConfigurations", data.themeId]);
            if (data.themeId !== undefined) {
                queryClient.invalidateQueries(["getMeetingConfigurationDifference", data.themeId]);
                queryClient.invalidateQueries(["getActualMeetingConfiguration", data.themeId]);
            }
        }
        queryClient.invalidateQueries(["getMeetingConfiguration", id]);
        queryClient.invalidateQueries(["getThemeIdsWithMeetingConfiguration"]);
    };
};
