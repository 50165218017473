/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MeetingDuration
 */
export interface MeetingDuration {
    /**
     * 
     * @type {string}
     * @memberof MeetingDuration
     */
    duration: string;
    /**
     * 
     * @type {string}
     * @memberof MeetingDuration
     */
    customerCategoryId?: string | null;
}

export function MeetingDurationFromJSON(json: any): MeetingDuration {
    return MeetingDurationFromJSONTyped(json, false);
}

export function MeetingDurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingDuration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': json['duration'],
        'customerCategoryId': !exists(json, 'customerCategoryId') ? undefined : json['customerCategoryId'],
    };
}

export function MeetingDurationToJSON(value?: MeetingDuration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'customerCategoryId': value.customerCategoryId,
    };
}


