/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceLevel,
    ServiceLevelFromJSON,
    ServiceLevelFromJSONTyped,
    ServiceLevelToJSON,
    ServiceTrigger,
    ServiceTriggerFromJSON,
    ServiceTriggerFromJSONTyped,
    ServiceTriggerToJSON,
} from './';

/**
 * 
 * @export
 * @interface ServiceGroup
 */
export interface ServiceGroup {
    /**
     * 
     * @type {string}
     * @memberof ServiceGroup
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ServiceGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGroup
     */
    membershipEmail?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGroup
     */
    advisors?: Array<string> | null;
    /**
     * 
     * @type {ServiceTrigger}
     * @memberof ServiceGroup
     */
    serviceTrigger?: ServiceTrigger;
    /**
     * 
     * @type {ServiceLevel}
     * @memberof ServiceGroup
     */
    serviceLevel?: ServiceLevel;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceGroup
     */
    competenceGroupIds?: Array<string> | null;
}

export function ServiceGroupFromJSON(json: any): ServiceGroup {
    return ServiceGroupFromJSONTyped(json, false);
}

export function ServiceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ServiceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'membershipEmail': !exists(json, 'membershipEmail') ? undefined : json['membershipEmail'],
        'advisors': !exists(json, 'advisors') ? undefined : json['advisors'],
        'serviceTrigger': !exists(json, 'serviceTrigger') ? undefined : ServiceTriggerFromJSON(json['serviceTrigger']),
        'serviceLevel': !exists(json, 'serviceLevel') ? undefined : ServiceLevelFromJSON(json['serviceLevel']),
        'competenceGroupIds': !exists(json, 'competenceGroupIds') ? undefined : json['competenceGroupIds'],
    };
}

export function ServiceGroupToJSON(value?: ServiceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'membershipEmail': value.membershipEmail,
        'advisors': value.advisors,
        'serviceTrigger': ServiceTriggerToJSON(value.serviceTrigger),
        'serviceLevel': ServiceLevelToJSON(value.serviceLevel),
        'competenceGroupIds': value.competenceGroupIds,
    };
}


