/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankAdvisorOptions,
    BankAdvisorOptionsFromJSON,
    BankAdvisorOptionsFromJSONTyped,
    BankAdvisorOptionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface AdvisorBankOptionsItem
 */
export interface AdvisorBankOptionsItem {
    /**
     * 
     * @type {BankAdvisorOptions}
     * @memberof AdvisorBankOptionsItem
     */
    name?: BankAdvisorOptions;
    /**
     * 
     * @type {string}
     * @memberof AdvisorBankOptionsItem
     */
    label?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AdvisorBankOptionsItem
     */
    order?: number;
}

export function AdvisorBankOptionsItemFromJSON(json: any): AdvisorBankOptionsItem {
    return AdvisorBankOptionsItemFromJSONTyped(json, false);
}

export function AdvisorBankOptionsItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvisorBankOptionsItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : BankAdvisorOptionsFromJSON(json['name']),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function AdvisorBankOptionsItemToJSON(value?: AdvisorBankOptionsItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': BankAdvisorOptionsToJSON(value.name),
        'label': value.label,
        'order': value.order,
    };
}


