import { IconButtonProps } from "@chakra-ui/react"

export const available = {
    bg: 'available'
}
export const booked = {
    bg: 'customerMeeting'
}
export const busy = {
    bg: 'bookedInOwnCalendar'
}

export const availableFill = {
    fill: 'available'
}

export const ScrollButtonStyle: Omit<IconButtonProps, 'aria-label'> = {
    pos: 'absolute',
    color: 'white',
    bg: '#1A8CF9',
    top: '50%',
    rounded: 'none',
    _hover: {
        bg: '#1c7dd9'
    },
    _active: {
        bg: '#1A8CF9'
    }
}
