import { HStack, IconButton, ListItem, ListItemProps } from "@chakra-ui/react";
import { ChevronDown } from "../../icons/ChevronDown";
import { ChevronUp } from "../../icons/ChevronUp";
import { useState } from "react";


interface OrdereableListItemProps extends ListItemProps {
    handleMoveUpClicked?: () => void;
    handleMoveDownClicked?: () => void;
}

export const OrdereableListItem = ({handleMoveDownClicked, handleMoveUpClicked, children, ...props}:  React.PropsWithChildren<OrdereableListItemProps>) => {
    const [buttonIsVisible, setButtonIsVisible] = useState(false);
    
    return (
        <ListItem {...props} pl={2} >
            <HStack justifyContent="space-between" onMouseEnter={() => setButtonIsVisible(true)} 
                onMouseLeave={() => setButtonIsVisible(false)} _hover={{bgColor: "bookMeLightBlue"}} height="10">
                {children}
                <HStack hidden={!buttonIsVisible}>
                    <IconButton _focus={{ boxShadow: "0" }} bgColor="unset" icon={<ChevronUp height="18px" width="18px" />}
                        aria-label="move-up-button" onClick={handleMoveUpClicked ? () => handleMoveUpClicked() : undefined} />
                    <IconButton _focus={{ boxShadow: "0" }} bgColor="unset" icon={<ChevronDown height="18px" width="18px" />}
                        aria-label="move-down-button" onClick={handleMoveDownClicked ? () => handleMoveDownClicked() : undefined} />
                </HStack>
            </HStack>
        </ListItem>
    );
}