/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeetingTypeForCustomer,
    MeetingTypeForCustomerFromJSON,
    MeetingTypeForCustomerFromJSONTyped,
    MeetingTypeForCustomerToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnabledMeetingTypes
 */
export interface EnabledMeetingTypes {
    /**
     * 
     * @type {Array<MeetingTypeForCustomer>}
     * @memberof EnabledMeetingTypes
     */
    types: Array<MeetingTypeForCustomer>;
    /**
     * 
     * @type {string}
     * @memberof EnabledMeetingTypes
     */
    customerCategoryId?: string | null;
}

export function EnabledMeetingTypesFromJSON(json: any): EnabledMeetingTypes {
    return EnabledMeetingTypesFromJSONTyped(json, false);
}

export function EnabledMeetingTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnabledMeetingTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'types': ((json['types'] as Array<any>).map(MeetingTypeForCustomerFromJSON)),
        'customerCategoryId': !exists(json, 'customerCategoryId') ? undefined : json['customerCategoryId'],
    };
}

export function EnabledMeetingTypesToJSON(value?: EnabledMeetingTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'types': ((value.types as Array<any>).map(MeetingTypeForCustomerToJSON)),
        'customerCategoryId': value.customerCategoryId,
    };
}


