import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Select, { MultiValue, SingleValue } from "react-select";

interface DataListProps {
    values?: OptionValue[];
    handleOnChange: (options: OptionValue[]) => void;
    defaultSelectedOptions?: OptionValue[];
    placeholder: string;
    inputStyles?: any;
    isMulti?: boolean;
    closeMenuOnSelect?: boolean;
    setValueOnSelect?: boolean;
}

export interface OptionValue {
    value: string;
    label: string;
}

export const datalistStyles = {
    input: (provided: any, state: any) => ({
        ...provided,
        minHeight: 40
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        opacity: 0
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        border: 0,
        paddingLeft: 8,
        paddingRight: 8,
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1),0 4px 6px -2px rgba(0, 0, 0, 0.05)' // Same shadow as chakra-lg
    })
}


export const DataList = forwardRef(({ values, handleOnChange, defaultSelectedOptions, setValueOnSelect = true, placeholder, inputStyles, isMulti = false, closeMenuOnSelect = true }: DataListProps, ref) => {
    const [selectedOptions, setSelectedOptions] = useState<OptionValue[]>(defaultSelectedOptions || []);

    function handleOnChangeInner(newValue: SingleValue<OptionValue> | MultiValue<OptionValue>) {
        let optionValues: OptionValue[] = [];
        if (Array.isArray(newValue)) {
            optionValues = newValue as OptionValue[];
        } else {
            optionValues = [newValue as OptionValue];
        }
        if (setValueOnSelect) {
            setSelectedOptions(optionValues);
        }
        handleOnChange(optionValues);
    }

    useEffect(() => {
        setSelectedOptions(defaultSelectedOptions || []);
    }, [defaultSelectedOptions]);

    useImperativeHandle(ref, () => ({
        clearSelectedOptions: () => setSelectedOptions([])
    }));

    const styles = { ...datalistStyles, ...inputStyles };

    return (
        <Select isClearable={true} styles={styles} options={values} value={selectedOptions}
            isMulti={isMulti} placeholder={placeholder} onChange={handleOnChangeInner}
            closeMenuOnSelect={closeMenuOnSelect} />
    );
});