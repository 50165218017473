export class Time {
    private _hour: number = 0;
    private _minute: number = 0;

    get hour() {
        return this._hour;
    }
    get minute() {
        return this._minute;
    }
    set hour(hour: number) {
        if (hour < 0 || hour >= 24) {
            throw new Error("Hour needs to be between 0 and 23");
        }
        this._hour = hour;
    }
    set minute(minute: number) {
        if (minute < 0 || minute >= 60) {
            throw new Error("Minute needs to be between 0 and 59");
        }
        this._minute = minute;
    }

    constructor(hour?: number, minute?: number) {
        if (hour !== undefined) {
            this._hour = hour;
        }
        if (minute != undefined) {
            this._minute = minute;
        }
    }

    toString(): string {
        return `${this._hour.toString().padStart(2, "0")}:${this._minute.toString().padStart(2, "0")}`;
    }

    /**
     * Calculates the difference in time (hour and minute) of time and a date instance
     * @param date the date to compare with
     * @returns > 0 if date is after time, < 0 if date is before time
     */
    calculateTimeDiff(date: Date) {
        const timeInMinutes = this._hour * 60 + this._minute;
        const dateTimeInMinutes = date.getHours() * 60 + date.getMinutes();
        return dateTimeInMinutes - timeInMinutes;
    }

    static fromString(value: string): Time {
        const splitString = value.split(":");
        if (splitString.length > 3) throw new Error("Invalid time string");
        const hour = Number(splitString[0]);
        const minute = Number(splitString[1]);
        return new Time(hour, minute);
    }
}