import { Icon } from "@chakra-ui/react"
import { MdModeEdit } from "react-icons/md"

interface Props {
    height: string;
    width: string;
}

export const EditIcon = ({height, width}: Props) => {
    return <Icon height={height} width={width} fill="#1A8CF9" as={MdModeEdit}/>
}