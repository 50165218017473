import { BoxProps, Heading, HStack, VStack, Text } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompetenceGroup, Taxonomy } from "../../api/client";
import {  useGetCompetenceGroupInheritage } from "../../api/useCompetenceGroupApi";
import { useGetAllMeetingThemes } from "../../api/useMeetingThemeApi";
import { ContentCard } from "../ContentCard/ContentCard";
import { ListView } from "./ListView";

interface Props extends BoxProps {
    selectedCompetenceGroupInput: CompetenceGroup;
}

export const CompetenceInheritageInfo = ({ selectedCompetenceGroupInput, ...props }: Props) => {
    const { data: competenceInheritage } = useGetCompetenceGroupInheritage(selectedCompetenceGroupInput.id!);
    const { data: themes } = useGetAllMeetingThemes();
    const { t } = useTranslation();


    const customerCategoryItems = useMemo(() => {
        if (!competenceInheritage) return [];

        return competenceInheritage?.customerCategories?.map(x => { return { value: x.id!, label: x.name! } }) || [];
    }, [competenceInheritage]);

    const themeItems = useMemo(() => {
        if (!competenceInheritage) return [];

        // Map themes to include parent, sort by parent name and name, and map to items
        return competenceInheritage?.themes?.map(x => {
            const parent = themes?.find(t => t.children?.find(c => c.id === x.id));
            return { ...x, parent } as Taxonomy;
        }).sort((a, b) => {
            return (a.parent && b.parent) ? a.parent.name!.localeCompare(b.parent.name!) : a.name!.localeCompare(b.name!);
        }).map(x => {
            return { value: x.id!, label: `${x.name} ${(x.parent ? `(${x.parent.name!})` : ``)}` };
        }) || [];
    }, [competenceInheritage, themes]);

    if (!competenceInheritage) {
        return (<></>);
    }

    if (themeItems.length === 0 && customerCategoryItems.length === 0) {
        return (<></>);
    }

    return (
        <ContentCard {...props}>
            <VStack align="stretch" spacing="8">
                <VStack align="start">
                    <Heading as="h2" size="lg" fontWeight="semibold">{t('competencegrouppage.inheritage-info.heading')}</Heading>
                    <Text color="gray.300" fontSize="xs">{t('competencegrouppage.inheritage-info.description')}</Text>
                </VStack>
                <HStack spacing="8" align="stretch" justify="stretch" w="100%">
                    <ListView items={customerCategoryItems} heading={t('competencegrouppage.inheritage-info.customer-category-list.heading')} />
                    <ListView items={themeItems} heading={t('competencegrouppage.inheritage-info.meeting-theme-list.heading')} />
                </HStack>
            </VStack>
        </ContentCard>
    );
}



