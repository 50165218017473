import { BoxProps, Heading, HStack, VStack, Text, Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CompetenceGroup, CompetenceGroupInput } from "../../api/client";
import { useGetAllAdvisors } from "../../api/useAdvisorApi";
import { useGetCompetenceGroupInheritage, useUpdateCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard";
import { OptionValue } from "../DataList";
import { DisplayList } from "../ServiceGroup/DisplayList";
import { ListView } from "./ListView";

interface Props extends BoxProps {
  selectedCompetenceGroupInput: CompetenceGroup;
}

export const CompetenceGroupMembership = ({ selectedCompetenceGroupInput, ...props }: Props) => {
  const updateCompetenceGroupMutation = useUpdateCompetenceGroup();
  const { data: competenceInheritage } = useGetCompetenceGroupInheritage(selectedCompetenceGroupInput.id!);
  const { data: advisors } = useGetAllAdvisors();
  const { t } = useTranslation();


  const selectedCompetenceGroupView = useMemo(() => {
    return selectedCompetenceGroupInput;
  }, [selectedCompetenceGroupInput]);

  const advisorOptions: OptionValue[] = useMemo(() => {

    return advisors?.map(adv => {
      const label = `${(adv.initials ? `${adv.initials} -` : ``)} ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;
      return { value: adv.id, label: label }
    }) || [];
  }, [advisors]);

  const selectedCompetenceGroupUpdateDTO: CompetenceGroupInput = useMemo(() => {
    return {
      name: selectedCompetenceGroupInput.name,
      advisorIds: selectedCompetenceGroupInput.advisorIds,
      childGroupIds: selectedCompetenceGroupInput.childGroupIds,
      customerCategoryIds: selectedCompetenceGroupInput.customerCategories?.map(x => x.id!) || [],
      themeIds: selectedCompetenceGroupInput.themes?.map(x => x.id!) || []
    }
  }, [selectedCompetenceGroupInput]);

  const advisorItems = useMemo(() => {
    if (!advisors) return [];

    // Identify advisors that are inherited, sort by location, name and map to items
    return advisors.filter(x => competenceInheritage?.advisorIds?.includes(x.id))?.sort((a, b) => {
      return a.location?.localeCompare(b.location!) || a.displayName.localeCompare(b.displayName);
    }).map(adv => {
      const nameString = `${adv.initials} - ${adv.displayName} ${(adv.location ? `- ${adv.location}` : ``)}`;
      return { value: adv.id, label: nameString }
    }) || [];
  }, [advisors, competenceInheritage]);

  function handleAdvisorsChanged(ids: string[]): void {

    updateCompetenceGroupMutation.mutate({
      id: selectedCompetenceGroupView.id!,
      competenceGroup: {
        ...selectedCompetenceGroupUpdateDTO,
        advisorIds: ids
      }
    });
  }

  return (
    <ContentCard {...props}>
      <VStack align="stretch" spacing="8">
        <VStack align="start" w="50%">
          <Heading as="h2" size="lg" fontWeight="semibold">{t('competencegrouppage.update-members.heading')}</Heading>
          <Text color="gray.300" fontSize="xs">
            {t('competencegrouppage.update-members.description')}</Text>
        </VStack>
        <HStack spacing="8" align="stretch" justify="stretch" w="100%">
          <Box h="100%" w="50%">
            <DisplayList heading={t('competencegrouppage.update-members.members-list.heading')}
              subheading={t('competencegrouppage.update-members.members-list.subheading')}
              selectedItems={selectedCompetenceGroupView.advisorIds || []}
              options={advisorOptions} placeholder={t('competencegrouppage.update-members.members-list.placeholder')}
              handleOnChange={ids => handleAdvisorsChanged(ids)} />
          </Box>
          <Box h="100%" w="50%">
            <ListView items={advisorItems} heading={t('competencegrouppage.update-members.subgroup-members.heading')} />
          </Box>
        </HStack>
      </VStack>
    </ContentCard>
  );
}



