/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompetenceGroupInput
 */
export interface CompetenceGroupInput {
    /**
     * 
     * @type {string}
     * @memberof CompetenceGroupInput
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroupInput
     */
    childGroupIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroupInput
     */
    customerCategoryIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroupInput
     */
    advisorIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroupInput
     */
    themeIds?: Array<string> | null;
}

export function CompetenceGroupInputFromJSON(json: any): CompetenceGroupInput {
    return CompetenceGroupInputFromJSONTyped(json, false);
}

export function CompetenceGroupInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetenceGroupInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'childGroupIds': !exists(json, 'childGroupIds') ? undefined : json['childGroupIds'],
        'customerCategoryIds': !exists(json, 'customerCategoryIds') ? undefined : json['customerCategoryIds'],
        'advisorIds': !exists(json, 'advisorIds') ? undefined : json['advisorIds'],
        'themeIds': !exists(json, 'themeIds') ? undefined : json['themeIds'],
    };
}

export function CompetenceGroupInputToJSON(value?: CompetenceGroupInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'childGroupIds': value.childGroupIds,
        'customerCategoryIds': value.customerCategoryIds,
        'advisorIds': value.advisorIds,
        'themeIds': value.themeIds,
    };
}


