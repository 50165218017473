import {
    useStyleConfig,
    BoxProps,
    Box,
    Heading
} from "@chakra-ui/react";
import React from "react";
import { ContentCardConfigKey } from "./ContentCardConfig";

interface Props extends BoxProps {
    isFullWidth?: boolean;
    heading?: string;
    subHeading?: string;
}

export const ContentCard = ({ children, isFullWidth, heading, subHeading, ...props }: React.PropsWithChildren<Props>) => {
    const styles = useStyleConfig(ContentCardConfigKey, {});

    return (
        <Box __css={styles} w={isFullWidth ? "100%" : "initial"} {...props}>
            {heading && (<Heading as="h2" size="xl" fontWeight="semibold">{heading}</Heading>)}
            {subHeading && (<Heading mt="2" fontWeight="normal" as="h3" size="sm">{subHeading}</Heading>)}
            <Box mt={heading ? "8" : 0}>
                {children}
            </Box>
        </Box>
    );
};