import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { BankOptionsApi, UpdateBankOptions } from "./client";

export const useBankOptionsApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const advisorApi = React.useMemo(
        () => new BankOptionsApi(baseConfiguration), [baseConfiguration]
    );

    return advisorApi;
};

export const useGetBankOptions = () => {
    const client = useBankOptionsApi();
    return useQuery(["getBankOptions"], async () => {
        return client.getBankOptions()
    });
};

export const useGetMeetingTypeLabels = () => {
    const client = useBankOptionsApi();
    return useQuery(["getMeetingTypeLabels"], async () => {
        return client.getMeetingTypeLabels()
    });
};

export const useUpdateBankOptions = (onSuccess?: () => void) => {
    const client = useBankOptionsApi();
    const queryClient = useQueryClient();
    return useMutation(
        (updateBankOptions: UpdateBankOptions) =>
            client.updateBankOptions({ updateBankOptions: updateBankOptions }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getBankOptions"]);
                onSuccess?.();
            }
        }
    );
};

export const useCreateClosingDays = (onSuccess?: () => void) => {
    const client = useBankOptionsApi();
    const queryClient = useQueryClient();
    return useMutation(({ bankOptionsId, closingDays }: { bankOptionsId: string, closingDays: Date[] }) => client.createClosingDays({ id: bankOptionsId, requestBody: closingDays }), {
        onSuccess: () => {
            queryClient.invalidateQueries(["getBankOptions"]);
            onSuccess?.();
        },
        onError: () => {
            queryClient.invalidateQueries(["getBankOptions"]);
        }
    })
}

export const useDeleteClosingDays = (onSuccess?: () => void) => {
    const client = useBankOptionsApi();
    const queryClient = useQueryClient();
    return useMutation(({ bankOptionsId, closingDays }: { bankOptionsId: string, closingDays: Date[] }) => client.deleteClosingDays({ id: bankOptionsId, requestBody: closingDays }), {
        onSuccess: () => {
            queryClient.invalidateQueries(["getBankOptions"]);
            onSuccess?.();
        },
        onError: () => {
            queryClient.invalidateQueries(["getBankOptions"]);
        }
    })
}

export const useGetFeatureFlags = () => {
    const client = useBankOptionsApi();
    return useQuery(["getFeatureFlags"], async () => {
        return client.getFeatureFlags()
    });
};