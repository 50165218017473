import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { MeetingThemeApi, Taxonomy } from "./client"

export const useMeetingThemeApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [advisorApi] = React.useState(
        () => new MeetingThemeApi(baseConfiguration)
    );
    return advisorApi;
}

export const useGetAllMeetingThemes = () => {
    const client = useMeetingThemeApi();
    return useQuery(["getAllMeetingThemes"], () =>
        client.getAllMeetingThemes())
}

export const useUpdateMeetingTheme = () => {
    const client = useMeetingThemeApi();
    const queryClient = useQueryClient();
    return useMutation(
        (meetingTheme: Taxonomy) =>
            client.updateMeetingTheme({ taxonomy: meetingTheme}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllMeetingThemes"])
                }
            }
    );
};

export const useUpdateMeetingThemeOrdering = () => {
    const client = useMeetingThemeApi();
    const queryClient = useQueryClient();
    return useMutation(
        (meetingThemes: Taxonomy[]) =>
        client.updateMeetingThemeOrder({ taxonomy: meetingThemes}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllMeetingThemes"])
            }
        }
    )
}

export const useDeleteMeetingTheme = () => {
    const client = useMeetingThemeApi();
    const queryClient = useQueryClient();
    return useMutation(
        (meetingThemeId: string) =>
            client.deleteMeetingTheme({ id: meetingThemeId}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllMeetingThemes"])

                }
            }
    );
};

export const useCreateMeetingTheme = () => {
    const client = useMeetingThemeApi();
    const queryClient = useQueryClient();
    return useMutation(
        (meetingTheme: Taxonomy) =>
            client.createMeetingTheme({ taxonomy: meetingTheme}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllMeetingThemes"])
                }
            }
    );
};