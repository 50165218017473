import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/accordion";
import { Box, HStack, VStack, Text } from "@chakra-ui/layout";
import { useDisclosure } from "@chakra-ui/react";
import React, { useState } from "react";
import { UseMutationResult } from "react-query";
import { Taxonomy } from "../../api/client";
import { DeleteModal } from "./DeleteModal";
import { EditableRow } from "./EditableRow";

interface CustomAccordionItemProps {
    bgColor: string;
    taxonomy: Taxonomy;
    subText: string;
    disallowDeletionOfLastChild?: boolean;
    handleDeleteButtonClicked: (taxonomy: Taxonomy) => void;
    handleMoveUpClicked: (taxonomy: Taxonomy) => void;
    handleMoveDownClicked: (taxonomy: Taxonomy) => void;
    useUpdateTaxonomyMutation: UseMutationResult<Taxonomy, unknown, Taxonomy, unknown>;
    disallowReordering?: boolean;
}

const CustomAccordionItem = ({taxonomy, bgColor, subText, handleDeleteButtonClicked, handleMoveUpClicked, handleMoveDownClicked, useUpdateTaxonomyMutation, disallowDeletionOfLastChild, disallowReordering }: CustomAccordionItemProps) => {
    return (
        <AccordionItem key={`${taxonomy?.name}`} py="5" >
            <HStack>
                {taxonomy.children ?
                <AccordionButton width="unset" _focus={{boxShadow: "0"}} _hover={{bgColor: "bookMeLightBlue"}}>
                    <AccordionIcon />
                </AccordionButton>
                : null }
                <Box flex="1" textAlign="left" fontSize="sm"
                    _hover={{bgColor: "bookMeLightBlue"}} bgColor={bgColor} tabIndex={0}>
                    <EditableRow useUpdateTaxonomyMutation={useUpdateTaxonomyMutation} taxonomyFontSize="18px"
                         handleDeleteButtonClicked={handleDeleteButtonClicked} handleMoveUpClicked={handleMoveUpClicked}
                         handleMoveDownClicked={handleMoveDownClicked} taxonomy={taxonomy} />
                </Box>
            </HStack>
            <AccordionPanel>
                <VStack pl="16" alignItems="stretch">
                        <Text color="gray.300" fontSize="xs">{subText}</Text>
                        <Box>
                            {(!taxonomy.children || taxonomy.children.length === 0) ? (
                                <Text as="em" fontSize="xs">Ingen {subText.toLowerCase()} oprettet</Text>
                            ) : taxonomy.children?.map((subTaxonomy) => {
                                return (<EditableRow key={`popover-${subTaxonomy.id}`} useUpdateTaxonomyMutation={useUpdateTaxonomyMutation} taxonomyFontSize="14px"
                                     handleDeleteButtonClicked={handleDeleteButtonClicked} handleMoveUpClicked={handleMoveUpClicked} handleMoveDownClicked={handleMoveDownClicked}
                                     taxonomy={subTaxonomy} disallowDelete={(disallowDeletionOfLastChild && taxonomy.children?.length === 1) || false} 
                                     disallowReordering={disallowReordering}/>
                                );
                            })}
                        </Box>
                </VStack>
            </AccordionPanel>
        </AccordionItem>
    );
};

interface AccordionViewProps {
    currentTaxonomy?: Taxonomy;
    taxonomies?: Taxonomy[];
    disallowDeletionOfLastChild?: boolean;
    subText: string;
    useUpdateTaxonomyMutation: UseMutationResult<Taxonomy, unknown, Taxonomy, unknown>;
    useDeleteTaxonomyMutation: UseMutationResult<unknown, unknown, string, unknown>;
    disallowReordering?: boolean;
    handleMoveUpClicked: (taxonomy: Taxonomy) => void;
    handleMoveDownClicked: (taxonomy: Taxonomy) => void;
}

export const AccordionView = ({currentTaxonomy, taxonomies, subText, 
    useDeleteTaxonomyMutation, useUpdateTaxonomyMutation, disallowDeletionOfLastChild, disallowReordering,
    handleMoveUpClicked, handleMoveDownClicked}: AccordionViewProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [dataPointToDelete, setDataPointToDelete] = useState<Taxonomy>();
    

    function handleDeleteButtonClicked(dataPoint: Taxonomy) {
        onOpen();
        setDataPointToDelete(dataPoint);
    }

    function onDeleteConfirm() {
        if(dataPointToDelete && dataPointToDelete.id) {
            if (dataPointToDelete.parent?.children?.length === 1 && disallowDeletionOfLastChild) return;
            useDeleteTaxonomyMutation.mutate(dataPointToDelete.id);
            onClose();
        }
    }

    return (
        <VStack spacing={5} alignItems="stretch">
            {taxonomies ? <Accordion allowToggle >
                {taxonomies?.map((taxonomy) => {
                    const bgColor = currentTaxonomy === taxonomy ? "gray.100" : "unset";
                    return ( 
                        <CustomAccordionItem key={`accordion-item-${taxonomy.id}`} useUpdateTaxonomyMutation={useUpdateTaxonomyMutation} taxonomy={taxonomy} 
                            subText={subText} bgColor={bgColor} handleDeleteButtonClicked={handleDeleteButtonClicked}
                            handleMoveUpClicked={handleMoveUpClicked}
                            handleMoveDownClicked={handleMoveDownClicked}
                            disallowDeletionOfLastChild={disallowDeletionOfLastChild}
                            disallowReordering={disallowReordering} />
                    );
                })}
            </Accordion> : null}
            <DeleteModal isOpen={isOpen} itemName={dataPointToDelete?.name || ""} onClose={onClose} onConfirm={onDeleteConfirm}>
                Er du sikker på at du vil slette "{dataPointToDelete?.name}"{dataPointToDelete?.parent !== undefined ? " samt dets underområder" : ""}?
            </DeleteModal>
        </VStack>
    );
};