import { VStack, Heading, Text, HStack, BoxProps } from "@chakra-ui/react"
import { useMemo } from "react";
import { CompetenceGroup, CompetenceGroupInput, Taxonomy } from "../../api/client";
import { useGetAllCustomerCategories } from "../../api/useCustomerCategoriesApi";
import { useGetAllMeetingThemes } from "../../api/useMeetingThemeApi";
import { useUpdateCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard"
import { OptionValue } from "../DataList";
import { DisplayList } from "../ServiceGroup/DisplayList";
import { useTranslation } from "react-i18next";

interface Props extends BoxProps {
    selectedCompetenceGroupInput: CompetenceGroup;
}

export const UpdateCompetences = ({ selectedCompetenceGroupInput, ...props }: Props) => {
    const { data: customerCategories } = useGetAllCustomerCategories();
    const { data: themes } = useGetAllMeetingThemes();
    const updateCompetenceGroupMutation = useUpdateCompetenceGroup();
    const { t } = useTranslation();

    const customerCategoryOptions: OptionValue[] = useMemo(() => {
        return customerCategories?.map(c => { return { value: c.id!, label: c.name! } }) || [];
    }, [customerCategories]);

    const themeOptions: OptionValue[] = useMemo(() => {
        if (!themes) return [];

        // Get all child themes and add parent name to label
        const childThemes: Taxonomy[] = themes.filter(t => t.children?.length !== 0).flatMap(t => {
            const children: Taxonomy[] = t.children!.map(c => {
                return { ...c, parent: t }
            });
            return children;
        });

        return childThemes?.map(t => { return { value: t.id!, label: `${t.name!} (${t.parent?.name!})` } }) || [];
    }, [themes]);

    const selectedCompetenceGroupView = useMemo(() => {
        return selectedCompetenceGroupInput;
    }, [selectedCompetenceGroupInput]);

    const selectedCompetenceGroupUpdateDTO: CompetenceGroupInput = useMemo(() => {
        return {
            name: selectedCompetenceGroupInput.name,
            advisorIds: selectedCompetenceGroupInput.advisorIds,
            childGroupIds: selectedCompetenceGroupInput.childGroupIds,
            customerCategoryIds: selectedCompetenceGroupInput.customerCategories?.map(x => x.id!) || [],
            themeIds: selectedCompetenceGroupInput.themes?.map(x => x.id!) || []
        }
    }, [selectedCompetenceGroupInput]);

    if (!selectedCompetenceGroupView) {
        return (<></>);
    }

    function handleThemesChanged(ids: string[]): void {
        if (!selectedCompetenceGroupUpdateDTO) {
            return;
        }

        updateCompetenceGroupMutation.mutate({
            id: selectedCompetenceGroupInput.id!,
            competenceGroup: {
                ...selectedCompetenceGroupUpdateDTO,
                themeIds: ids
            }
        });
    }

    function handleCategoriesChanged(ids: string[]): void {
        if (!selectedCompetenceGroupUpdateDTO) {
            return;
        }

        updateCompetenceGroupMutation.mutate({
            id: selectedCompetenceGroupInput.id!,
            competenceGroup: {
                ...selectedCompetenceGroupUpdateDTO,
                customerCategoryIds: ids
            }
        });
    }

    return (
        <ContentCard {...props}>
            <VStack align="start" spacing="8">
                <VStack align="start" w="50%">
                    <Heading as="h2" size="lg" fontWeight="semibold">{t('competencegrouppage.update-competences.heading')}</Heading>
                    <Text color="gray.300" fontSize="xs">{t('competencegrouppage.update-competences.description')}
                    </Text>
                </VStack>
                <HStack spacing="8" align="stretch" justify="stretch" w="100%">
                    <DisplayList heading={t('competencegrouppage.update-competences.customer-category-list.heading')} selectedItems={selectedCompetenceGroupView.customerCategories?.map(x => x.id!) || []} options={customerCategoryOptions} placeholder={t('competencegrouppage.update-competences.customer-category-list.placeholder')} handleOnChange={ids => handleCategoriesChanged(ids)} />
                    <DisplayList heading={t('competencegrouppage.update-competences.meeting-theme-list.heading')} selectedItems={selectedCompetenceGroupView.themes?.map(x => x.id!) || []} options={themeOptions} placeholder={t('competencegrouppage.update-competences.meeting-theme-list.placeholder')} handleOnChange={ids => handleThemesChanged(ids)} />
                </HStack>
            </VStack>
        </ContentCard>
    )
}