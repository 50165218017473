export const BASE_URL = (window as any).BASE_URL as string;
export const APPINSIGHTS_INSTRUMENTATIONKEY = (window as any)
  .APPINSIGHTS_INSTRUMENTATIONKEY as string;
export const USE_FALLBACK_LANGUAGE = process.env.REACT_APP_USE_FALLBACK_LANGUAGE === 'true';

export const APP_RUNTIME_ENVIRONMENT = {
  msal: {
    auth: {
      clientId: '33b3d543-9b3f-4b8b-80bd-3601a85ce71a'
    },
    access_as_user_scope: 'api://e2d16eb4-af66-4603-87d7-d9c2c6d0902f/access_as_user'
  }
};