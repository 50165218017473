import {
    ComponentSingleStyleConfig
} from "@chakra-ui/react";

export const ColumnLayoutConfigKey = "TwoColumnLayout";

export const ColumnLayoutConfig: ComponentSingleStyleConfig = {
    baseStyle: {
        justifyContent: "space-between",
        width: "100%", 
        minHeight: "100%",
        alignItems: "start"
    }
};