/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TextToCustomer
 */
export interface TextToCustomer {
    /**
     * 
     * @type {string}
     * @memberof TextToCustomer
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof TextToCustomer
     */
    customerCategoryId?: string | null;
}

export function TextToCustomerFromJSON(json: any): TextToCustomer {
    return TextToCustomerFromJSONTyped(json, false);
}

export function TextToCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): TextToCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'customerCategoryId': !exists(json, 'customerCategoryId') ? undefined : json['customerCategoryId'],
    };
}

export function TextToCustomerToJSON(value?: TextToCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'customerCategoryId': value.customerCategoryId,
    };
}


