import { AccountInfo } from "@azure/msal-common";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useTokenContext } from "../TokenContext";
import { HStack, VStack, Text } from "@chakra-ui/react";

interface Props {
    account: AccountInfo | null;
}
// Test page to show data
export const HelloUserPage = ({ account }: Props) => {
    const token = useTokenContext();
    const [userRoles, setUserRoles] = useState<string[]>()

    useEffect(() => {
        if(token) {
            const decodedToken: { [key: string]: any } = jwtDecode(token);
            setUserRoles(decodedToken['roles']);
        }
    }, [token])

    return (
            <VStack justifyContent="start" alignItems="start">
                <Text>Du er nu logget ind som:</Text>
                <HStack><Text textStyle='h1'>Navn:</Text><Text>{account?.name}</Text></HStack>
                <HStack><Text textStyle='h1'>Brugernavn:</Text><Text>{account?.username}</Text></HStack>
                {!userRoles && <Text textStyle='h1' paddingTop="2em">Denne bruger er endnu ikke blevet tildelt en rolle, og kan derfor ikke tilgå indholdet</Text> }
            </VStack>
    );
}