import { VStack, Text, HStack, Box } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateButton } from './Buttons/CreateButton';

interface DateSelectorProps {
    onDateAdded: (date: Date | null) => void;
    header?: string;
}

export const DateSelector = ({ onDateAdded, header }: DateSelectorProps) => {
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();
    const { t } = useTranslation();


    const inputValue = useMemo(() => {
        if (selectedDate) {
            return selectedDate.toISOString().split('T')[0];
        }
        return '';
    }, [selectedDate]);

    const now = new Date().toISOString().split('T')[0];

    function handleSelectDate() {
        if (selectedDate && selectedDate.toISOString().split('T')[0] > now) {
            onDateAdded(selectedDate);
            setSelectedDate(undefined);
        }
    }

    return (
        <VStack alignItems="stretch" justifyContent="center" spacing="0">
            {header && <Text fontSize="18px" fontWeight="bold">{header} </Text>}
            <HStack justifyContent="space-between">
                <input type="date" onChange={(e) => setSelectedDate(new Date(e.target.value))} min={now} value={inputValue} />
                <CreateButton size="md" text={t('create-button.label')} onClick={handleSelectDate} />
            </HStack>
        </VStack>
    );
};