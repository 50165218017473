import { FormControl, FormLabel, HStack, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Radio, RadioGroup, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateCalendarTimeFromBookingToMeetings } from "../../../api/useMeetingConfigurationApi";
import { useDebounce } from "../../../helpers/debounce";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";
import { durationHoursToTimeSpan } from "../../../helpers/durationHelper";

interface CalendarTimeFromBookingToMeetingsValues {
    customerCategory: string | undefined;
    duration: number | undefined;
    excludeWeekend: boolean | undefined;
}

export const CalendarTimeFromBookingToMeetingInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updateCalendarTimeFromBookingToMeetingMutation = useUpdateCalendarTimeFromBookingToMeetings();
    const debouncedUpdateCalendarTimeFromBookingToMeetingMutation = useDebounce(updateCalendarTimeFromBookingToMeetingMutation.mutate, 500);
    const [customerCategoryValues, setCustomerCategoryValues] = useState<CalendarTimeFromBookingToMeetingsValues[]>();
    const { t } = useTranslation();

    useEffect(() => {
        if (customerCategories && actualMeetingConfiguration) {
            const calendarTimeFromBookingToMeetings = actualMeetingConfiguration!.calendarTimeFromBookingToMeetings!;
            // Make array of category ids and undefined (the "id" for the general configuration all customer categories)
            const categoryIds = Array.from(customerCategories.map(x => x.id)).concat([undefined]);
            const value: CalendarTimeFromBookingToMeetingsValues[] = categoryIds.map(customerCategoryId => {
                const customerCategoryValue = calendarTimeFromBookingToMeetings.find(x => x.customerCategoryId === customerCategoryId);
                // format of duration is either 'd.hh:mm:ss', 'hh:mm:ss'
                const daysAndHours = customerCategoryValue?.duration.split(':')[0];
                let hours: number | undefined = undefined;
                if (daysAndHours) {
                    if (daysAndHours.includes('.')) {
                        hours = (+daysAndHours.split('.')[0]) * 24;
                        hours = hours + +daysAndHours.split('.')[1];
                    } else {
                        hours = +daysAndHours;
                    }
                }
                return { customerCategory: customerCategoryId ? customerCategoryId : undefined, duration: hours, excludeWeekend: customerCategoryValue?.excludeWeekend }
            });
            setCustomerCategoryValues(value);
        }
    }, [customerCategories, actualMeetingConfiguration])



    function handleDurationChanged(newValue: number, customerCategoryId?: string) {
        if (Number.isNaN(newValue)) {
            // should reset the customercategory duration
            newValue = 0;
        }
        const index = customerCategoryValues?.findIndex(x => x.customerCategory === customerCategoryId);
        if (customerCategoryValues && (index !== undefined && index !== -1)) {
            const customerCategoryValue = customerCategoryValues[index];
            setCustomerCategoryValues([...customerCategoryValues.slice(0, index), { customerCategory: customerCategoryId, duration: newValue, excludeWeekend: customerCategoryValue.excludeWeekend }, ...customerCategoryValues.slice(index + 1)]);
            const durationAsTimeSpan = durationHoursToTimeSpan(customerCategoryValue.duration!);
            debouncedUpdateCalendarTimeFromBookingToMeetingMutation({ id: actualMeetingConfiguration!.id || undefined, calendarTimeFromBookingToMeeting: { customerCategoryId, duration: durationAsTimeSpan, excludeWeekend: customerCategoryValue?.excludeWeekend! } });
        }
    }

    function handleExcludeWeekendChanged(newValue: boolean, customerCategoryId?: string) {
        const index = customerCategoryValues?.findIndex(x => x.customerCategory === customerCategoryId);
        if (customerCategoryValues && (index !== undefined && index !== -1)) {
            const customerCategoryValue = customerCategoryValues[index];
            setCustomerCategoryValues([...customerCategoryValues.slice(0, index), { customerCategory: customerCategoryId, excludeWeekend: newValue, duration: customerCategoryValue.duration }, ...customerCategoryValues.slice(index + 1)]);
            const durationAsTimeSpan = durationHoursToTimeSpan(customerCategoryValue.duration!);
            debouncedUpdateCalendarTimeFromBookingToMeetingMutation({ id: actualMeetingConfiguration!.id || undefined, calendarTimeFromBookingToMeeting: { customerCategoryId, duration: durationAsTimeSpan, excludeWeekend: newValue } });
        }
    }


    if (!customerCategoryValues) {
        return <></>;
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.time-from-booking-to-meeting.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const customerCategoryValue = customerCategoryValues?.find(x => x.customerCategory === customerCategoryId);
                const durationValue = customerCategoryValue?.duration;
                const excludeWeekendValue = customerCategoryValue?.excludeWeekend;
                const calendarTimeFromBookingToMeetings = actualMeetingConfiguration!.calendarTimeFromBookingToMeetings!;
                let isDisabled = false
                if (customerCategoryId === undefined && calendarTimeFromBookingToMeetings.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }
                return (
                    <HStack justifyContent="space-between" spacing="5em">
                        <HStack>
                            <NumberInput size="md" maxWidth="10em" value={durationValue === undefined ? "" : durationValue} min={0} max={999}
                                onChange={(_, newValue) => handleDurationChanged(newValue, customerCategoryId)}
                                isDisabled={isDisabled}>
                                <NumberInputField />
                                <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                </NumberInputStepper>
                            </NumberInput>
                            {durationValue === undefined ?
                                <Text color="gray.200">
                                    {t('meetingconfigurationpage.time-from-booking-to-meeting.hours')}
                                </Text> :
                                <Text>
                                    {t('meetingconfigurationpage.time-from-booking-to-meeting.hours')}
                                </Text>}
                        </HStack>
                        <FormControl width="fit-content">
                            <RadioGroup isDisabled={isDisabled} value={excludeWeekendValue ? "true" : "false"} onChange={value => handleExcludeWeekendChanged?.(value.toLowerCase() === "true", customerCategoryId)}>
                                <HStack>
                                    <Radio value="true">{t('meetingconfigurationpage.exclude-weekend.label')}</Radio>
                                    <Radio value="false">{t('meetingconfigurationpage.inklude-weekend.label')}</Radio>
                                </HStack>
                            </RadioGroup>
                        </FormControl>
                    </HStack>
                );
            }}
        </ConfigurationOptionItem>
    );
};