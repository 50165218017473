import React from "react";
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    HStack,
    VStack,
    Text
} from "@chakra-ui/react";
import { Taxonomy } from "../../api/client";
import { ArrowRightIcon } from "../../icons/ArrowRightIcon";
import { useGetThemeIdsWithMeetingConfiguration } from "../../api/useMeetingConfigurationApi";
import { useTranslation } from "react-i18next";

const hoverStyle = {bgColor: "blue.100", borderLeftColor: "blue.500", cursor: "pointer"};
const chosenStyle = {...hoverStyle};

interface Props {
    meetingThemes: Taxonomy[];
    chosenMeetingTheme: Taxonomy | null;
    onThemeChosen?: (theme: Taxonomy | null) => void;
}

export const MeetingConfigurationThemeNavigation = ({ onThemeChosen, chosenMeetingTheme, meetingThemes }: Props) => {
    const { data: themeIdsWithMeetingConfiguration } = useGetThemeIdsWithMeetingConfiguration();
    const { t } = useTranslation();

    if (themeIdsWithMeetingConfiguration === undefined) return null;

    return (
        <>
            <Box height="3em" _hover={hoverStyle}  display="flex" alignItems="center"
                {...(chosenMeetingTheme === null ? {...chosenStyle} : {})}
            onClick={() => onThemeChosen?.(null)}>
                <HStack justifyContent="space-between" width="100%" paddingRight="1em">
                    <Text paddingLeft="3em"> {t('meetingconfigurationpage.navigation.general')} </Text>
                    {chosenMeetingTheme === null ? <ArrowRightIcon width="24px" height="24px" color="blue" /> : null}
                </HStack>
            </Box>
            <Accordion allowToggle allowMultiple borderBottomColor="white">
                <AccordionItem borderWidth="0" >
                    <AccordionButton _focus={{boxShadow: ""}} _hover={hoverStyle} minHeight="3em">
                        <AccordionIcon marginRight="0.5em"/>
                        <Box flex="1" textAlign="left">
                            <Text>
                                {t('meetingconfigurationpage.navigation.theme-specific')}
                            </Text>
                        </Box>
                    </AccordionButton >
                    <AccordionPanel pl="2em" pr="0">
                        <Box borderLeftWidth="thin" borderLeftColor="gray.200">
                            <Accordion allowToggle allowMultiple borderBottomColor="white">
                                {meetingThemes?.map(meetingTheme => {
                                    return (
                                        <AccordionItem key={`accordion-theme${meetingTheme.id}`} borderWidth="0">
                                            <AccordionButton _focus={{boxShadow: ""}} _hover={hoverStyle} minHeight="3em"
                                            onClick={() => onThemeChosen?.(meetingTheme)}
                                                    {...(chosenMeetingTheme?.id === meetingTheme.id ? {...chosenStyle} : {})}>
                                                <AccordionIcon/>
                                                <HStack justifyContent="space-between" paddingLeft="1em" width="100%">
                                                    <Text fontWeight={themeIdsWithMeetingConfiguration.some(x =>meetingTheme.id == x || (meetingTheme.children && meetingTheme.children.some(c => c.id === x))) ? "bold" : "normal"}>
                                                        {meetingTheme.name}
                                                    </Text>
                                                    {chosenMeetingTheme?.id === meetingTheme.id ? <ArrowRightIcon width="24px" height="24px" color="blue" /> : null}
                                                </HStack>
                                            </AccordionButton >
                                            <AccordionPanel pl="3em" pr="0">
                                                <VStack alignItems="stretch" borderLeftWidth="thin" borderLeftColor="gray.200">
                                                    {meetingTheme.children && meetingTheme.children.map(subTheme => {
                                                        return (
                                                            <HStack justifyContent="space-between" 
                                                                key={`accordion-subtheme${subTheme.id}`} minHeight="3em" 
                                                                _hover={hoverStyle} paddingX="1em"
                                                            onClick={() => onThemeChosen?.(subTheme)}
                                                                {...(chosenMeetingTheme?.id === subTheme.id ? {...chosenStyle} : {})}>
                                                                <Text fontWeight={themeIdsWithMeetingConfiguration.some(x => x === subTheme.id) ? "bold" : "normal"}>
                                                                    {subTheme.name}
                                                                </Text>
                                                                {chosenMeetingTheme?.id === subTheme.id ? <ArrowRightIcon width="24px" height="24px" color="blue" /> : null}
                                                            </HStack>
                                                        );
                                                    })}
                                                </VStack>
                                            </AccordionPanel>
                                        </AccordionItem >
                                    );
                                })}
                            </Accordion>
                        </Box>
                    </AccordionPanel>
                </AccordionItem >
            </Accordion>
        </>
    );
};