import { VStack, UnorderedList, ListItem, Text, StackProps, HStack, IconButton, Icon, Heading } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { CircleRemove } from "../../icons/CircleRemove";
import { DataList, OptionValue } from "../DataList";

const inputStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        paddingLeft: 8,
        paddingRight: 8,
    }),
};


interface Props extends StackProps {
    options: OptionValue[];
    placeholder: string;
    selectedItems: string[];
    spacing?: number | undefined;
    handleOnChange: (ids: string[]) => void;
    subheading?: string;
    heading?: string;
}

export const DisplayList = ({ options, placeholder, selectedItems, spacing, handleOnChange, subheading, heading }: Props) => {
    const [selectedItemsState, setSelectedItemsState] = useState(selectedItems);
    
    function handleAddClick(options: OptionValue[]): void {
        const selectedItems = [...selectedItemsState, options[0].value]
        setSelectedItemsState(selectedItems);
        handleOnChange(selectedItems);
    }

    function handleRemoveClicked(id: string): void {
        const selectedItems = selectedItemsState.filter((item) => item !== id);
        setSelectedItemsState(selectedItems);
        handleOnChange(selectedItems);
    }

    const selectedOptions = useMemo(() => {
        return options.filter((option) => selectedItemsState.includes(option.value));
    }, [selectedItemsState, options]);

    const unselectedOptions = useMemo(() => {
        return options.filter((option) => !selectedItemsState.includes(option.value));
    }, [selectedItemsState, options])


    return (
        <VStack spacing={spacing} align="stretch" justify="end" w="100%" >
            {heading &&  <Heading as="h3" size="md" fontWeight="semibold">{heading}</Heading>}
            <UnorderedList spacing={spacing} h="200px" overflowY="auto" overflowX="visible">
                {selectedOptions.map((opt) => {
                    return <ListItem key={opt.value} flex="1" textAlign="left" fontSize="18px">
                        <HStack justify="space-between" spacing="0" align="center">
                            <Text fontSize="16px">{opt.label}</Text>
                            <IconButton aria-label="Remove" fill="black" bgColor="unset" icon={<Icon as={CircleRemove} />} onClick={() => handleRemoveClicked(opt.value)}></IconButton>
                        </HStack>
                    </ListItem>
                })}
            </UnorderedList>
            <VStack align="stretch" spacing="0">
                {subheading && <Text color="gray.300" fontSize="xs">{subheading}</Text>}
                <DataList inputStyles={inputStyles} values={unselectedOptions} setValueOnSelect={false} placeholder={placeholder} handleOnChange={handleAddClick} closeMenuOnSelect={false} isMulti={false} />
            </VStack>
        </VStack>
    );
}