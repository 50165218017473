/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WeekdayEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

export function WeekdayEnumFromJSON(json: any): WeekdayEnum {
    return WeekdayEnumFromJSONTyped(json, false);
}

export function WeekdayEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekdayEnum {
    return json as WeekdayEnum;
}

export function WeekdayEnumToJSON(value?: WeekdayEnum | null): any {
    return value as any;
}

