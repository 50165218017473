import {
    ComponentMultiStyleConfig
} from "@chakra-ui/react";

export const TabsConfig: ComponentMultiStyleConfig = {
    parts: ["tablist", "tab"],
    variants: {
        "button-like": ({ colorScheme, colorMode }) => {
            const backgroundColor = colorMode === "light" ? `${colorScheme}.200` : `${colorScheme}.600`;
            const color = colorMode === "light" ? "currentColor" : "white";
            return {
                tablist: {
                    "& > *:not(style) ~ *:not(style)": {
                        ml: "4"
                    },
                    pb: "8",
                    borderBottom: "1px solid",
                    borderColor: `${backgroundColor}`
                },
                tab: {
                    borderRadius: "md",
                    _active: {
                        color: color,
                        backgroundColor: backgroundColor
                    },
                    _hover: {
                        color: color,
                        backgroundColor: backgroundColor
                    },
                    _selected: {
                        color: color,
                        fontWeight: "bold",
                        backgroundColor: backgroundColor
                    },
                    _focus: {
                        boxShadow: "none"
                    }
                }
            }
        }
    }
}