import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { LocationApi, LocationConfiguration, MeetingRoom } from "./client"

export const useLocationApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [locationApi] = React.useState(
        () => new LocationApi(baseConfiguration)
    );
    return locationApi;
}

export const useGetLocations = () => {
    const client = useLocationApi();
    return useQuery(["getLocations"], () =>
        client.getLocations());
};

export const useUpdateLocation = () => {
    const client = useLocationApi();
    const queryClient = useQueryClient();
    return useMutation(
        (location: LocationConfiguration) =>
            client.updateLocation({ locationConfiguration: location }),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getLocations"])
                }
            }
    );
};

export const useGetLocationMeetingRooms = (locationId: string | null) => {
    const client = useLocationApi();
    return useQuery(["getLocationMeetingRooms", locationId], () => 
        locationId === null ? client.getAllMeetingRooms() : client.getLocationMeetingRooms({ id: locationId! })
    );
};

export const useUpdateMeetingRoom = () => {
    const client = useLocationApi();
    const queryClient = useQueryClient();
    return useMutation(
        (params: {id: string, meetingRoom: MeetingRoom}) =>
            client.updateMeetingRoom({...params}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getLocations"]);
                }
            }
    );
};
