import React from "react";
import { UseMutationResult } from "react-query";

type DebounceFunctionType<T extends (...args: any) => unknown> = (this: ThisParameterType<T>, ...args: Parameters<T>) => void;
type DebounceReturnType<T extends (...args: any) => unknown> = { func: DebounceFunctionType<T>, timeoutHandle: number | undefined };

/**
 * Debounces a function, like Lodash's debounce method
 * @param func the function to invoke
 * @param wait the time before invoking the function
 * @param immediate if the function should be invoked immediately
 * @returns the debounced function and timeout handle
 */
export function debounce<T extends (...args: any) => unknown>(func: T, wait: number, immediate: boolean = false): DebounceReturnType<T> {
    let timeout: number | undefined;
    function functionToCall<TArgs extends Parameters<T>>(this: ThisParameterType<typeof func>, ...args: TArgs) {
        let context = this;
        window.clearTimeout(timeout);
        timeout = window.setTimeout(function() {
            timeout = undefined;
            if (!immediate) func.apply(context, args);
        }, wait);
        if (immediate && !timeout) return func.apply(context, args);
    };
    return { func: functionToCall, timeoutHandle: timeout };
};

/**
 * Hook for using the debounce function, with automatic cleanup of the debounced function when the surrounding component is removed
 * @param func the function to invoke
 * @param wait the time before invoking the function
 * @param immediate if the function should be invoked immediately
 * @returns the debounced function. NOTE: make sure the function supplied in the parameters is memoized, otherwise the debounce function will be called everytime the component is rerendered.
 */
export function useDebounce<T extends (...args: any) => unknown>(func: T, wait: number, immediate: boolean = false): DebounceFunctionType<T> {
    const { func: functionToCall, timeoutHandle } = React.useMemo(() => {
        return debounce(func, wait, immediate);
    }, [func, wait, immediate]);
    React.useEffect(() => {
        return () => window.clearTimeout(timeoutHandle);
    }, [timeoutHandle]);

    return functionToCall;
};

export function useDebouncedMutation<T extends UseMutationResult<any, any, any, any>>(mutation: T, wait: number): T {
    const { mutate } = mutation;
    const { func: debouncedMutate, timeoutHandle: timeoutHandleMutate } = React.useMemo(() => {
        return debounce(mutate, wait, false);
    }, [mutate, wait]);
    React.useEffect(() => {
        return () => {
            window.clearTimeout(timeoutHandleMutate);
        } 
    }, [timeoutHandleMutate]);

    return {
        ...mutation,
        mutate: debouncedMutate
    };
};

export function useDebouncedState<T>(initial: T, delay = 250): [T, (newValue: React.SetStateAction<T>) => void] {
    const [state, setState] = React.useState(initial);
    let timeout: number | undefined;

    function setValue(newValue: React.SetStateAction<T>) {
        window.clearTimeout(timeout);
        timeout = window.setTimeout(() => {
            timeout = undefined;
            setState(newValue);
        }, delay);
    }

    return [state, setValue];
}