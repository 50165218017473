import React from "react"
import { useQuery } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { LocalizationApi } from "./client"

export const useLocalizationApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [localizationApi] = React.useState(
        () => new LocalizationApi(baseConfiguration)
    );
    return localizationApi;
}

export const useGetLanguages = () => {
    const client = useLocalizationApi();
    return useQuery(["getLanguages"], () =>
        client.getLanguages());
};

