import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/accordion";
import { VStack, Text, HStack, Box, Flex } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Taxonomy } from "../api/client/models";

interface ConfigurationOptionItemProps{
    title: string;
    customerCategories: Taxonomy[];
    children: (customerCategoryId: string | undefined) => JSX.Element;
}

export function ConfigurationOptionItem({title, customerCategories, children }: ConfigurationOptionItemProps) {
    const { t } = useTranslation();
    return (
        <VStack alignItems="stretch" width="100%">
            <Text fontSize="18px" fontWeight="bold">
                {title}
            </Text>
            <Accordion allowToggle>
                <AccordionItem borderTopWidth="0" pb="1em">
                    <HStack>
                        <AccordionButton width="33%" pl="0" _focus={{boxShadow: "0"}}>
                            <HStack>
                                <AccordionIcon/>
                                <Text fontSize="16px">
                                    {t('meetingconfigurationpage.configuration-option.all-categories')}
                                </Text>
                            </HStack>
                        </AccordionButton>
                        <Flex>
                            {children(undefined)}
                        </Flex>
                    </HStack>
                    <AccordionPanel paddingX="0">
                        <VStack alignItems="stretch">
                            {customerCategories.filter(x => x.id !== undefined && x.id !== null).map(customercategory => {
                                return (
                                    <HStack key={`${customercategory.id}-configuration`} height="3em">
                                        <Box width="33%">
                                            <Text pl="2em">{customercategory.name}</Text>
                                        </Box>
                                        <Flex>
                                            {children(customercategory.id!)}
                                        </Flex>
                                    </HStack>
                                );
                            })}
                        </VStack>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </VStack>
    );
}