/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeekdayEnum,
    WeekdayEnumFromJSON,
    WeekdayEnumFromJSONTyped,
    WeekdayEnumToJSON,
    WorkdayMeetingType,
    WorkdayMeetingTypeFromJSON,
    WorkdayMeetingTypeFromJSONTyped,
    WorkdayMeetingTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Workday
 */
export interface Workday {
    /**
     * 
     * @type {WeekdayEnum}
     * @memberof Workday
     */
    dayOfWeek: WeekdayEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Workday
     */
    isAvailable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Workday
     */
    from: string;
    /**
     * 
     * @type {string}
     * @memberof Workday
     */
    to: string;
    /**
     * 
     * @type {Array<WorkdayMeetingType>}
     * @memberof Workday
     */
    availableMeetingTypes: Array<WorkdayMeetingType>;
    /**
     * 
     * @type {string}
     * @memberof Workday
     */
    location?: string | null;
}

export function WorkdayFromJSON(json: any): Workday {
    return WorkdayFromJSONTyped(json, false);
}

export function WorkdayFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workday {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dayOfWeek': WeekdayEnumFromJSON(json['dayOfWeek']),
        'isAvailable': !exists(json, 'isAvailable') ? undefined : json['isAvailable'],
        'from': json['from'],
        'to': json['to'],
        'availableMeetingTypes': ((json['availableMeetingTypes'] as Array<any>).map(WorkdayMeetingTypeFromJSON)),
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function WorkdayToJSON(value?: Workday | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dayOfWeek': WeekdayEnumToJSON(value.dayOfWeek),
        'isAvailable': value.isAvailable,
        'from': value.from,
        'to': value.to,
        'availableMeetingTypes': ((value.availableMeetingTypes as Array<any>).map(WorkdayMeetingTypeToJSON)),
        'location': value.location,
    };
}


