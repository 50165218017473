import React, { useRef } from "react";
import { Checkbox } from "@chakra-ui/checkbox"
import {
  Box,
  Flex,
  HStack,
  VStack,
  StackDivider,
  Text
} from "@chakra-ui/layout"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react"
import { useBoolean, Icon } from "@chakra-ui/react"
import { IoIosOptions, IoIosArrowDown, IoIosCheckmarkCircle } from "react-icons/io";
import { useState } from "react"
import { useGetAllBookableAdvisors } from "../api/useAdvisorApi"
import { useGetCompetenceGroupInheritageMutation } from "../api/useCompetenceGroupApi"
import { useGetDays } from '../api/useCalendarApi'
import { Calendar } from "../components/CalendarOverview/Calendar"
import * as CalendarStyles from "../components/CalendarOverview/CalendarStyles"
import { useDebouncedState } from "../helpers/debounce";
import { useGetLocations } from "../api/useLocationApi";
import { DataList, OptionValue } from "../components/DataList";
import ContentWithHeading from "../components/ContentWithHeading";
import { LocationConfiguration } from "../api/client";
import { useGetAllCompetenceGroups } from "../api/useCompetenceGroupApi";
import { CompetenceGroup } from "../api/client";
import { useTranslation } from "react-i18next";

type View = 'Lokation' | 'Kompetencegruppe';

const labelStyle = {
  minW: 120,
  pb: .5,
  px: 2,
  textAlign: 'center',
  border: '1px solid #00000000'
};

const initWeekMonday = new Date();
const dayOffset = initWeekMonday.getUTCDay() === 0 ? 6 : initWeekMonday.getUTCDay() - 1;
initWeekMonday.setDate(initWeekMonday.getUTCDate() - dayOffset); //+ 1 for monday
initWeekMonday.setHours(0, 0, 0, 0);

export const CalendarOverviewPage = () => {
  const { t } = useTranslation();
  const dataListAdvisorsRef = useRef<{ clearSelectedOptions: () => void }>(null);
  const [weekMonday, setWeekMonday] = useState(initWeekMonday);
  const { data: advisors } = useGetAllBookableAdvisors();
  const { data: competenceGroups } = useGetAllCompetenceGroups();
  const getCompetenceGroupInheritageMutation = useGetCompetenceGroupInheritageMutation();
  const nextWeekMonday = React.useMemo(() => {
    const d = new Date(weekMonday);
    d.setDate(d.getDate() + 7);
    return d;
  }, [weekMonday]);
  const [selectedView, setSelectedView] = useState<View>('Lokation');
  const [selectedAdvisors, setSelectedAdvisors] = useDebouncedState<string[] | null>(null, 750);
  const [filteredAdvisorOptions, setFilteredAdvisorOptions] = useState<string[]>();
  const { data: days } = useGetDays({ startDate: weekMonday.toDateString(), endDate: nextWeekMonday.toDateString(), advisorEmails: selectedAdvisors || undefined });
  const [openTimesOnly, setOpenTimesOnly] = useBoolean(false);
  const { data: locations } = useGetLocations();
  const [selectedLocations, setSelectedLocations] = useState<LocationConfiguration[]>();
  const advisorOptions = React.useMemo(() => {
    if (advisors) {
      if (selectedView === "Lokation") {
        const filteredAdvisors = selectedLocations ?
          advisors.filter(x => x.location && selectedLocations
            .some(selLoc => selLoc.location === x.location)) : advisors;
        return filteredAdvisors.map(a => {
          return {
            value: a.email || "",
            label: `${a.initials} - ${a.displayName} ${(a.location ? `- ${a.location}` : ``)}`
          };
        });
      } else {
        return filteredAdvisorOptions?.map(advisor => {
          const adv = advisors.find(a => a.id === advisor);
          
          if (!adv) return null;

          return {
            value: adv?.email || "",
            label: `${adv?.initials} - ${adv?.displayName} ${(adv?.location ? `- ${adv?.location}` : ``)}`
          };
        }).filter(Boolean) as OptionValue[];
      }
    }
  }, [advisors, selectedLocations, filteredAdvisorOptions, selectedView]);

  const locationOptions = React.useMemo(() => {
    if (locations) {
      return locations.map(loc => {
        return { value: loc.id, label: loc.location };
      })
    }
  }, [locations]);

  const groupOptions = React.useMemo(() => {
    if (!competenceGroups) return [];

    return competenceGroups?.map((competenceGroup) => {
        return {
            label: competenceGroup.name!,
            value: competenceGroup.id!,
        }
    });
}, [competenceGroups]);

  function handleAdvisorSelectChange(options: OptionValue[]) {
    if (options && options.length > 0) {
      const filteredAdvisors = advisors?.filter(adv => options.some(opt => opt.value === adv.email));
      if (filteredAdvisors) {
        const advisorEmails = filteredAdvisors.map(fadv => fadv.email).filter(email => !!email) as string[];
        setSelectedAdvisors(advisorEmails);
      }
    } else {
      setSelectedAdvisors(null);
    }
  }

  function handleLocationChange(options: OptionValue[]) {
    if (options && options.length > 0) {
      setSelectedLocations(locations?.filter(loc => options.some(opt => opt.value === loc.id)));
    } else {
      setSelectedLocations(undefined);
    }
  }

  const getInheritedAdvisorsForSelectedCompetenceGroup = (competenceGroup: CompetenceGroup) => {
    getCompetenceGroupInheritageMutation.mutate(competenceGroup.id as string, {
        onSuccess: (data) => {
            const inheritedAdvisors = data.advisorIds || [];
            const competenceAdvisors = competenceGroup.advisorIds || [];
            const mergedAdvisors = Array.from(new Set([...inheritedAdvisors, ...competenceAdvisors]));

            clearDataListAdvisors();
            setSelectedAdvisors(null);
            setFilteredAdvisorOptions(mergedAdvisors);
        }
    });
  };

  const handleGroupChange = (options: OptionValue[]) => {
    if (options && options.length > 0 && options[0] !== null) {
      const filteredGroups = competenceGroups?.filter(group => options.some(opt => opt.value === group.id));
      if (filteredGroups) {
        getInheritedAdvisorsForSelectedCompetenceGroup(filteredGroups[0]);
      }
    } else {
      setFilteredAdvisorOptions([]);
    }
  };

  const clearDataListAdvisors = () => {
    if (dataListAdvisorsRef.current) {
      dataListAdvisorsRef.current.clearSelectedOptions();
    }
  }

  React.useEffect(() => {
    if (selectedView === 'Kompetencegruppe') {
      setSelectedLocations(undefined);
    } else if (selectedView === 'Lokation') {
      //
    }

    setSelectedAdvisors(null);
    clearDataListAdvisors();
  }, [selectedView]);

  return (
    <Flex flexDir='column' flex='1 0 0' w='100%' h="100%">
      <HStack w={["100%", null, null, null, null, "70%", "50%"]} alignItems="end" spacing={8}>
        <Popover closeOnBlur={true} placement="bottom-start">
          <PopoverTrigger>
            <Box bg="white" minW="260px" p={2} borderRadius="md"  boxShadow="md" cursor="pointer">
              <HStack spacing={3} alignItems="center" >
                <Icon as={IoIosOptions} width={7} height={7} fill="gray.200" />
                <VStack spacing={0} alignItems="left" width="100%">
                  <Text fontSize="xs" marginTop={1}>Vælg filtrering</Text>
                  <Text fontWeight="semibold">{selectedView}</Text>
                </VStack>
                <Icon as={IoIosArrowDown} width={5} height={5} fill="gray.200" />
              </HStack>
            </Box>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverBody>
              <VStack spacing={2} alignItems="stretch" divider={<StackDivider borderColor='gray.50' />}>
                <HStack p={2} borderRadius="md" cursor="pointer" justifyContent="space-between" onClick={() => setSelectedView('Lokation')}>
                  <Text cursor="pointer" fontWeight={selectedView === 'Lokation' ? 'semibold' : 'normal'}>Lokation</Text>
                  {selectedView === 'Lokation' && <Icon as={IoIosCheckmarkCircle} width={5} height={5} {...CalendarStyles.availableFill} />}
                </HStack>
                <HStack p={2} borderRadius="md" cursor="pointer" justifyContent="space-between" onClick={() => setSelectedView('Kompetencegruppe')}>
                  <Text cursor="pointer" fontWeight={selectedView === 'Kompetencegruppe' ? 'semibold' : 'normal'}>Kompetencegruppe</Text>
                  {selectedView === 'Kompetencegruppe' && <Icon as={IoIosCheckmarkCircle} width={5} height={5} {...CalendarStyles.availableFill} />}
                </HStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
        {selectedView === 'Lokation' && (
          <Box w="50%">
            <ContentWithHeading heading={t('calendarpage.location-search.header')}>
              <DataList placeholder={t('calendarpage.location-search.placeholder')} values={locationOptions} isMulti={true} handleOnChange={handleLocationChange} />
            </ContentWithHeading>
          </Box>
        )}
        {selectedView === 'Kompetencegruppe' && (
          <Box w="50%">
            <ContentWithHeading heading={t('competencegrouppage.group-list.heading')}>
              <DataList placeholder={t('competencegrouppage.group-list.placeholder')} values={groupOptions} isMulti={false} handleOnChange={handleGroupChange} closeMenuOnSelect={true} />
            </ContentWithHeading>
          </Box>
        )}
        <Box w="50%">
          <ContentWithHeading heading={t('calendarpage.employee-search.header')}>
            <DataList ref={dataListAdvisorsRef} placeholder={t('calendarpage.employee-search.placeholder')} values={advisorOptions} isMulti={true} handleOnChange={handleAdvisorSelectChange} closeMenuOnSelect={false} />
          </ContentWithHeading>
        </Box>
      </HStack>
      <Flex gridGap={'5px'} pb='5px' align='end' whiteSpace='nowrap' wrap='wrap-reverse'>
        <Flex gridGap='5px 5px' wrap='wrap' userSelect='none'>
          <Text {...CalendarStyles.available} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.availability')}</Text>
          <Text {...CalendarStyles.booked} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.customermeeting')}</Text>
          <Text {...CalendarStyles.busy} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.owncalendar')}</Text>
          <Text border={'1px solid'} borderColor={'gray.200'} sx={labelStyle} borderRadius="md">{t('calendarpage.calendar-label.unavailable')}</Text>
        </Flex>
        <HStack gridGap={5} flex='1 auto' justify='right' py={2} wrap='wrap'>
          <Checkbox size='lg' borderColor='black' isChecked={openTimesOnly} onChange={setOpenTimesOnly.toggle}>{t('calendarpage.checkbox.only-available')}</Checkbox>
        </HStack>
      </Flex>
      <Calendar currentWeekMonday={weekMonday} onWeekChange={setWeekMonday} days={days} openTimesOnly={openTimesOnly} />
    </Flex>
  )
}