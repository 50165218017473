import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount >= 3) {
          return false;
        }

        if (error instanceof Response) {
          if (error.status === 401) {
            window.location.reload();
            return false;
          }
        }

        return true;
      },
    },
  },
});
