import { useTranslation } from "react-i18next";
import { useUpdateMeetingDuration } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { TimeDropdown } from "../../TimeDropdown";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const MeetingDurationInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updateMeetingDurationMutation = useUpdateMeetingDuration();
    const { t } = useTranslation();

    function handleMeetingDurationChanged(newValue: string, customerCategoryId?: string) {
        updateMeetingDurationMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, duration: { customerCategoryId, duration: newValue } });
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.input.meeting-duration')}customerCategories={customerCategories}>
        {customerCategoryId => {
            const meetingDuration = actualMeetingConfiguration!.meetingDurations!;
            const value = meetingDuration.find(x => x.customerCategoryId === customerCategoryId);
            let isDisabled = false
            if (customerCategoryId === undefined && meetingDuration.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                isDisabled = true;
            }

            return (
                <TimeDropdown 
                    label={t('meetingconfigurationpage.time-dropdown.label')}
                    timeResolution="minutes" 
                    isDisabled={isDisabled} 
                    value={value?.duration || ""} 
                    onSelectChanged={(newValue) => handleMeetingDurationChanged(newValue, customerCategoryId)} 
                    stepSize={15}
                    stepStart={1}
                    stepEnd={16}/>
            );
        }}
        </ConfigurationOptionItem>
    );
};