import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { CompetenceGroupApi, CompetenceGroupInput } from "./client";

export const useCompetenceGroupApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [competenceGroupApi] = React.useState(
        () => new CompetenceGroupApi(baseConfiguration)
    );
    return competenceGroupApi;
}

export const useGetAllCompetenceGroups = () => {
    const client = useCompetenceGroupApi();
    return useQuery(["getAllCompetenceGroups"], () =>
        client.getAllCompetenceGroups())
}

export const useGetCompetenceGroup = (id: string) => {
    const client = useCompetenceGroupApi();
    return useQuery(["getCompetenceGroup", id], () =>
        client.getCompetenceGroup({ id }))
}

export const useCreateCompetenceGroup = () => {
    const client = useCompetenceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(
        (competenceGroup: CompetenceGroupInput) =>
            client.createCompetenceGroup({ competenceGroupInput: competenceGroup }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllCompetenceGroups"])
            }
        }
    );
}

export const useUpdateCompetenceGroup = () => {
    const client = useCompetenceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(({id, competenceGroup }: {id: string, competenceGroup: CompetenceGroupInput }) => client.updateCompetenceGroup({ id, competenceGroupInput: competenceGroup }), {
        onSuccess: (data, input) => {
            queryClient.invalidateQueries(["getCompetenceGroup", input.id]);
            queryClient.invalidateQueries(["getAllowedChildrenIds", input.id]);
        }
    })
}

export const useDeleteCompetenceGroup = () => {
    const client = useCompetenceGroupApi();
    const queryClient = useQueryClient();
    return useMutation((id: string) => client.deleteCompetenceGroup({ id }), {
        onSuccess: (data, input) => {
            queryClient.invalidateQueries(["getAllCompetenceGroups"]);
        }
    })
}

export const useGetAllowedChildrenIds = (id: string) => {
    const client = useCompetenceGroupApi();
    return useQuery(["getAllowedChildrenIds",  id], () =>
        client.getAllowedChildGroups({ id }))
}

export const useGetCompetenceGroupInheritage = (id: string) => {
    const client = useCompetenceGroupApi();
    return useQuery(["getCompetenceGroupInheritage", id], () =>
        client.getCompetenceGroupInheritage({ id }))
}

export const useGetCompetenceGroupInheritageMutation = () => {
    const client = useCompetenceGroupApi();
    return useMutation((id: string) => client.getCompetenceGroupInheritage({ id }));
};