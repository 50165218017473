import React from "react"
import { useQuery } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { Configuration } from "./client"
import { CalendarApi, GetDaysRequest } from "./client/apis/CalendarApi";

export const useCalendarApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const advisorApi = React.useMemo(
        () => new CalendarApi(baseConfiguration), [baseConfiguration]
    );
    return advisorApi;
}

export const useGetDays = (requestParams: GetDaysRequest) => {
    const client = useCalendarApi();
    return useQuery(["getDays", requestParams],
        async () => client.getDays(requestParams),
        { keepPreviousData: true }
    ); 
}
