import {
    ComponentSingleStyleConfig
} from "@chakra-ui/react";

export const ContentCardConfigKey = "ContentCard";

export const ContentCardConfig: ComponentSingleStyleConfig = {
    baseStyle: {
        bgColor: "white",
        boxShadow: "lg",
        px: "8",
        py: "8",
        borderRadius: "md"
    }
};