import { 
    Popover, 
    HStack, 
    PopoverTrigger, 
    IconButton, 
    PopoverContent, 
    PopoverArrow, 
    PopoverCloseButton, 
    PopoverBody, 
    Input, 
    Button,
    Text,
} from "@chakra-ui/react";
import { useState, createRef } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";
import { Taxonomy } from "../../api/client";
import { ChevronDown } from "../../icons/ChevronDown";
import { ChevronUp } from "../../icons/ChevronUp";
import { DeleteIcon } from "../../icons/DeleteIcon";
import { EditIcon } from "../../icons/EditIcon";

interface Props {
    handleDeleteButtonClicked: (taxonomy: Taxonomy) => void;
    handleMoveUpClicked?: (taxonomy: Taxonomy) => void;
    handleMoveDownClicked?: (taxonomy: Taxonomy) => void;
    taxonomy: Taxonomy;
    taxonomyFontSize: string;
    useUpdateTaxonomyMutation: UseMutationResult<Taxonomy, unknown, Taxonomy, unknown>;
    disallowDelete?: boolean;
    disallowReordering?: boolean;
}

export const EditableRow = ({handleDeleteButtonClicked, handleMoveUpClicked, handleMoveDownClicked, taxonomy, taxonomyFontSize, useUpdateTaxonomyMutation, disallowDelete, disallowReordering }: Props) => {
    const [updateTaxonomyName, setUpdateTaxonomyName] = useState("")
    const [updateTaxonomyPopoverIsOpen, setUpdateTaxonomyPopoverIsOpen] = useState(false);
    const [buttonIsVisible, setButtonIsVisible] = useState(false);
    const taxonomyInputRef = createRef<HTMLInputElement>();
    const { t } = useTranslation();
    
    function handleUpdateTaxonomySubmitted() {
        const taxonomyToUpdate: Taxonomy = {...taxonomy, name: updateTaxonomyName}
        useUpdateTaxonomyMutation.mutate(taxonomyToUpdate);
        setUpdateTaxonomyPopoverIsOpen(false);
    }

    function handleEditButtonClicked(taxonomy: Taxonomy) {
        if (taxonomy.name) {
            setUpdateTaxonomyName(taxonomy.name);
        }
        setUpdateTaxonomyPopoverIsOpen(true);
    }

    return (
        <Popover placement="right" isOpen={updateTaxonomyPopoverIsOpen} computePositionOnMount={true} 
            onClose={() => setUpdateTaxonomyPopoverIsOpen(false)} initialFocusRef={taxonomyInputRef}>
            <HStack justifyContent="space-between" height="10" onMouseEnter={() => setButtonIsVisible(true)} 
                onMouseLeave={() => setButtonIsVisible(false)} _hover={{bgColor: "bookMeLightBlue"}}>
                <PopoverTrigger>
                    <Text paddingLeft="8" fontSize={taxonomyFontSize}>{taxonomy.name}</Text>
                </PopoverTrigger>
                <HStack hidden={!buttonIsVisible} spacing="2em">
                    {!disallowReordering && (<HStack >
                        <IconButton  _focus={{boxShadow: "0"}} bgColor="unset" icon={<ChevronUp height="24px" width="24px"/>}
                            aria-label="move-up-button"  onClick={handleMoveUpClicked ? () => handleMoveUpClicked(taxonomy) : undefined}/>
                        <IconButton  _focus={{boxShadow: "0"}} bgColor="unset" icon={<ChevronDown height="24px" width="24px"/>}
                            aria-label="move-down-button"  onClick={handleMoveDownClicked ? () => handleMoveDownClicked(taxonomy) : undefined}/>
                    </HStack>)}
                    <HStack>
                        <IconButton _focus={{boxShadow: "0"}} bgColor="unset" icon={<EditIcon height="24px" width="24px"/>}
                                aria-label="edit-button"  onClick={() => handleEditButtonClicked(taxonomy)}/>
                        {!disallowDelete && (<IconButton _focus={{boxShadow: "0"}} bgColor="unset" icon={<DeleteIcon height="24px" width="24px"/>}
                                aria-label="delete-button" onClick={() => handleDeleteButtonClicked(taxonomy)}/>)}
                    </HStack>
                </HStack>
            </HStack>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                    <HStack>
                        <Input ref={taxonomyInputRef} value={updateTaxonomyName}  
                            onChange={e => setUpdateTaxonomyName(e.target.value)} />
                        <Button bgColor="#1A8CF9" textColor="white" onClick={handleUpdateTaxonomySubmitted}>{t('edit-name.save-button')}</Button>
                    </HStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};