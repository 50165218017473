import { VStack, Heading, Text, HStack, BoxProps, Divider } from "@chakra-ui/react"
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ServiceGroup, Taxonomy } from "../../api/client";
import { useGetAllCustomerCategories } from "../../api/useCustomerCategoriesApi";
import { useGetLocations } from "../../api/useLocationApi";
import { useGetAllMeetingThemes } from "../../api/useMeetingThemeApi";
import { useUpdateServiceTrigger } from "../../api/useServiceGroupApi";
import { ContentCard } from "../ContentCard/ContentCard"
import { OptionValue } from "../DataList";
import { DisplayList } from "./DisplayList"

interface Props extends BoxProps {
    selectedServiceGroup: ServiceGroup;
}

export const UpdateServiceTrigger = ({ selectedServiceGroup, ...props }: Props) => {
    const { data: locationConfigurations } = useGetLocations();
    const { data: customerCategories } = useGetAllCustomerCategories();
    const { data: themes } = useGetAllMeetingThemes();
    const updateServiceLevelMutation = useUpdateServiceTrigger();
    const { t } = useTranslation();

    const locationConfigurationOptions: OptionValue[] = useMemo(() => {
        return locationConfigurations?.map(lc => { return { value: lc.id, label: lc.location } }) || [];
    }, [locationConfigurations]);

    const customerCategoryOptions: OptionValue[] = useMemo(() => {
        return customerCategories?.map(c => { return { value: c.id!, label: c.name! } }) || [];
    }, [customerCategories]);

    const themeOptions: OptionValue[] = useMemo(() => {
        if (!themes) return [];

        // Get all child themes and add parent name to label
        const childThemes: Taxonomy[] = themes.filter(t => t.children?.length !== 0).flatMap(t => {
            const children: Taxonomy[] = t.children!.map(c => {
                return { ...c, parent: t }
            });
            return children;
        });

        return childThemes?.map(t => { return { value: t.id!, label: `${t.name!} (${t.parent?.name!})` } }) || [];
    }, [themes]);

    const serviceTrigger = useMemo(() => {
        return selectedServiceGroup.serviceTrigger;
    }, [selectedServiceGroup]);


    if (!serviceTrigger) {
        return (<></>);
    }

    function handleThemesChanged(ids: string[]): void {
        if (!serviceTrigger) {
            return;
        }

        updateServiceLevelMutation.mutate({
            id: selectedServiceGroup.id!,
            serviceTrigger: {
                ...serviceTrigger,
                themeIds: ids
            }
        });
    }

    function handleLocationsChanged(ids: string[]): void {
        if (!serviceTrigger) {
            return;
        }

        updateServiceLevelMutation.mutate({
            id: selectedServiceGroup.id!,
            serviceTrigger: {
                ...serviceTrigger,
                locationConfigurationIds: ids
            }
        });
    }

    function handleCategoriesChanged(ids: string[]): void {
        if (!serviceTrigger) {
            return;
        }

        updateServiceLevelMutation.mutate({
            id: selectedServiceGroup.id!,
            serviceTrigger: {
                ...serviceTrigger,
                customerCategoryIds: ids
            }
        });
    }

    return (
        <ContentCard {...props}>
            <VStack align="start" spacing="8">
                <VStack align="start" w="50%">
                    <Heading as="h2" size="lg" fontWeight="semibold">{t('servicegrouppage.update-trigger.heading')}</Heading>
                    <Text color="gray.300" fontSize="xs">{t('servicegrouppage.update-trigger.description')}
                    </Text>
                </VStack>
                <HStack spacing="8" align="stretch" justify="stretch" w="100%" minH="200px">
                    <DisplayList heading={t('servicegrouppage.update-trigger.location-list.heading')} selectedItems={serviceTrigger.locationConfigurationIds || []} options={locationConfigurationOptions} placeholder={t('servicegrouppage.update-trigger.location-list.placeholder')} handleOnChange={ids => handleLocationsChanged(ids)} />
                    <DisplayList heading={t('servicegrouppage.update-trigger.customer-category-list.heading')} selectedItems={serviceTrigger.customerCategoryIds || []} options={customerCategoryOptions} placeholder={t('servicegrouppage.update-trigger.customer-category-list.placeholder')} handleOnChange={ids => handleCategoriesChanged(ids)} />
                    <DisplayList heading={t('servicegrouppage.update-trigger.meeting-theme-list.heading')} selectedItems={serviceTrigger.themeIds || []} options={themeOptions} placeholder={t('servicegrouppage.update-trigger.meeting-theme-list.placeholder')} handleOnChange={ids => handleThemesChanged(ids)} />
                </HStack>
            </VStack>
        </ContentCard>
    )
}