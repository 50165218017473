/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MeetingTypeEnum {
    Physical = 'Physical',
    Online = 'Online',
    Telephone = 'Telephone',
    OffSite = 'OffSite'
}

export function MeetingTypeEnumFromJSON(json: any): MeetingTypeEnum {
    return MeetingTypeEnumFromJSONTyped(json, false);
}

export function MeetingTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): MeetingTypeEnum {
    return json as MeetingTypeEnum;
}

export function MeetingTypeEnumToJSON(value?: MeetingTypeEnum | null): any {
    return value as any;
}

