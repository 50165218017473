/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Taxonomy,
    TaxonomyFromJSON,
    TaxonomyFromJSONTyped,
    TaxonomyToJSON,
} from './';

/**
 * 
 * @export
 * @interface CompetenceGroup
 */
export interface CompetenceGroup {
    /**
     * 
     * @type {string}
     * @memberof CompetenceGroup
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompetenceGroup
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroup
     */
    childGroupIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroup
     */
    parentGroupIds?: Array<string> | null;
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof CompetenceGroup
     */
    customerCategories?: Array<Taxonomy> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompetenceGroup
     */
    advisorIds?: Array<string> | null;
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof CompetenceGroup
     */
    themes?: Array<Taxonomy> | null;
}

export function CompetenceGroupFromJSON(json: any): CompetenceGroup {
    return CompetenceGroupFromJSONTyped(json, false);
}

export function CompetenceGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompetenceGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'childGroupIds': !exists(json, 'childGroupIds') ? undefined : json['childGroupIds'],
        'parentGroupIds': !exists(json, 'parentGroupIds') ? undefined : json['parentGroupIds'],
        'customerCategories': !exists(json, 'customerCategories') ? undefined : (json['customerCategories'] === null ? null : (json['customerCategories'] as Array<any>).map(TaxonomyFromJSON)),
        'advisorIds': !exists(json, 'advisorIds') ? undefined : json['advisorIds'],
        'themes': !exists(json, 'themes') ? undefined : (json['themes'] === null ? null : (json['themes'] as Array<any>).map(TaxonomyFromJSON)),
    };
}

export function CompetenceGroupToJSON(value?: CompetenceGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'childGroupIds': value.childGroupIds,
        'parentGroupIds': value.parentGroupIds,
        'customerCategories': value.customerCategories === undefined ? undefined : (value.customerCategories === null ? null : (value.customerCategories as Array<any>).map(TaxonomyToJSON)),
        'advisorIds': value.advisorIds,
        'themes': value.themes === undefined ? undefined : (value.themes === null ? null : (value.themes as Array<any>).map(TaxonomyToJSON)),
    };
}


