import {
    VStack
} from "@chakra-ui/react";
import { ConfigurationTypes } from "../../api/client";
import { 
    useResetMeetingConfigurationType,
} from "../../api/useMeetingConfigurationApi";
import { AdvisorsOfferedInput } from "./Inputs/AdvisorsOfferedInput";
import { BookingByInput } from "./Inputs/BookingByInput";
import { EnabledMeetingTypesInput } from "./Inputs/EnabledMeetingTypesInput";
import { InputWithReset } from "./Inputs/InputWithReset";
import { MeetingDurationInput } from "./Inputs/MeetingDurationInput";
import { PostProcessingInput } from "./Inputs/PostProcessingInput";
import { PreProcessingInput } from "./Inputs/PreProcessingInput";
import { TimeBetweenMeetingsInput } from "./Inputs/TimeBetweenMeetingsInput";
import { CalendarTimeFromBookingToMeetingInput } from "./Inputs/CalendarTimeFromBookingToMeetingInput";
import { useMeetingConfigurationContext } from "./MeetingConfigurationContext";
import { TravelTimeBufferInput } from "./Inputs/TravelTimeBufferInput";
import { WorkingTimeFromBookingToMeetingInput } from "./Inputs/WorkingTimeFromBookingToMeetingInput";

export const MeetingConfigurationInputs = () => {
    const resetMeetingConfigurationTypeMutation = useResetMeetingConfigurationType();
    const { actualMeetingConfiguration } = useMeetingConfigurationContext();
    function resetMeetingConfigurationType(type: ConfigurationTypes) {
        resetMeetingConfigurationTypeMutation.mutate({ id: actualMeetingConfiguration!.id!, configurationType: type });
    }

    return (
        <VStack alignItems="stretch" spacing="2em">
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.BookingBy)}><BookingByInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.EnabledMeetingTypes)}><EnabledMeetingTypesInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.MeetingDuration)}><MeetingDurationInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.PreProcessing)}><PreProcessingInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.PostProcessing)}><PostProcessingInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.TravelTimeBuffer)}><TravelTimeBufferInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.CalendarTimeFromBookingToMeetings)}><CalendarTimeFromBookingToMeetingInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.WorkingTimeFromBookingToMeetings)}><WorkingTimeFromBookingToMeetingInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.TimeBetweenMeetings)}><TimeBetweenMeetingsInput /></InputWithReset>
            <InputWithReset onResetClicked={() => resetMeetingConfigurationType(ConfigurationTypes.AdvisorsOffered)}><AdvisorsOfferedInput /></InputWithReset>
        </VStack>
    );
};