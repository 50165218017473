import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { CustomerCategoryApi, Taxonomy } from "./client"

export const useCustomerCategoryApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [customerCategoryApi] = React.useState(
        () => new CustomerCategoryApi(baseConfiguration)
    );
    return customerCategoryApi;
}

export const useGetAllCustomerCategories = () => {
    const client = useCustomerCategoryApi();
    return useQuery(["getAllCustomerCategories"], () =>
        client.getAllCustomerCategories())
}

export const useUpdateCustomerCategory = () => {
    const client = useCustomerCategoryApi();
    const queryClient = useQueryClient();
    return useMutation(
        (customerCategory: Taxonomy) =>
            client.updateCustomerCategory({ customerCategory }),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllCustomerCategories"])
                }
            }
    );
};

export const useDeleteCustomerCategory = () => {
    const client = useCustomerCategoryApi();
    const queryClient = useQueryClient();
    return useMutation(
        (customerCategoryId: string) =>
            client.deleteCustomerCategory({ id: customerCategoryId}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllCustomerCategories"])
                }
            }
    );
};

export const useCreateCustomerCategory = () => {
    const client = useCustomerCategoryApi();
    const queryClient = useQueryClient();
    return useMutation(
        (customerCategory: Taxonomy) =>
            client.createCustomerCategory({ customerCategory}),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(["getAllCustomerCategories"])
                }
            }
    );
};