import jwtDecode from "jwt-decode";
import React, { useEffect, useMemo, useState } from "react";
import { Route, RouteProps } from "react-router";
import { useHasRoles } from "./hasRolesHook";

export interface RouteGuardProps extends RouteProps {
    inputRoles: string[];
    exact?: boolean;
    path: string;
}

export const RouteGuard = ({ path, exact, inputRoles, children }: React.PropsWithChildren<RouteGuardProps>) => {
    const hasRoles = useHasRoles(inputRoles);

    return (
        <>
            {
                hasRoles
                    ?
                    <Route exact={exact} path={path}>
                        {children}
                    </Route>
                    :
                    <div className="data-area-div">
                        <h3>You are unauthorized to view this content.</h3>
                    </div>
            }
        </>
    );
};