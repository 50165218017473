import { Flex } from '@chakra-ui/layout';
import './App.css';
import NavBar from './components/NavBar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { ConfigureAvailabilityPage } from './pages/ConfigureAvailabilityPage';
import { MeetingTaxonomyPage } from './pages/MeetingTaxonomyPage';
import { CustomerCategoriesPage } from './pages/CustomerCategoriesPage';
import { CalendarOverviewPage } from "./pages/CalendarOverviewPage";
import { MeetingRoomsPage } from './pages/MeetingRoomsPage';
import { MeetingConfigurationPage } from './pages/MeetingConfigurationPage';
import { DefaultValuesPage } from './pages/DefaultValuesPage';
import { useIsAuthenticated, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { appRoles, loginRequest } from './authconfig';
import { useEffect } from 'react';
import { HelloUserPage } from './pages/HelloUserPage';
import { TokenContextProvider, useTokenContext } from './TokenContext';
import { RouteGuard } from './RouteGuard';
import { ServiceGroupPage } from './pages/ServiceGroupPage';
import { CompetenceGroupPage } from './pages/CompetenceGroupPage';
import { PageLayout } from './PageLayout';
import { PortalsPage } from './pages/PortalsPage';
import { PortalConfigurationsPage } from "./pages/PortalConfigurationsPage";

const NavBarWithRoutesWrapper = () => {
  const { accounts } = useMsal();

  return (
    <Flex flex='1 0 0' flexDir='column' spacing={0} height='100%'>
      <NavBar />
      <PageLayout>
        <Switch>
          <Route exact path="/">
            <HelloUserPage account={accounts[0]} />
          </Route>
          <RouteGuard path="/overview" inputRoles={[appRoles.AMB_managementAccess]}>
          </RouteGuard>
          <RouteGuard path="/calendar" inputRoles={[appRoles.AMB_managementAccess]}>
            <CalendarOverviewPage />
          </RouteGuard>
          <RouteGuard path="/availability" inputRoles={[appRoles.AMB_managementAccess]}>
            <ConfigureAvailabilityPage />
          </RouteGuard>
          <RouteGuard path="/standardvalues" inputRoles={[appRoles.AMB_configurationAccess]}>
            <DefaultValuesPage />
          </RouteGuard>
          <RouteGuard path="/meetingtaxonomy" inputRoles={[appRoles.AMB_configurationAccess]}>
            <MeetingTaxonomyPage />
          </RouteGuard>
          <RouteGuard path="/meetingconfiguration" inputRoles={[appRoles.AMB_configurationAccess]}>
            <MeetingConfigurationPage />
          </RouteGuard>
          <RouteGuard path="/customercategories" inputRoles={[appRoles.AMB_configurationAccess]}>
            <CustomerCategoriesPage />
          </RouteGuard>
          <RouteGuard path="/meetingrooms" inputRoles={[appRoles.AMB_configurationAccess]}>
            <MeetingRoomsPage />
          </RouteGuard>
          <RouteGuard path="/serviceGroups" inputRoles={[appRoles.AMB_configurationAccess]}>
            <ServiceGroupPage />
          </RouteGuard>
          <RouteGuard path="/competenceGroups" inputRoles={[appRoles.AMB_configurationAccess]}>
            <CompetenceGroupPage />
          </RouteGuard>
          <RouteGuard exact path="/portals" inputRoles={[appRoles.AMB_configurationAccess]}>
            <PortalsPage />
          </RouteGuard>
          <RouteGuard exact path="/portals/configurations" inputRoles={[appRoles.AMB_configurationAccess]}>
            <PortalConfigurationsPage />
          </RouteGuard>
        </Switch>
      </PageLayout>
    </Flex>
  );
};


function App() {
  const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
  const isAuthenticated = useIsAuthenticated();
  const token = useTokenContext();

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect, loginRequest)
    }
  }, [error, login]);


  if (!isAuthenticated) {
    return <></>;
  }


  return (
    <TokenContextProvider>
      <Router key={token}>
        <NavBarWithRoutesWrapper />
      </Router>
    </TokenContextProvider>
  );

}

export default App;
