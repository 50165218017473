/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeSlotStatus,
    TimeSlotStatusFromJSON,
    TimeSlotStatusFromJSONTyped,
    TimeSlotStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface TimeSlot
 */
export interface TimeSlot {
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    id?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlot
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlot
     */
    endDate?: Date;
    /**
     * 
     * @type {TimeSlotStatus}
     * @memberof TimeSlot
     */
    status?: TimeSlotStatus;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    locationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TimeSlot
     */
    advisorId?: string | null;
}

export function TimeSlotFromJSON(json: any): TimeSlot {
    return TimeSlotFromJSONTyped(json, false);
}

export function TimeSlotFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeSlot {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
        'status': !exists(json, 'status') ? undefined : TimeSlotStatusFromJSON(json['status']),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'advisorId': !exists(json, 'advisorId') ? undefined : json['advisorId'],
    };
}

export function TimeSlotToJSON(value?: TimeSlot | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
        'status': TimeSlotStatusToJSON(value.status),
        'locationId': value.locationId,
        'advisorId': value.advisorId,
    };
}


