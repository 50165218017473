import React from "react";
import {
    InputProps,
    Box,
    useMultiStyleConfig,
    Portal,
    useStyles,
    StylesProvider,
    useOutsideClick
} from "@chakra-ui/react";
import { TimeRangeInputConfigKey } from "./TimeRangeInputConfig";
import { Time } from "../../models/Time";

interface Props extends InputProps {
    startTime?: string;
    endTime?: string;
    onStartTimeChanged?: (startTime: string) => void;
    onEndTimeChanged?: (endTime: string) => void;
}

interface TimeSelectProps {
    position: { x: number, y: number };
    time?: Time;
    onHourChanged?: (value: number) => void;
    onMinuteChanged?: (value: number) => void;
    onClose?: () => void;
    isVisible?: boolean;
}

const TimeSelect = ({ position, time: initialTime, onHourChanged, onMinuteChanged, isVisible, onClose }: TimeSelectProps) => {
    const styles = useStyles();
    const [time, setTime] = React.useState(initialTime || new Time(0, 0));
    const [lastInitialTime, setLastInitialTime] = React.useState(new Time(0, 0));
    const containerRef = React.useRef<HTMLDivElement>(null);
    const initialHourRef = React.useRef<HTMLDivElement>(null);
    const initialMinuteRef = React.useRef<HTMLDivElement>(null);
    const hourContainerRef = React.useRef<HTMLDivElement>(null);
    const minuteContainerRef = React.useRef<HTMLDivElement>(null);
    useOutsideClick({
        ref: containerRef,
        handler: () => onClose?.() 
    });

    React.useEffect(() => {
        if (initialTime !== undefined) {
            setTime(initialTime);
        }
    }, [initialTime]);

    const handleHourChanged = React.useCallback((value: number) => {
        setTime(previous => new Time(value, previous.minute));
        onHourChanged?.(value);
    }, [onHourChanged]);

    const handleMinuteChanged = React.useCallback((value: number) => {
        setTime(previous => new Time(previous.hour, value));
        onMinuteChanged?.(value);
    }, [onMinuteChanged]);

    const [hourButtonElements, minuteButtonElements] = React.useMemo(() => {
        return [Array.from(Array(24).keys()).map(i => {
            if (time && i === time.hour) {
                return (<Box key={`hour-${i}`} ref={initialHourRef} sx={styles.selectedTimeButton} onClick={() => handleHourChanged(i)}>{i.toString().padStart(2, "0")}</Box>);
            } else {
                return (<Box key={`hour-${i}`} sx={styles.timeButton} onClick={() => handleHourChanged(i)}>{i.toString().padStart(2, "0")}</Box>);
            }
        }), Array.from(Array(60).keys()).map(i => {
            if (time && i === time.minute) {
                return (<Box key={`minute-${i}`} ref={initialMinuteRef} sx={styles.selectedTimeButton} onClick={() => handleMinuteChanged(i)}>{i.toString().padStart(2, "0")}</Box>);
            } else {
                return (<Box key={`minute-${i}`} sx={styles.timeButton} onClick={() => handleMinuteChanged(i)}>{i.toString().padStart(2, "0")}</Box>);
            }
        }
        )];
    }, [time, styles, handleHourChanged, handleMinuteChanged]);

    React.useEffect(() => {
        if (!isVisible || (initialTime !== undefined && lastInitialTime.hour === initialTime.hour && lastInitialTime.minute === initialTime.minute)) return;
        setTimeout(() => {
            if (hourContainerRef.current !== null &&
                minuteContainerRef.current !== null &&
                initialHourRef.current !== null &&
                initialMinuteRef.current !== null && initialTime !== undefined) {
                setLastInitialTime(initialTime);
                hourContainerRef.current.scrollTo(0, initialHourRef.current.offsetTop || 0);
                minuteContainerRef.current.scrollTo(0, initialMinuteRef.current.offsetTop || 0);
            }
        }, 30);
    }, [initialTime, isVisible, hourButtonElements, lastInitialTime]);

    return (
        <Portal>
            <Box ref={containerRef} sx={styles.timeSelectContainer} display={isVisible ? "flex" : "none"}
                left={position.x + "px"} top={position.y + "px"}>
                <Box ref={hourContainerRef} sx={styles.timeSelectColumn}>
                    {hourButtonElements}
                </Box>
                <Box ref={minuteContainerRef} sx={styles.timeSelectColumn}>
                    {minuteButtonElements}
                </Box>
            </Box>
        </Portal>
    );
};

export const TimeRangeInput = ({ startTime: initialStartTime, endTime: initialEndTime, onStartTimeChanged, onEndTimeChanged, ...props }: Props) => {
    const styles = useMultiStyleConfig(TimeRangeInputConfigKey, {});
    const [startTime, setStartTime] = React.useState<Time>(initialStartTime ? Time.fromString(initialStartTime) : new Time(0, 0));
    const [endTime, setEndTime] = React.useState<Time>(initialEndTime ? Time.fromString(initialEndTime) : new Time(23, 59));
    const [selectingTime, setSelectingTime] = React.useState<"start" | "end" | null>(null);
    const [timeSelectContainerPosition, setTimeSelectContainerPosition] = React.useState<{ x: number, y: number }>({ x: 0, y: 0});
    const [timeSelectCurrentTime, setTimeSelectCurrentTime] = React.useState<Time>(startTime);
    const startTimeInputRef = React.useRef<HTMLInputElement>(null);
    const endTimeInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (startTimeInputRef.current !== null) {
            startTimeInputRef.current.value = startTime.toString();
        }
    }, [startTime]);

    React.useEffect(() => {
        if (endTimeInputRef.current !== null) {
            endTimeInputRef.current.value = endTime.toString();
        }
    }, [endTime]);

    function handleStartTimeClicked(event: React.MouseEvent<HTMLInputElement>) {
        if (startTimeInputRef.current === null) return;
        event.stopPropagation();
        const boundingRect = startTimeInputRef.current.getBoundingClientRect();
        calculateAndSetContainerPosition(boundingRect);
        setSelectingTime("start");
        setTimeSelectCurrentTime(startTime);
    }

    function handleEndTimeClicked(event: React.MouseEvent<HTMLInputElement>) {
        if (endTimeInputRef.current === null) return;
        event.stopPropagation();
        const boundingRect = endTimeInputRef.current.getBoundingClientRect();
        calculateAndSetContainerPosition(boundingRect);
        setSelectingTime("end");
        setTimeSelectCurrentTime(endTime);
    }

    const handleTimeSelectHourChanged = React.useCallback((value: number) => {
        if (selectingTime === "start") {
            setStartTime(previous => new Time(value, previous.minute));
        } else if (selectingTime === "end") {
            setEndTime(previous => new Time(value, previous.minute));
        }
    }, [selectingTime]);

    const handleTimeSelectMinuteChanged = React.useCallback((value: number) => {
        if (selectingTime === "start") {
            setStartTime(previous => new Time(previous.hour, value));
        } else if (selectingTime === "end") {
            setEndTime(previous => new Time(previous.hour, value));
        }
    }, [selectingTime]);

    function handleStartTimeBlur(event: React.FocusEvent<HTMLInputElement>) {
        setStartTime(Time.fromString(event.target.value));
        onStartTimeChanged?.(event.target.value);
    }

    function handleEndTimeBlur(event: React.FocusEvent<HTMLInputElement>) {
        setEndTime(Time.fromString(event.target.value));
        onEndTimeChanged?.(event.target.value);
    }

    function calculateAndSetContainerPosition(boundingRect: DOMRect) {
        setTimeSelectContainerPosition({
            x: boundingRect.x - boundingRect.width / 2,
            y: boundingRect.y + boundingRect.height + 10
        });
    }

    function handleOnClose() {
        if (selectingTime === "start") {
            onStartTimeChanged?.(startTime.toString());
        } else if (selectingTime === "end") {
            onEndTimeChanged?.(endTime.toString());
        }
        setSelectingTime(null);
    }

    return (
        <>
            <Box sx={styles.container}>
                <Box ref={startTimeInputRef} as="input" sx={styles.startTimeInput} defaultValue={startTime.toString()} onClick={handleStartTimeClicked} onBlur={handleStartTimeBlur} />
                <Box sx={styles.divider}>-</Box>
                <Box ref={endTimeInputRef} as="input" sx={styles.endTimeInput} defaultValue={endTime.toString()} onClick={handleEndTimeClicked} onBlur={handleEndTimeBlur} />
            </Box>
            <StylesProvider value={styles}>
                <TimeSelect time={timeSelectCurrentTime} isVisible={selectingTime !== null} onClose={handleOnClose}
                    position={timeSelectContainerPosition} onHourChanged={handleTimeSelectHourChanged} onMinuteChanged={handleTimeSelectMinuteChanged} />
            </StylesProvider>
        </>
    )
};