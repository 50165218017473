import React from "react";
import { HStack } from "@chakra-ui/layout";
import { RadioGroup, Radio } from "@chakra-ui/radio";
import { UserType } from "../../../api/client";
import { useUpdateBookingBy } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";
import { useTranslation } from "react-i18next";

export const BookingByInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const bookingBy = React.useMemo(() => {
        return actualMeetingConfiguration?.bookingBy;
    }, [actualMeetingConfiguration]);
    const updateCanBeBookedMutation = useUpdateBookingBy();
    const { t } = useTranslation();


    function handleCanBeBookedByChanged(nextValue: string, customerCategoryId?: string) {
        updateCanBeBookedMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, bookingBy: { customerCategoryId, canBeBookedBy: nextValue as UserType }})
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.input.booked-by.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const value = bookingBy!.find(x => x.customerCategoryId === customerCategoryId);
                let isDisabled = false
                if (customerCategoryId === undefined && bookingBy!.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }
                return (
                    <RadioGroup value={value?.canBeBookedBy} isDisabled={isDisabled} onChange={(nextValue: string) => handleCanBeBookedByChanged(nextValue, customerCategoryId)}>
                        <HStack alignItems="start" spacing="2em">
                            <Radio value={UserType.Customer}>{t('meetingconfigurationpage.input.booked-by.external')}</Radio>
                            <Radio value={UserType.Bank}>{t('meetingconfigurationpage.input.booked-by.internal')}</Radio>
                        </HStack>
                    </RadioGroup>
                );
            }}
        </ConfigurationOptionItem>
    );
};