import { Button, ButtonProps } from "@chakra-ui/react";
import { CreateIcon } from "../../icons/CreateIcon";

interface CreateButtonProps {
    text: string;
}

export const CreateButton = ({text, ...props} : CreateButtonProps & ButtonProps )  => {
    return (
        <Button size="lg" {...props} leftIcon={<CreateIcon height="24px" width="24px"/>} shadow="md" aria-label="createbutton" variant="bookMe" textColor="white">{text}</Button>
    );
}