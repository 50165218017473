import { Text, VStack, Box, Input, BoxProps, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompetenceGroup } from "../../api/client";
import { useCreateCompetenceGroup } from "../../api/useCompetenceGroupApi";
import { CreateButton } from "../Buttons/CreateButton";
import { ContentCard } from "../ContentCard/ContentCard";

interface Props extends BoxProps {
    onGroupCreated: React.Dispatch<React.SetStateAction<CompetenceGroup | undefined>>;
}

export const CreateCompetenceGroup = ({ onGroupCreated, ...props }: Props) => {
    const useCreateCompetenceGroupMutation = useCreateCompetenceGroup();

    const [competenceGroupName, setCompetenceGroupName] = useState("");
    const [competenceGroupNameIsInvalid, setCompetenceGroupNameIsInvalid] = useState(false);

    const { t } = useTranslation();

    function handleCreateCompetenceGroup(): void {
        const nameInvalid = competenceGroupName === "";
        setCompetenceGroupNameIsInvalid(nameInvalid);
        if (nameInvalid) {
            setTimeout(() => {
                setCompetenceGroupNameIsInvalid(false);
            }, 5000);
            return;
        }

        const competenceGroupToCreate: CompetenceGroup = {
            name: competenceGroupName,
        }
        useCreateCompetenceGroupMutation.mutateAsync(competenceGroupToCreate).then(group => {
            onGroupCreated(group);

        });

        setCompetenceGroupName("");
    }

    return (
        <ContentCard {...props} w="70%" minHeight="500px" heading={t('competencegrouppage.create-form.heading')}
            subHeading={t('competencegrouppage.create-form.description')}>
            <VStack spacing="4" align="stretch">
                <Box width="50%">
                    <Text color="gray.300" fontSize="xs">{t('competencegrouppage.create-form.input.label')}</Text>
                    <FormControl isInvalid={competenceGroupNameIsInvalid}>
                        <Input placeholder={t('competencegrouppage.create-form.input.placeholder')} size="lg" shadow="md" value={competenceGroupName} onChange={e => setCompetenceGroupName(e.target.value)}
                            borderRadius="0">
                        </Input>
                    {competenceGroupNameIsInvalid && <FormErrorMessage>{t('competencegrouppage.create-form.error-message')}</FormErrorMessage>}
                    </FormControl>
                </Box>
                <CreateButton  w="50%" mt="2" onClick={handleCreateCompetenceGroup} text={t('competencegrouppage.create-form.create-button')} />
            </VStack>
        </ContentCard>);
}