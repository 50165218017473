/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LocationConfiguration,
    LocationConfigurationFromJSON,
    LocationConfigurationToJSON,
    MeetingRoom,
    MeetingRoomFromJSON,
    MeetingRoomToJSON,
} from '../models';

export interface GetLocationMeetingRoomsRequest {
    id: string;
}

export interface UpdateLocationRequest {
    locationConfiguration?: LocationConfiguration;
}

export interface UpdateMeetingRoomRequest {
    id: string;
    meetingRoom?: MeetingRoom;
}

/**
 * 
 */
export class LocationApi extends runtime.BaseAPI {

    /**
     */
    async getAllMeetingRoomsRaw(): Promise<runtime.ApiResponse<Array<MeetingRoom>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Location/rooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingRoomFromJSON));
    }

    /**
     */
    async getAllMeetingRooms(): Promise<Array<MeetingRoom>> {
        const response = await this.getAllMeetingRoomsRaw();
        return await response.value();
    }

    /**
     */
    async getLocationMeetingRoomsRaw(requestParameters: GetLocationMeetingRoomsRequest): Promise<runtime.ApiResponse<Array<MeetingRoom>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLocationMeetingRooms.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Location/{id}/rooms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MeetingRoomFromJSON));
    }

    /**
     */
    async getLocationMeetingRooms(requestParameters: GetLocationMeetingRoomsRequest): Promise<Array<MeetingRoom>> {
        const response = await this.getLocationMeetingRoomsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getLocationsRaw(): Promise<runtime.ApiResponse<Array<LocationConfiguration>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Location`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationConfigurationFromJSON));
    }

    /**
     */
    async getLocations(): Promise<Array<LocationConfiguration>> {
        const response = await this.getLocationsRaw();
        return await response.value();
    }

    /**
     */
    async updateLocationRaw(requestParameters: UpdateLocationRequest): Promise<runtime.ApiResponse<LocationConfiguration>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Location`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationConfigurationToJSON(requestParameters.locationConfiguration),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationConfigurationFromJSON(jsonValue));
    }

    /**
     */
    async updateLocation(requestParameters: UpdateLocationRequest): Promise<LocationConfiguration> {
        const response = await this.updateLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateMeetingRoomRaw(requestParameters: UpdateMeetingRoomRequest): Promise<runtime.ApiResponse<MeetingRoom>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMeetingRoom.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/Location/{id}/rooms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MeetingRoomToJSON(requestParameters.meetingRoom),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MeetingRoomFromJSON(jsonValue));
    }

    /**
     */
    async updateMeetingRoom(requestParameters: UpdateMeetingRoomRequest): Promise<MeetingRoom> {
        const response = await this.updateMeetingRoomRaw(requestParameters);
        return await response.value();
    }

}
