/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompetenceGroup,
    CompetenceGroupFromJSON,
    CompetenceGroupToJSON,
    CompetenceGroupInput,
    CompetenceGroupInputFromJSON,
    CompetenceGroupInputToJSON,
    CompetenceInheritage,
    CompetenceInheritageFromJSON,
    CompetenceInheritageToJSON,
} from '../models';

export interface CreateCompetenceGroupRequest {
    competenceGroupInput?: CompetenceGroupInput;
}

export interface DeleteCompetenceGroupRequest {
    id: string;
}

export interface GetAllowedChildGroupsRequest {
    id: string;
}

export interface GetCompetenceGroupRequest {
    id: string;
}

export interface GetCompetenceGroupInheritageRequest {
    id: string;
}

export interface UpdateCompetenceGroupRequest {
    id: string;
    competenceGroupInput?: CompetenceGroupInput;
}

/**
 * 
 */
export class CompetenceGroupApi extends runtime.BaseAPI {

    /**
     */
    async createCompetenceGroupRaw(requestParameters: CreateCompetenceGroupRequest): Promise<runtime.ApiResponse<CompetenceGroup>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/CompetenceGroup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompetenceGroupInputToJSON(requestParameters.competenceGroupInput),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompetenceGroupFromJSON(jsonValue));
    }

    /**
     */
    async createCompetenceGroup(requestParameters: CreateCompetenceGroupRequest): Promise<CompetenceGroup> {
        const response = await this.createCompetenceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteCompetenceGroupRaw(requestParameters: DeleteCompetenceGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCompetenceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CompetenceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompetenceGroup(requestParameters: DeleteCompetenceGroupRequest): Promise<void> {
        await this.deleteCompetenceGroupRaw(requestParameters);
    }

    /**
     */
    async getAllCompetenceGroupsRaw(): Promise<runtime.ApiResponse<Array<CompetenceGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CompetenceGroup`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompetenceGroupFromJSON));
    }

    /**
     */
    async getAllCompetenceGroups(): Promise<Array<CompetenceGroup>> {
        const response = await this.getAllCompetenceGroupsRaw();
        return await response.value();
    }

    /**
     */
    async getAllowedChildGroupsRaw(requestParameters: GetAllowedChildGroupsRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getAllowedChildGroups.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CompetenceGroup/{id}/allowed-child-groups`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAllowedChildGroups(requestParameters: GetAllowedChildGroupsRequest): Promise<Array<string>> {
        const response = await this.getAllowedChildGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompetenceGroupRaw(requestParameters: GetCompetenceGroupRequest): Promise<runtime.ApiResponse<CompetenceGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompetenceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CompetenceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompetenceGroupFromJSON(jsonValue));
    }

    /**
     */
    async getCompetenceGroup(requestParameters: GetCompetenceGroupRequest): Promise<CompetenceGroup> {
        const response = await this.getCompetenceGroupRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getCompetenceGroupInheritageRaw(requestParameters: GetCompetenceGroupInheritageRequest): Promise<runtime.ApiResponse<CompetenceInheritage>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCompetenceGroupInheritage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/CompetenceGroup/{id}/inheritage`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompetenceInheritageFromJSON(jsonValue));
    }

    /**
     */
    async getCompetenceGroupInheritage(requestParameters: GetCompetenceGroupInheritageRequest): Promise<CompetenceInheritage> {
        const response = await this.getCompetenceGroupInheritageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateCompetenceGroupRaw(requestParameters: UpdateCompetenceGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompetenceGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/CompetenceGroup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CompetenceGroupInputToJSON(requestParameters.competenceGroupInput),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateCompetenceGroup(requestParameters: UpdateCompetenceGroupRequest): Promise<void> {
        await this.updateCompetenceGroupRaw(requestParameters);
    }

}
