import React from "react"
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useBaseConfiguration } from "./BaseConfiguration";
import { ServiceGroup, ServiceGroupApi, ServiceLevel, ServiceTrigger } from "./client";

export const useServiceGroupApi = () => {
    const baseConfiguration = useBaseConfiguration();
    const [serviceGroupApi] = React.useState(
        () => new ServiceGroupApi(baseConfiguration)
    );
    return serviceGroupApi;
}

export const useGetAllServiceGroup = () => {
    const client = useServiceGroupApi();
    return useQuery(["getAllServiceGroups"], () =>
        client.getAllServiceGroups())
}

export const useGetServiceGroup = (id: string) => {
    const client = useServiceGroupApi();
    return useQuery(["getServiceGroup", id], () =>
        client.getServiceGroup({ id }))
}

export const useGetServiceGroupMembership = (id: string) => {
    const client = useServiceGroupApi();
    return useQuery(["getServiceGroupMembership", id], () =>
        client.getServiceGroupMembership({id}))
}
export const useCreateServiceGroup = () => {
    const client = useServiceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(
        (serviceGroup: ServiceGroup) =>
            client.createServiceGroup({ serviceGroup: serviceGroup }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["getAllServiceGroups"])
            }
        }
    );
};

export const useUpdateServiceGroup = () => {
    const client = useServiceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(({id, serviceGroup }: {id: string, serviceGroup: ServiceGroup }) => client.updateServiceGroup({ id, serviceGroup }), {
        onSuccess: (data, input) => {
            queryClient.invalidateQueries(["getServiceGroup", input.id]);
            queryClient.invalidateQueries(["getServiceGroupMembership", input.id]);
        }
    })
}

export const useUpdateServiceTrigger = () => {
    const client = useServiceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(({id, serviceTrigger }: {id: string, serviceTrigger: ServiceTrigger }) => client.updateServiceGroupServiceTrigger({ id, serviceTrigger }), {
        onSuccess: (data, input) => {
            queryClient.invalidateQueries(["getServiceGroup", input.id]);

        }
    })
}

export const useUpdateServiceLevel = () => {
    const client = useServiceGroupApi();
    const queryClient = useQueryClient();
    return useMutation(({id,  serviceLevel }: {id: string, serviceLevel: ServiceLevel }) => client.updateServiceGroupServiceLevel({ id, serviceLevel }), {
        onSuccess: (data, input) => {
            queryClient.invalidateQueries(["getServiceGroup", input.id]);

        }
    })
}

export const useDeleteServiceGroup = () => {
    const client = useServiceGroupApi();
    const queryClient = useQueryClient();
    return useMutation((id: string) => client.deleteServiceGroup({ id }), {
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllServiceGroups"])
        }
    });
}