import { Icon, IconProps } from "@chakra-ui/react";

export const CreateIcon = (props: IconProps) => {
    return (
        <Icon width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
            <path d="M10.25 19C15.2206 19 19.25 14.9706 19.25 10C19.25 5.02944 15.2206 1 10.25 1C5.27944 1 1.25 5.02944 1.25 10C1.25 14.9706 5.27944 19 10.25 19Z" stroke="white" strokeWidth="2"/>
            <path d="M11.25 6C11.25 5.44772 10.8023 5 10.25 5C9.69772 5 9.25 5.44772 9.25 6V14C9.25 14.5523 9.69772 15 10.25 15C10.8023 15 11.25 14.5523 11.25 14V6Z" fill="white"/>
            <path d="M14.25 9H6.25C5.69772 9 5.25 9.44772 5.25 10C5.25 10.5523 5.69772 11 6.25 11H14.25C14.8023 11 15.25 10.5523 15.25 10C15.25 9.44772 14.8023 9 14.25 9Z" fill="white"/>
        </Icon>
    );
};