import { ServiceGroup } from "../api/client";
import { ColumnLayout } from "../components/layout/ColumnLayout";
import { CreateServiceGroup } from "../components/ServiceGroup/CreateServiceGroup";
import { useMemo, useState } from "react";
import { ServiceGroupOverview } from "../components/ServiceGroup/ServiceGroupOverview";
import { GroupItemsList } from "../components/ServiceGroup/GroupItemList";
import { useGetAllServiceGroup } from "../api/useServiceGroupApi";
import { useTranslation } from "react-i18next";

export const ServiceGroupPage = () => {
    const [selectedServiceGroup, setSelectedServiceGroup] = useState<ServiceGroup | undefined>();
    const {data: serviceGroups} = useGetAllServiceGroup();
    const { t } = useTranslation();

    const groupItems = useMemo(() => {
        if (!serviceGroups) return [];

        return serviceGroups?.map((serviceGroup) => {
            return {
                name: serviceGroup.name!,
                id: serviceGroup.id!,
            }
        });
    }, [serviceGroups]);

    const selectedGroupItem = useMemo(() => {
        if (!selectedServiceGroup) return undefined;
        return {
            name: selectedServiceGroup?.name!,
            id: selectedServiceGroup?.id!
        };
    }, [selectedServiceGroup]);

    function handleGroupItemClicked(id: string | undefined): void {
        const serviceGroup = serviceGroups?.find((serviceGroup) => serviceGroup.id === id);
        setSelectedServiceGroup(serviceGroup);
    }


    return (
        <ColumnLayout>
            <GroupItemsList w="30%" minHeight="500px" heading={t('servicegrouppage.overview.header')} groupItems={groupItems} selectedGroupItemInput={selectedGroupItem} handleGroupItemClicked={handleGroupItemClicked}></GroupItemsList>

            {!selectedServiceGroup || !selectedServiceGroup.id ?
                <CreateServiceGroup onGroupCreated={setSelectedServiceGroup} minHeight="500px"></CreateServiceGroup>
                :
                <ServiceGroupOverview serviceGroupId={selectedServiceGroup.id}
                    handleBack={() => handleGroupItemClicked(undefined)}
                    handleDelete={() => handleGroupItemClicked(undefined)}>
                </ServiceGroupOverview>
            }
        </ColumnLayout>
    );

}
