import { Heading } from "@chakra-ui/react";

interface BoxWithHeadingProps {
    heading: string;
}

const ContentWithHeading = ({ heading, children }: React.PropsWithChildren<BoxWithHeadingProps>) => {
    return (
        <>
            <Heading as="h2" size="md" mb="2">{heading}</Heading>
            {children}
        </>
    );
};

export default ContentWithHeading;