import { useTranslation } from "react-i18next";
import { useUpdatePostProcessing } from "../../../api/useMeetingConfigurationApi";
import { ConfigurationOptionItem } from "../../ConfigurationOptionItem";
import { TimeDropdown } from "../../TimeDropdown";
import { useMeetingConfigurationContext } from "../MeetingConfigurationContext";

export const PostProcessingInput = () => {
    const { customerCategories, actualMeetingConfiguration } = useMeetingConfigurationContext();
    const updatePostProcessingMutation = useUpdatePostProcessing();
    const { t } = useTranslation();

    function handlePostProcessingValueChanged(newValue: string, customerCategoryId?: string) {
        const currentPostProcessing = actualMeetingConfiguration!.postProcessings!.find(x => x.customerCategoryId === customerCategoryId);
        updatePostProcessingMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, meetingProcessing: { customerCategoryId, enabled: currentPostProcessing?.enabled || true, duration: newValue }})
    }

    function handlePostProcessingEnabledChanged(enabled: boolean, customerCategoryId?: string) {
        const currentPostProcessing = actualMeetingConfiguration!.postProcessings!.find(x => x.customerCategoryId === customerCategoryId);
        updatePostProcessingMutation.mutate({ id: actualMeetingConfiguration!.id || undefined, meetingProcessing: { customerCategoryId, enabled, duration: currentPostProcessing?.duration || "00:00:00" }})
    }

    return (
        <ConfigurationOptionItem title={t('meetingconfigurationpage.meeting-post-time.label')} customerCategories={customerCategories}>
            {customerCategoryId => {
                const postProcessing = actualMeetingConfiguration!.postProcessings!;
                const value = postProcessing.find(x => x.customerCategoryId === customerCategoryId);
                let isDisabled = false
                if (customerCategoryId === undefined && postProcessing.filter(x => x.customerCategoryId !== customerCategoryId).length > 0) {
                    isDisabled = true;
                }

                return (
                    <TimeDropdown label={t('meetingconfigurationpage.time-dropdown.label')} isDisabled={isDisabled} timeResolution="minutes" hasYesNoRadio value={value?.duration || ""} yesNoValue={value?.enabled}
                        onSelectChanged={(newValue) => handlePostProcessingValueChanged(newValue, customerCategoryId)}
                        onUserChoiceChanged={(enabled) => handlePostProcessingEnabledChanged(enabled, customerCategoryId)} />
                );
            }}
        </ConfigurationOptionItem>
    );
};