import { IconButton } from '@chakra-ui/button'
import { Box, HStack } from '@chakra-ui/layout'
import { Select } from '@chakra-ui/select'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { getWeekNumberForDate, getYearForDatesWeek } from '../../helpers/dateCalculations'

const buttonStyle = {
    h: '100%',
    border: '1em solid',
    borderColor: 'gray.700',
    bg: 'gray.300',
    _hover: {
        bg: 'gray.400',
        borderColor: 'gray.800'
    },
    rounded: 'false'
}

interface WeekSelectHeaderProps {
    currentWeekMonday: Date
    onWeekChange: (weekMonday: Date) => void
}

function WeekSelectHeader({currentWeekMonday, onWeekChange} : WeekSelectHeaderProps) {
    const { t } = useTranslation();
    const [weeks, weekOptions] = useMemo(() => {
        const firstMonday = new Date(Date.now());
        firstMonday.setHours(0, 0, 0, 0);
        firstMonday.setDate(firstMonday.getDate() - firstMonday.getDay() - 27); //start monday 4 weeks earlier (+ 1 - 28 = -27)
        const weeks = new Array<Date>(56); //4 weeks yearlier + 52 weeks for a year
        for (let i = 0; i < weeks.length; i++) {
            weeks[i] = new Date(firstMonday);
            firstMonday.setDate(firstMonday.getDate() + 7);
        }
        const weekOptions = weeks.map(d => <Box key={d.getTime()} as='option' color='black' value={d.getTime()}>{t('calendarpage.week-select.header')} {getWeekNumberForDate(d)} {getYearForDatesWeek(d)}</Box>);

        return [weeks, weekOptions];
    }, []);


    const onNextWeek = () => {
        const date = new Date(currentWeekMonday);
        date.setDate(date.getDate() + 7);
        if(weeks[weeks.length - 1] >= date) onWeekChange(date);
    };

    const onPreviousWeek = () => {
        const date = new Date(currentWeekMonday);
        date.setDate(date.getDate() - 7);
        if(weeks[0] <= date) onWeekChange(date);
    };

    return (
        <HStack bg="gray.700" spacing="4" color="white" h="3em" justifyContent="center">
            <IconButton onClick={onPreviousWeek} aria-label={t('calendarpage.week-select.previous')} icon={<FiChevronLeft strokeWidth='3px'/>} disabled={currentWeekMonday <= weeks[0]} {...buttonStyle}/>
            <Select maxW='10em' bg={'gray.800'} rounded='none' border='none' _hover={{ bg: "gray.600" }} _expanded={{ bg: "gray.800" }} value={currentWeekMonday.getTime().toString()} onChange={val => onWeekChange(new Date(parseInt(val.target.value)))} >
                {weekOptions}
            </Select>
            <IconButton onClick={onNextWeek} aria-label={t('calendarpage.week-select.next')} icon={<FiChevronRight strokeWidth='3px'/>} disabled={currentWeekMonday >= weeks[weeks.length - 1]} {...buttonStyle}/>
        </HStack>
    )
}

export default WeekSelectHeader
