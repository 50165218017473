import { VStack, Text, Box, HStack, FormLabel, FormControl } from "@chakra-ui/react"
import { Radio, RadioGroup } from "@chakra-ui/radio"
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table"
import { useState } from "react"
import { LocationConfiguration } from "../api/client"
import { useGetLocationMeetingRooms, useGetLocations, useUpdateLocation } from "../api/useLocationApi"
import { ContentCard } from "../components/ContentCard/ContentCard"
import { DataList, OptionValue } from "../components/DataList"
import { useTranslation } from "react-i18next"


export const MeetingRoomsPage = () => {
    const [currentLocation, setCurrentLocation] = useState<LocationConfiguration>();
    const [activeLocationId, setActiveLocationId] = useState<string | null>(null);
    const { data: allLocations } = useGetLocations();
    const { data: meetingRooms } = useGetLocationMeetingRooms(activeLocationId);
    const useUpdateLocationMutation = useUpdateLocation();
    const { t } = useTranslation();


    function handleOnSelectLocation(options: OptionValue[]) {
        if (options && options[0]) {
            setCurrentLocation(allLocations?.find(x => x.id === options[0].value));
            setActiveLocationId(options[0].value);
        } else {
            setCurrentLocation(undefined);
            setActiveLocationId(null);
        }
    }

    function handleLocationConfigurationChange(loc?: LocationConfiguration) {
        if (loc) {
            setCurrentLocation(loc);
            useUpdateLocationMutation.mutate(loc);
        }
    }

    if (!allLocations) {
        return null;
    }

    const dataListOptions: OptionValue[] = allLocations.map(loc => {
        return {value: loc.id, label: loc.location ? loc.location : "undefined"};
    });

    return (
        <VStack spacing={10} width={["100%", null, null, null, "75%"]}>
            <ContentCard isFullWidth heading={t('locationpage.location-select.header')}>
                <Box width={["100%", null, null, null, "33%"]}>
                    <DataList placeholder={t('locationpage.location-select.placeholder')} 
                        values={dataListOptions} 
                        handleOnChange={handleOnSelectLocation} 
                        inputStyles={{
                            control: (provided: any, state: any) => ({
                            ...provided,
                            paddingLeft: 8,
                            paddingRight: 8,
                            }),
                        }}
                    />
                </Box>
            </ContentCard>
            <ContentCard isFullWidth heading={t('locationpage.location-info.header')} subHeading={t('locationpage.location-info.subheader')}>
                <HStack justifyContent="left" spacing="8">
                    <FormControl width="fit-content">
                        <FormLabel>{t('locationpage.location-info.add-room.label')}</FormLabel>
                        <RadioGroup value={`${currentLocation?.addRoomsToBookedMeetings}`} isDisabled={!currentLocation} 
                            onChange={e => handleLocationConfigurationChange(currentLocation ? {...currentLocation, addRoomsToBookedMeetings: JSON.parse(e)} : undefined)}>
                            <HStack spacing={10}>
                                <Radio size="lg" value="true">{t('locationpage.location-info.radio-yes')}</Radio>
                                <Radio size="lg" value="false">{t('locationpage.location-info.radio-no')}</Radio>
                            </HStack>
                        </RadioGroup>
                    </FormControl>
                    <FormControl width="fit-content">
                        <FormLabel>{t('locationpage.location-info.required-room.label')}</FormLabel>
                        <RadioGroup value={`${currentLocation?.requireAvailableRoom}`} isDisabled={!currentLocation}
                            onChange={e => handleLocationConfigurationChange(currentLocation ? {...currentLocation, requireAvailableRoom: JSON.parse(e)} : undefined)}>
                            <HStack spacing={10}>
                                <Radio size="lg" value="true">{t('locationpage.location-info.radio-yes')}</Radio>
                                <Radio size="lg" value="false">{t('locationpage.location-info.radio-no')}</Radio>
                            </HStack>
                        </RadioGroup>
                    </FormControl>
                </HStack>
            </ContentCard>
            <ContentCard isFullWidth heading={t('locationpage.room-list.header')}>
                <VStack alignItems="stretch">
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>{t('locationpage.room-list.room-name')}</Th>
                                <Th>{t('locationpage.room-list.room-location')}</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {meetingRooms && meetingRooms.map(room => {
                                return (
                                    <Tr key={`${room.id}`}>
                                        <Td>{room.name}</Td>
                                        <Td>{room.location}</Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </VStack>
            </ContentCard>
        </VStack>
    );
};
