import { Icon } from "@chakra-ui/react"
import { IoMdCheckmarkCircle } from "react-icons/io"

interface Props {
    height: string;
    width: string;
}

export const CheckMarkIcon = ({height, width}: Props) => {
    return <Icon height={height} width={width} fill="#34B233" as={IoMdCheckmarkCircle}/>
}