/* tslint:disable */
/* eslint-disable */
/**
 * Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Advisor
 */
export interface Advisor {
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    initials: string;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Advisor
     */
    location?: string | null;
}

export function AdvisorFromJSON(json: any): Advisor {
    return AdvisorFromJSONTyped(json, false);
}

export function AdvisorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Advisor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'initials': json['initials'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'location': !exists(json, 'location') ? undefined : json['location'],
    };
}

export function AdvisorToJSON(value?: Advisor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'initials': value.initials,
        'email': value.email,
        'location': value.location,
    };
}


