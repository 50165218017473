import { VStack, StackProps } from "@chakra-ui/react";
import {useGetServiceGroup} from "../../api/useServiceGroupApi";
import { UpdateServiceGroup } from "./UpdateServiceGroup";
import { UpdateServiceLevel } from "./UpdateServiceLevel";
import { UpdateServiceTrigger } from "./UpdateServiceTrigger";
import {UpdateServiceGroupMembership} from "./UpdateServiceGroupMembership";


interface Props extends StackProps {
    serviceGroupId: string;
    handleBack: () => void;
    handleDelete: () => void;
}

export const ServiceGroupOverview = ({serviceGroupId, handleBack, handleDelete}: Props) => {
    const { data: serviceGroup } = useGetServiceGroup(serviceGroupId);

    if (serviceGroup === undefined) {
        return null;
    }
    
    return (
        <VStack key={serviceGroup.id} w="70%" spacing="4" align="stretch">
            <UpdateServiceGroup selectedServiceGroupInput={serviceGroup} backClicked={() => handleBack()} onServiceGroupDeleted={() => handleDelete()}></UpdateServiceGroup>
            <UpdateServiceGroupMembership selectedServiceGroupInput={serviceGroup}/>
            <UpdateServiceTrigger selectedServiceGroup={serviceGroup} />
            <UpdateServiceLevel selectedServiceGroup={serviceGroup} />
        </VStack>
    );
}